import { SVGIcon } from "assets/icons";
import React, { Fragment, useEffect, useState } from "react";
import { NavigationProps } from "types/navigation";
import { ReactComponent as CrossIcon } from "assets/icons/custom/cross.svg";
import {
  ArrowWrapperMobile,
  BottomMenu,
  ButtonWrapper,
  BuyButton,
  HorizontalLine,
  IconWrapper,
  MainMenu,
  MainMenuItem,
  MobileLangButton,
  MobileWrapper,
  NavLink,
  ProductsItemWrapper,
  StyledModal,
  SubMenuItem,
  SubMenuItemWrapper,
  SubSubMenu,
} from "./navigation.styles";
import Button from "components/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HASH_ROUTE, ROUTE } from "types/enums";
import theme from "theme";
import {
  isNavOpen,
  selectBuySection,
  selectStakingSection,
  setLocationToRedirect,
  setModalLangOpen,
  setNavOpen,
} from "app/features/utils";
import { useAppDispatch, useAppSelector } from "store/hooks";
import scrollWithOffset from "utils/scrollWithOffset";
import { ReactComponent as BurgerMenu } from "assets/icons/navigation/burgermenu.svg";
import { ReactComponent as ProductsIcon } from "assets/icons/navigation/products.svg";
import { ReactComponent as MegaphoneIcon } from "assets/icons/navigation/megaphone.svg";
import { ReactComponent as TeamIcon } from "assets/icons/navigation/team.svg";
import { ReactComponent as TicketIcon } from "assets/icons/navigation/ticket.svg";
import { ReactComponent as LegalIcon } from "assets/icons/navigation/legal.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { setLogout, userAuthToken } from "app/features/auth";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";
import { ReactComponent as ArrowRightIcon } from "assets/icons/Arrow_right/arrow-right.svg";
import { getFlag, languages } from "i18n";
import { scrollForPaymentGateway } from "utils/scrollForPaymentGateway";

const MobileNavigation: React.FunctionComponent<NavigationProps> = ({ isLogged, email, color }) => {
  const [areProductsOpen, setAreProductsOpen] = useState(false);
  const [areSettingsOpen, setAreSettingsOpen] = useState(false);
  const navigate = useNavigate();
  const authToken = useAppSelector(userAuthToken);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navOpen = useAppSelector(isNavOpen);
  const { t } = useTranslation();
  const isShowBuySection = useAppSelector(selectBuySection);
  const isShowStakingSection = useAppSelector(selectStakingSection);

  const ourTeamAnchor = HASH_ROUTE.OUR_TEAM.substring(2, HASH_ROUTE.OUR_TEAM.length);
  const lucrosusPoolAnchor = HASH_ROUTE.LUCROSUS_POOL.substring(2, HASH_ROUTE.LUCROSUS_POOL.length);

  const handleLogout = () => {
    dispatch(setLogout(`${authToken}`));
    navigate(ROUTE.HOME);
  };

  useEffect(() => {
    dispatch(setNavOpen(false));
  }, [location]);

  useEffect(() => {
    if (navOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [navOpen]);

  useEffect(() => {
    if (!navOpen) {
      setAreSettingsOpen(false);
      setAreProductsOpen(false);
    }
  }, [navOpen]);

  const ProductsArrowStyles = {
    transform: areProductsOpen ? "rotate(0.5turn)" : "none",
    transition: "all 0.2s ease",
  };

  const SettingsArrowStyles = {
    transform: areSettingsOpen ? "rotate(0.5turn)" : "none",
    transition: "all 0.2s ease",
  };

  return (
    <MobileWrapper>
      <BurgerMenu
        fill={theme.colors.primaryYellow}
        name="burgerMenu"
        style={{ cursor: "pointer", color: color }}
        onClick={() => dispatch(setNavOpen(true))}
      />
      <StyledModal isOpen={navOpen}>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "24px" }}>
          <CrossIcon
            stroke={theme.colors.black}
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(setNavOpen(false))}
          />
        </div>
        <MainMenu>
          {isLogged && (
            <Fragment>
              <SubMenuItem onClick={() => setAreSettingsOpen(!areSettingsOpen)}>
                <IconWrapper>
                  <UserIcon
                    stroke={areSettingsOpen ? theme.colors.primaryYellow : theme.colors.primary800}
                  />
                </IconWrapper>{" "}
                {email}
                <ArrowWrapperMobile>
                  <ArrowDownIcon
                    stroke={areSettingsOpen ? theme.colors.primaryYellow : theme.colors.primary800}
                    style={SettingsArrowStyles}
                  />
                </ArrowWrapperMobile>
              </SubMenuItem>
              {areSettingsOpen && (
                <MainMenuItem>
                  <IconWrapper />
                  <SubMenuItemWrapper>
                    <div>
                      <Link to={ROUTE.WALLET} onClick={() => window.scrollTo(0, 0)}>
                        <SVGIcon name="capital" />
                        {t("navigation.userDropdown.wallet")}
                      </Link>
                    </div>
                    <div>
                      <a href="https://lucrosuspool.io/" target="_blank" rel="noreferrer">
                        <SVGIcon name="referralCode" />
                        {t("navigation.userDropdown.lucrosusPool")}
                      </a>
                    </div>
                    <div>
                      <Link to={ROUTE.SETTINGS} onClick={() => window.scrollTo(0, 0)}>
                        <SVGIcon name="settings" />
                        {t("navigation.settings")}
                      </Link>
                    </div>
                    <div>
                      <Link onClick={handleLogout} to={ROUTE.HOME}>
                        <SVGIcon name="logout" />
                        {t("navigation.logout")}
                      </Link>
                    </div>
                  </SubMenuItemWrapper>
                </MainMenuItem>
              )}
              <HorizontalLine />
            </Fragment>
          )}
          <MainMenuItem onClick={() => setAreProductsOpen(!areProductsOpen)}>
            <IconWrapper>
              <ProductsIcon
                fill={areProductsOpen ? theme.colors.primaryYellow : theme.colors.neutral}
              />
            </IconWrapper>{" "}
            {t("navigation.products")}
            <ArrowWrapperMobile>
              <ArrowDownIcon
                stroke={areProductsOpen ? theme.colors.primaryYellow : theme.colors.primary800}
                style={ProductsArrowStyles}
              />
            </ArrowWrapperMobile>
          </MainMenuItem>
          {areProductsOpen && (
            <SubSubMenu>
              <IconWrapper />
              <ProductsItemWrapper>
                <a href="https://www.lucrosus.gallery/" target="_blank" rel="noreferrer">
                  {t("navigation.gallery")}
                </a>
                {location.pathname === "/" ? (
                  <HashLink
                    to={HASH_ROUTE.LUCROSUS_POOL}
                    scroll={(el) => scrollWithOffset(el)}
                    smooth
                  >
                    {t("navigation.lucrosusPool")}
                  </HashLink>
                ) : (
                  <NavLink
                    to={ROUTE.HOME}
                    state={{ id: lucrosusPoolAnchor }}
                    color={"light"}
                    smooth
                  >
                    {t("navigation.lucrosusPool")}
                  </NavLink>
                )}
                <HashLink to={HASH_ROUTE.TOKEN} scroll={(el) => scrollWithOffset(el)} smooth>
                  {t("navigation.token")}
                </HashLink>
              </ProductsItemWrapper>
            </SubSubMenu>
          )}
          {isShowStakingSection && (
            <MainMenuItem>
              <IconWrapper>
                <MegaphoneIcon />
              </IconWrapper>{" "}
              <Link to={ROUTE.STAKING} onClick={() => window.scrollTo(0, 0)}>
                Staking 36%
              </Link>
            </MainMenuItem>
          )}
          <MainMenuItem>
            <IconWrapper>
              <TeamIcon />
            </IconWrapper>{" "}
            {location.pathname === "/" ? (
              <HashLink to={HASH_ROUTE.OUR_TEAM} scroll={(el) => scrollWithOffset(el)} smooth>
                {t("navigation.team")}
              </HashLink>
            ) : (
              <NavLink to={ROUTE.HOME} state={{ id: ourTeamAnchor }} color={"light"} smooth>
                {t("navigation.team")}
              </NavLink>
            )}
          </MainMenuItem>
          <MainMenuItem>
            <IconWrapper>
              <TicketIcon />
            </IconWrapper>{" "}
            <a href="https://lucrosuspool.io" target="_blank" rel="noreferrer">
              {t("navigation.pool")}
            </a>
          </MainMenuItem>
          <MainMenuItem>
            <IconWrapper>
              <LegalIcon />
            </IconWrapper>{" "}
            <a href="https://docs.lucrosus.capital" target="_blank" rel="noreferrer">
              {t("navigation.legal")}
            </a>
          </MainMenuItem>
          <HorizontalLine />
          <MainMenuItem>
            <img
              src={getFlag()}
              width="24px"
              height="16px"
              style={{ marginRight: 5 }}
              alt="Country Flag"
            />
            <LanguageSelector />
            <MobileLangButton onClick={() => dispatch(setModalLangOpen(true))}>
              {languages()}
            </MobileLangButton>
            <ArrowWrapperMobile>
              <ArrowRightIcon />
            </ArrowWrapperMobile>
          </MainMenuItem>
          <HorizontalLine />
        </MainMenu>
        <BottomMenu>
          {isLogged ? null : (
            <Link
              to={ROUTE.LOG_IN}
              onClick={() => dispatch(setLocationToRedirect(location.pathname))}
              style={{ fontWeight: theme.fontWeights.bold, margin: "25px 0" }}
            >
              {t("navigation.logIn").toUpperCase()}
            </Link>
          )}
          {!isLogged && (
            <ButtonWrapper>
              <Link to={ROUTE.SIGN_UP}>
                <Button>{t("navigation.signUp")}</Button>
              </Link>
            </ButtonWrapper>
          )}
          <ButtonWrapper>
            <HashLink
              smooth
              to={isShowBuySection ? HASH_ROUTE.BUY_LUCA : HASH_ROUTE.BUY_EXTERNAL_MARKETS}
              scroll={(el) => scrollForPaymentGateway(el, isShowBuySection)}
            >
              <BuyButton>{t("navigation.CTA").toUpperCase()}</BuyButton>
            </HashLink>
          </ButtonWrapper>
        </BottomMenu>
      </StyledModal>
    </MobileWrapper>
  );
};

export default MobileNavigation;
