import { LocationParams } from "types/locationParams";
export const moveToBlockedPaymentInfo = (id: string, positionY: number) => {
  const element = document.getElementById(id);
  if (element) {
    const position = element.getBoundingClientRect().top + window.scrollY - positionY;
    window.scrollTo({ top: position, behavior: "smooth" });
  }
};

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export const scrollToWithRedirect = (location: LocationParams) => {
  const locationState = location.state as any;
  if (locationState.id) {
    const divElement = document.getElementById(locationState.id);
    if (divElement) {
      setTimeout(() => {
        scrollWithOffset(divElement);
      }, 1000);
    }
  }
};

export default scrollWithOffset;
