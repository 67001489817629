import styled from "styled-components";
import theme from "theme";

export const MenuStyles = styled.ul`
  position: absolute;
  top: 100%;
  width: 200px;
  background: ${theme.colors.white};
  border-radius: 12px;
  border: ${theme.border};
  padding: 10px;
  z-index: 1;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 14px rgba(34, 69, 63, 0.21);

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.primary800};
    cursor: pointer;

    svg {
      margin: 0 10px;
    }

    a,
    Link {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${theme.colors.black};
      text-decoration: none;
      transition: ${theme.transition};
      &:hover {
        color: ${theme.colors.primary};
        transition: ${theme.transition};
        text-decoration: underline;
      }
    }
  }
`;

export const DesktopMenuStyles = styled(MenuStyles)`
  right: 25px;
`;

export const SelectorWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    box-shadow: rgb(19 125 106 / 21%) 0 4px 14px 0;
  }
`;
