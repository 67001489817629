import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentPhaseProps, NextPhaseProps } from "types/progressBar";
import { SalesStats } from "types/salesStats/salesStats.types";

const phasesSlice = createSlice({
  name: "phases",
  initialState: {
    currentSalesPhase: {} as CurrentPhaseProps,
    nextSalesPhase: {} as NextPhaseProps,
    salesStats: {} as SalesStats,
  },
  reducers: {
    setCurrentPhase: (state, action: PayloadAction<CurrentPhaseProps>) => {
      state.currentSalesPhase = action.payload;
    },
    setNextPhase: (state, action: PayloadAction<NextPhaseProps>) => {
      state.nextSalesPhase = action.payload;
    },
    setSalesStats: (state, action: PayloadAction<SalesStats>) => {
      state.salesStats = action.payload;
    },
  },
});

export const { setCurrentPhase, setNextPhase, setSalesStats } = phasesSlice.actions;

export default phasesSlice.reducer;
