import React, { useState } from "react";
import styled, { css } from "styled-components";
import theme from "theme";
import { down } from "styled-breakpoints";
import { SubscribeFieldProps, InputErrorProps } from "types/newsletterSubscribe";
import { ReactComponent as SubscribeIcon } from "assets/icons/subscribe.svg";
import { ReactComponent as LineIcon } from "assets/icons/custom/line.svg";
import Container from "components/Grid/Container";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { subscribeNewsletter } from "app/api/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { isUserSubmitting, setLoading } from "app/features/auth";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const NewsletterSubscriptionStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${theme.colors.gradient};
  padding: 80px 0;
  color: ${theme.colors.white};
  text-align: center;
  > div {
    align-items: center;
  }
  .newsletter-subscription {
    &__title {
      font-weight: ${theme.fontWeights.bold};
      font-size: ${theme.fontSizes.xLarge};
      color: ${theme.colors.white};
      margin: 16px 0 8px 0;
    }
    &__description {
      font-size: ${theme.fontSizes.regular};
      line-height: 24px;
      max-width: 500px;
      opacity: 0.75;
      text-align: center;
    }
  }
`;

const LineIconStyle = styled(LineIcon)`
  margin-bottom: 8px;
`;

export const Form = styled.form`
  display: flex;
  position: relative;
  margin-top: 24px;
  ${down("sm")} {
    flex-direction: column;
    text-align: center;
  }
  ${down("xs")} {
    width: 100%;
  }
`;

const Input = styled.input<InputErrorProps>`
  position: relative;
  width: 385px;
  max-width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 2px solid ${theme.colors.white};
  border-radius: 8px;
  background-color: ${theme.colors.white};
  font-size: ${theme.fontSizes.regular};
  ${down("xs")} {
    width: 100%;
  }
  ${down("sm")} {
    margin-bottom: 16px;
    ::placeholder {
      text-align: center;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    opacity: 0.6;
    font-size: ${theme.fontSizes.regular};
    color: ${theme.colors.primary800};
  }
  :focus {
    outline: 2px solid ${theme.colors.primary};
  }
  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${theme.colors.error};
    `};
`;

export const Errors = styled.span`
  width: auto;
  text-align: left;
  position: absolute;
  top: -23px;
  left: 18px;
  color: ${theme.colors.error};
  font-size: ${theme.fontSizes.small};
  line-height: ${theme.lineHeightSmall};

  ${down("xs")} {
    width: 100%;
  }
`;

const Submit = styled.button`
  width: 160px;
  height: 48px;
  margin-left: 32px;
  color: ${theme.colors.black};
  background-color: ${theme.colors.secondary500};
  font-size: ${theme.fontSizes.regular};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  ${down("xs")} {
    margin-left: 0;
  }
  ${down("sm")} {
    width: 100%;
    margin-left: 0;
  }
  :focus {
    outline: 2px solid ${theme.colors.primary800};
  }
  :hover {
    background-color: ${theme.colors.creamy};
    transition: ${theme.transition};
  }
`;

const NewsletterSubscription: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector(isUserSubmitting);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const subscribeSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("authorization.signUp.pleaseEmail"))
      .required(t("authorization.signUp.pleaseEmail")),
  });

  const {
    handleSubmit,
    register,
    setError,
    setFocus,
    reset,
    formState: { errors },
  } = useForm<SubscribeFieldProps>({
    resolver: yupResolver(subscribeSchema),
  });

  const handleChange = (event: { target: HTMLInputElement } & React.FormEvent) => {
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    const subscribeFormData = new FormData();
    subscribeFormData.append("email", email);
    dispatch(setLoading(true));

    try {
      const response = await subscribeNewsletter(subscribeFormData);
      if (response.status === 201) {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 2000);
        toast.success(t("newsletter.thanks"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
          pauseOnHover: true,
          progress: undefined,
        });
        setEmail("");
        setFocus("email");
        reset();
      }
    } catch (error: unknown | any) {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 2000);
      if (error.response) {
        const errorMessage = error.response?.data?.details[0];
        setError("email", {
          type: "manual",
          message: errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1),
        });
        setFocus("email");
      }
    }
  };

  return (
    <NewsletterSubscriptionStyles id="subscribe-newsletter">
      <Container>
        <SubscribeIcon />
        <h2 className="newsletter-subscription__title">{t("newsletter.title")}</h2>
        <LineIconStyle />
        <div className="newsletter-subscription__description">{t("newsletter.description")}</div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {errors?.email?.message && <Errors>{errors.email.message}</Errors>}
          <Input
            type="email"
            placeholder={t("authorization.logIn.email")}
            value={email}
            {...register("email", { required: true })}
            onChange={handleChange}
            invalid={Boolean(errors.email)}
          />
          {isSubmitting ? (
            <Submit type="submit" disabled={isSubmitting}>
              {t("authorization.signUp.sending")}
            </Submit>
          ) : (
            <Submit type="submit">{t("newsletter.CTA").toUpperCase()}</Submit>
          )}
        </Form>
      </Container>
    </NewsletterSubscriptionStyles>
  );
};

export default NewsletterSubscription;
