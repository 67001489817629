import * as React from "react";
import styled from "styled-components";
import theme from "theme";
import Button from "components/Button";
import Header from "components/Header";
import Divider from "components/Divider";
import { down, up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import Container from "components/Grid/Container";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import Box from "components/Box";
import { Trans, useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  min-height: 500px;
  background: ${theme.colors.gradient};
  padding: 50px 0;
  color: ${theme.colors.white};

  ${down("lg")} {
    padding: 50px 0;
  }
`;

const Title = styled(Header)`
  font-size: 64px;
  padding: 0;
  display: block;
  text-align: left;

  ${down("md")} {
    width: 100%;
    text-align: left;
    font-size: ${theme.fontSizes.xLarge};
    line-height: 48px;
  }
`;

const Subtitle = styled(Header)`
  padding: 0;
  margin-top: -15px;

  ${down("md")} {
    width: 100%;
    margin-top: -10px;
    text-align: left;
    font-size: ${theme.fontSizes.regular};
    line-height: 34px;
  }
`;

const Description = styled.div`
  font-size: ${theme.fontSizes.regular};
  color: ${theme.colors.white};
  line-height: 30px;
  opacity: 1;

  ${down("lg")} {
    text-align: center;
    max-width: 800px;

    ${down("md")} {
      max-width: 100%;
      text-align: left;
    }
  }

  ${up("md")} {
    font-size: ${theme.fontSizes.medium};
    line-height: 32px;
  }

  a {
    text-decoration: underline;
    color: ${theme.colors.white};
    opacity: 0.75;
    white-space: nowrap;

    :hover {
      color: ${theme.colors.primary800};
    }
  }
`;

const ButtonBox = styled(Box)`
  width: 100%;

  ${down("lg")} {
    justify-content: center;
    align-items: center;
  }

  Button {
    width: 150px;
    min-width: 180px;
    color: ${theme.colors.black};

    ${down("md")} {
      justify-content: center;
    }
  }
`;

const InfoBox = styled(Box)`
  padding: 16px;
  border-radius: 8px;
  background: ${theme.colors.white};
  color: ${theme.colors.primary800};
  flex-direction: column;
  margin-top: 20px;
  max-width: 500px;
  text-align: center;

  ${down("lg")} {
    width: 50%;
  }

  ${down("xs")} {
    width: 100%;
    padding: 24px;
  }

  h2 {
    font-family: Roboto, sans-serif;
    font-size: 24px;

    ${up("md")} {
      font-size: 32px;
    }
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-size: ${theme.fontSizes.regular};

    ${up("md")} {
      margin-bottom: 5px;
    }
  }
`;

const ReferralCode: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  const multipleLinelist = ["en", "fr", "es", "de", "id", "nl"];
  const checkLang = multipleLinelist.includes(language);

  const adjustFontSizeBasedOnLang = () => {
    const listOfLanguagesToLowerFontSize = ["tr", "es", "de"];
    if (listOfLanguagesToLowerFontSize.includes(language)) {
      return false;
    }
  };

  return (
    <Wrapper id="lucrosus-pool">
      <Container>
        <Row>
          <Col>
            {adjustFontSizeBasedOnLang() && (
              <Title
                level={1}
                color={theme.colors.primaryYellow}
                marginBottom="0"
                fontWeight={theme.fontWeights.bold}
              >
                {t("referralCode.title1")}
              </Title>
            )}
            {!adjustFontSizeBasedOnLang() && (
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: "36px",
                  paddingBottom: "15px",
                  color: "#F7B668",
                  marginTop: "25px",
                }}
              >
                {t("referralCode.title1")}
              </h1>
            )}

            <Subtitle
              level={2}
              color={theme.colors.white}
              align={useBreakpoint(down("lg")) ? "center" : "left"}
              fontWeight={theme.fontWeights.bold}
            >
              {t("referralCode.title2")}
            </Subtitle>
            <Divider
              marginBottom={
                useBreakpoint(down("lg"))
                  ? "20px"
                  : checkLang
                  ? "0px"
                  : language === "zh" || language === "hi"
                  ? "60px"
                  : "50px"
              }
            />
            <Description>
              <Trans i18nKey="referralCode.description" />
            </Description>
            {useBreakpoint(up("lg")) && (
              <>
                <Divider marginBottom="20px" />
                <ButtonBox>
                  <a href="https://lucrosuspool.io/" target="_blank" rel="noreferrer">
                    <Button outlined>{t("referralCode.CTA")}</Button>
                  </a>
                </ButtonBox>
              </>
            )}
          </Col>
          <Divider right="20px" left="20px" />
          <Col>
            <InfoBox>
              <h3>{t("referralCode.messageh3")}</h3>
              <h2>{t("referralCode.messageh2")}</h2>
            </InfoBox>
            <Divider marginBottom={useBreakpoint(down("md")) ? "20px" : "50px"} />
            <Description>{t("referralCode.contactUs")}</Description>
          </Col>
          {useBreakpoint(down("md")) && (
            <>
              <Divider marginBottom="20px" />
              <ButtonBox>
                <a href="https://lucrosuspool.io/" target="_blank" rel="noreferrer">
                  <Button outlined>{t("referralCode.CTA")}</Button>
                </a>
              </ButtonBox>
            </>
          )}
        </Row>
      </Container>
    </Wrapper>
  );
};

export default ReferralCode;
