import * as React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Navigation from "../Navigation";
import Footer from "components/Footer";
import { ToastContainer } from "react-toastify";
import { RootState, useAppSelector } from "store/hooks";
import { AnnouncementData } from "types/announcment";
import { isUserLoggedIn } from "app/features/auth";

const Container = styled.div<{ announcement?: boolean }>`
  ${(props) => props.announcement && "padding-top: 0px;"}
`;

const Layout: React.FunctionComponent = () => {
  const announcement = useAppSelector(
    (state: RootState) => state.marketing.announcement
  ) as unknown as AnnouncementData | null;

  return (
    <>
      <Navigation isLogged={isUserLoggedIn()} />
      <Container announcement={!!announcement}>
        <Outlet />
      </Container>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Layout;
