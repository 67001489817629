import React, { Suspense, useEffect } from "react";
import InfoWidget from "components/InfoWidget";
import "nprogress/nprogress.css";
import { trackPage } from "utils/adform";
import AnimatedHero from "components/AnimatedHero";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  isUserLoggedIn,
  setAuthTokenExpiry,
  setCredentials,
  setLogout,
  setLogoutModal,
  userAuthToken,
} from "./app/features/auth";
import { getMediaPosts, userRefreshToken } from "./app/api/user";
import { ROUTE } from "./types/enums";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { paramGaSearcher } from "./utils/paramSearcher/searcher";
import AuditedByHacken from "components/AuditedByHacken";
import { scrollToWithRedirect } from "utils/scrollWithOffset/scrollWithOffset";
import Zendesk from "react-zendesk";
import { handleSetCookie } from "./utils/setCookies";
import OurTeam from "./components/OurTeam";
import ReferralCode from "./components/ReferralCode";
import TokenUtility from "./components/TokenUtility";
import Listing from "./components/Listing";
import { Trans, useTranslation } from "react-i18next";
import {
  getShowBuySection,
  getShowOurTeamSection,
  getShowStakingSection,
  selectOurTeamSection,
  selectStakingSection,
} from "./app/features/utils";

const TierContainer = React.lazy(() => import("components/Tiers/TierContainer"));
const NewFeature = React.lazy(() => import("components/NewFeature"));
const BuyLucaWidget = React.lazy(() => import("components/BuyLucaWidget"));
const ProgressBar = React.lazy(() => import("components/ProgressBar"));
const Partnerships = React.lazy(() => import("components/Partnerships"));
const NewsletterSubscription = React.lazy(() => import("components/NewsletterSubscription"));
const Roadmap = React.lazy(() => import("components/Roadmap"));
const TokenDetails = React.lazy(() => import("components/TokenDetails"));

const App: React.FC = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem("refresh_token");
  const authToken = useAppSelector(userAuthToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlReferral = new URLSearchParams(window.location.search).get("referral");
  const isShowOurTeamSection = useAppSelector(selectOurTeamSection);
  const isShowStakingSection = useAppSelector(selectStakingSection);

  useEffect(() => {
    dispatch(getShowBuySection());
    dispatch(getShowOurTeamSection());
    dispatch(getShowStakingSection());
  }, []);

  const returnUrlGaParams = React.useCallback(() => {
    const urlUtmSource = new URLSearchParams(window.location.search).get("utm_source");

    const result = paramGaSearcher();
    if (result && urlUtmSource) {
      Cookies.set("utm_source", result.source !== null ? result.source : "");
      Cookies.set("utm_campaign", result.campaign !== null ? result.campaign : "");
      Cookies.set("utm_content", result.content !== null ? result.content : "");
      Cookies.set("utm_medium", result.medium !== null ? result.medium : "");
      Cookies.set("referrer", result.referrer !== null ? result.referrer : "");
      Cookies.set("utm_term", result.term !== null ? result.term : "");
    }
  }, []);

  React.useEffect(() => {
    returnUrlGaParams();
  }, [returnUrlGaParams]);

  const checkForAuthTokenAndRenewIfNeeded = React.useCallback(async () => {
    if (authToken) {
      return authToken;
    } else {
      return await userRefreshToken().then(
        (response) =>
          dispatch(setCredentials(response.data)) &&
          dispatch(setAuthTokenExpiry(Date.now() + response.data.token_expiry_in * 1000)) &&
          handleSetCookie(
            "loginSession",
            new Date(Date.now() + response.data.token_expiry_in * 1000)
          ) &&
          localStorage.setItem("refresh_token", response.data.refresh_token)
      );
    }
  }, [authToken, dispatch]);

  const saveReferralCode = (referralCode: string) => {
    Cookies.set("referralCode", referralCode);
  };

  React.useEffect(() => {
    const sessions = setInterval(async () => {
      if (token && !Cookies.get("loginSession") && Cookies.get("session")) {
        dispatch(setLogout(`${authToken}`));
        navigate(ROUTE.HOME);
        dispatch(setLogoutModal(true));
      }
    }, 1000);
    return () => {
      clearInterval(sessions);
    };
  }, [authToken, dispatch, navigate, token]);

  React.useEffect(() => {
    if (urlReferral !== null) {
      saveReferralCode(urlReferral);
    }

    const sessions = setInterval(async () => {
      if (token && Cookies.get("loginSession") && Cookies.get("session")) {
        await checkForAuthTokenAndRenewIfNeeded();
      }
    }, 1000);
    return () => {
      clearInterval(sessions);
    };
  }, [checkForAuthTokenAndRenewIfNeeded, dispatch, token, urlReferral]);

  const location = useLocation();

  React.useEffect(() => {
    trackPage("Main Page");
    if (location.state !== null) {
      scrollToWithRedirect(location);
    }
  }, []);

  return (
    <>
      <Zendesk zendeskKey={`${process.env.REACT_APP_ZENDESK_KEY}`} defer />
      <AnimatedHero />
      <AuditedByHacken />
      <InfoWidget />
      <Suspense
        fallback={
          <div>
            <CircularProgress />
          </div>
        }
      >
        <TokenUtility />
        <TierContainer />
        <BuyLucaWidget>
          <ProgressBar isLogged={isUserLoggedIn()} />
        </BuyLucaWidget>
        <ReferralCode />
        {isShowStakingSection && (
          <NewFeature
            title={<Trans i18nKey="staking.title" components={{ strong: <b /> }} />}
            text={t("staking.text")}
          />
        )}
        <NewFeature
          style={{ padding: "0 0 50px 0" }}
          id="buy-luca-report"
          isReport
          isLeftSVG={false}
          title={
            <Trans
              i18nKey="newFeature.title"
              components={{ strong: <b style={{ margin: "10px 0" }} /> }}
            />
          }
          text={t("newFeature.text")}
        />
        {isShowOurTeamSection && <OurTeam />}
        <Roadmap />
        <Partnerships />
        <Listing listingPost={getMediaPosts} title="Media" />
        <NewsletterSubscription />
        <TokenDetails />
      </Suspense>
    </>
  );
};

export default App;
