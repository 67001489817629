import React from "react";
import { ReactComponent as NoMatchSVG } from "assets/icons/404.svg";
import Container from "components/Grid/Container";
import Row from "components/Grid/Row";
import Header from "components/Header";
import Description from "components/Description";
import styled from "styled-components";
import Button from "components/Button";
import { Link } from "react-router-dom";
import theme from "theme";
import { down } from "styled-breakpoints";
import { ROUTE } from "types/enums";

const StyledNoMatch = styled.div`
  padding-bottom: 100px;
  overflow: hidden;
`;

const StyledDescription = styled(Description)`
  font-size: ${theme.fontSizes.regular};
  ${down("lg")} {
    font-size: ${theme.fontSizes.small};
  }
`;

const StyledHeader = styled(Header)`
  font-size: ${theme.fontSizes.large};
  ${down("lg")} {
    font-size: ${theme.fontSizes.medium};
  }
`;

const StyledButton = styled(Button)`
  width: auto;
  ${down("sm")} {
    width: 100%;
  }
`;

const NoMatch: React.FunctionComponent = () => {
  return (
    <StyledNoMatch>
      <Container>
        <Row align="center" justify="center">
          <NoMatchSVG />
        </Row>
        <Row align="center" justify="center">
          <StyledHeader fontWeight="bold" mb="0">
            Oops! Page not found.
          </StyledHeader>
        </Row>
        <Row align="center" justify="center">
          <StyledDescription mb="4">The page you are looking for does not exist!</StyledDescription>
        </Row>
        <Row align="center" justify="center">
          <Link to={ROUTE.HOME}>
            <StyledButton>BACK TO HOME</StyledButton>
          </Link>
        </Row>
      </Container>
    </StyledNoMatch>
  );
};

export default NoMatch;
