import React from "react";
import styled from "styled-components";
import theme from "theme";
import { SwitchProps } from "types/button";

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 56px;
  min-width: 56px;
  height: 32px;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + .switch::before {
    transform: translateX(23px);
    background-color: ${theme.colors.green300};
  }

  input[type="checkbox"]:checked + .switch {
    background-color: rgba(79, 184, 161, 0.2);
  }

  .switch {
    position: absolute;
    cursor: pointer;
    background-color: #e7e7e7;
    border-radius: 32px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }

  .switch::before {
    position: absolute;
    content: "";
    left: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
`;

const ToggleSwitch: React.FunctionComponent<SwitchProps> = (props) => {
  return (
    <Switch>
      <input type="checkbox" checked={props.isChecked} onChange={props.handleChange} />
      <span className="switch" />
    </Switch>
  );
};

export default ToggleSwitch;
