import * as React from "react";
import Header from "components/Header";
import theme from "theme";
import Divider from "components/Divider";
import { ReactComponent as ResearchIcon } from "assets/icons/token-utility/research.svg";
import { ReactComponent as GovernanceIcon } from "assets/icons/token-utility/governance.svg";
import { ReactComponent as StakingIcon } from "assets/icons/token-utility/staking.svg";
import { ReactComponent as ProjectIcon } from "assets/icons/token-utility/project.svg";
import { TokenCardProps } from "types/tokenUtility";
import useWindowDimensions from "hooks/useWindowDimensions";
import { default as GridContainer } from "components/Grid/Container";
import Row from "components/Grid/Row";
import Lottie from "lottie-react";
import axios from "axios";
import {
  Card,
  CardDescription,
  Heading,
  LottieWrapper,
  SVGWrapper,
  TitleDescription,
  TokenUtilityStyles,
} from "./TokenUtility.styles";
import { useTranslation } from "react-i18next";

const CardWrapper: React.FunctionComponent<TokenCardProps> = ({
  title,
  children,
  icon,
  primary = false,
}) => {
  const [isRotated, setIsRotated] = React.useState(false);
  const { width } = useWindowDimensions();

  const onMouseEnter = () => {
    if (width >= 1024) {
      setIsRotated(true);
    }
  };

  const onMouseLeave = () => {
    if (width >= 1024) {
      setIsRotated(false);
    }
  };

  return (
    <Card onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} primary={primary}>
      <Header level={3} fontWeight={theme.fontWeights.bold}>
        {title}
      </Header>
      {isRotated || width < 1024 ? (
        <CardDescription align="left" primary={primary}>
          {children}
        </CardDescription>
      ) : (
        <SVGWrapper>{icon}</SVGWrapper>
      )}
      {width < 1024 && <SVGWrapper>{icon}</SVGWrapper>}
      <Divider marginBottom="10px" />
    </Card>
  );
};

const TokenUtility: React.FunctionComponent = () => {
  const [allocationProgramIcon, setAllocationProgramIcon] = React.useState<any>(null);
  const [buyBackIcon, setBuyBackIcon] = React.useState<any>(null);
  const { t } = useTranslation();

  const getAnimationsFromAws = React.useCallback(() => {
    if (!allocationProgramIcon || !buyBackIcon) {
      axios
        .get(`${process.env.REACT_APP_AWS_URL}/allocationpgrogram.json`)
        .then(({ data: json }) => setAllocationProgramIcon(json));
      axios
        .get(`${process.env.REACT_APP_AWS_URL}/buyback.json`)
        .then(({ data: json }) => setBuyBackIcon(json));
    }
  }, [allocationProgramIcon, buyBackIcon]);

  React.useEffect(() => {
    getAnimationsFromAws();
  }, [getAnimationsFromAws]);

  return (
    <GridContainer id="token">
      <TokenUtilityStyles>
        <Row style={{ gap: 20 }}>
          <Heading>
            <Header align="left" level={2} fontWeight="bold">
              {t("tokenUtility.title")}
            </Header>
            <TitleDescription align="left">{t("tokenUtility.description")}</TitleDescription>
          </Heading>
          {allocationProgramIcon ? (
            <CardWrapper
              icon={
                <LottieWrapper>
                  <Lottie animationData={allocationProgramIcon} />
                </LottieWrapper>
              }
              title={t("tokenUtility.cards.allocation.title")}
              primary
            >
              {t("tokenUtility.cards.allocation.description")}
            </CardWrapper>
          ) : null}
          {buyBackIcon ? (
            <CardWrapper
              icon={
                <LottieWrapper>
                  <Lottie animationData={buyBackIcon} />
                </LottieWrapper>
              }
              title={t("tokenUtility.cards.buyback.title")}
              primary
            >
              {t("tokenUtility.cards.buyback.description")}
            </CardWrapper>
          ) : null}
        </Row>
        <Row style={{ gap: 20, marginTop: 10 }}>
          <CardWrapper icon={<StakingIcon />} title={t("tokenUtility.cards.staking.title")}>
            {t("tokenUtility.cards.staking.description")}
          </CardWrapper>
          <CardWrapper icon={<GovernanceIcon />} title={t("tokenUtility.cards.governance.title")}>
            {t("tokenUtility.cards.governance.description")}
          </CardWrapper>
          <CardWrapper icon={<ResearchIcon />} title={t("tokenUtility.cards.research.title")}>
            {t("tokenUtility.cards.research.description")}
          </CardWrapper>
          <CardWrapper icon={<ProjectIcon />} title={t("tokenUtility.cards.representatives.title")}>
            {t("tokenUtility.cards.representatives.description")}
          </CardWrapper>
        </Row>
      </TokenUtilityStyles>
    </GridContainer>
  );
};

export default TokenUtility;
