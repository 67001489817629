import styled, { css } from "styled-components";
import theme from "theme";
import { typography } from "theme/config";
import { down, up } from "styled-breakpoints";
import { InputErrorProps } from "types/authTypes";
import arrow from "assets/icons/arrow-back.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "assets/logo.svg";

export const StyledLogo = styled(Logo)`
  width: 100px;
  height: 31px;
  cursor: pointer;
`;

export const DefaultButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 385px;
  margin: 20px 0 10px 0;
  padding: 16px 16px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  text-transform: ${theme.title.uppercase};
  border: none;
  border-radius: 8px;
  font-family: ${typography.fonts.body};
  font-size: ${theme.fontSizes.regular};
  line-height: ${theme.lineHeightMedium};
  outline: none;
  cursor: pointer;

  :focus {
    outline: 1px solid ${theme.colors.secondary};
    border-radius: 4px;
  }

  :hover {
    transition: ${theme.transition};
    background-color: ${theme.colors.green400};
  }

  ${down("lg")} {
    height: 48px;
    width: 335px;
  }
`;
export const DefaultInput = css<InputErrorProps>`
  position: relative;
  height: 56px;
  width: 385px;
  border: none;
  font-size: ${theme.fontSizes.regular};
  font-weight: ${theme.fontWeights.regular};
  padding-left: 25px;
  background-color: ${theme.colors.placeholder};
  letter-spacing: 0.7px;
  border-radius: 8px;
  box-sizing: border-box;
  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${theme.colors.error};
    `};

  :focus {
    outline: none;
    border: 1px solid ${theme.colors.secondary};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.colors.secondary};
  }

  :-ms-input-placeholder {
    color: ${theme.colors.secondary};
  }

  ${down("lg")} {
    height: 48px;
    width: 335px;
  }
`;
export const Info = styled.p`
  width: 385px;
  line-height: ${theme.lineHeightMedium};
  text-align: left;
  color: ${theme.colors.secondary};
  opacity: 0.75;
  font-weight: ${theme.fontWeights.regular};
  margin-bottom: 10px;
  cursor: default;

  ${down("lg")} {
    width: 335px;
  }
`;
export const MainWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
`;
export const BackLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  text-decoration: none;
  color: ${theme.colors.secondary};
  font-family: ${theme.fonts.body};
  font-size: ${theme.fontSizes.regular};
  background-color: ${theme.colors.transparent};
  border: none;
  cursor: pointer;

  :focus {
    outline: 1px solid ${theme.colors.secondary};
    border-radius: 4px;
  }
`;
export const MobileLogoWrapper = styled.div`
  display: none;

  ${down("lg")} {
    display: flex;
  }
`;
export const Title = styled.h1`
  margin-top: 30px;
  width: 385px;
  text-align: left;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.xxLarge};
  line-height: ${theme.lineHeightXXLarge};
  margin-bottom: 10px;
  color: ${theme.colors.secondary};
  cursor: default;

  ${down("lg")} {
    width: 335px;
    font-size: ${theme.fontSizes.xLarge};
    margin-top: 30px;
    line-height: ${theme.lineHeightXLarge};
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
`;
export const BackNavWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 385px;
  text-decoration: none;
  color: ${theme.colors.secondary};

  :focus {
    outline: none;
  }

  ${down("lg")} {
    width: 335px;
    justify-content: space-between;
  }
`;
export const ArrowBack = styled.img.attrs({
  src: `${arrow}`,
})`
  cursor: pointer;
`;
export const Errors = styled.span`
  width: 385px;
  text-align: left;
  position: relative;
  color: ${theme.colors.error};
  font-size: ${theme.fontSizes.small};
  line-height: ${theme.lineHeightSmall};
  margin-top: -22px;
  padding-left: 25px;

  ${down("md")} {
    width: 335px;
    padding-left: 0;
  }
`;
export const TermsLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 385px;

  ${down("lg")} {
    width: 335px;
  }
`;
export const TermsInfo = styled.p`
  line-height: ${theme.lineHeightMedium};
  font-size: ${theme.fontSizes.small};
  text-align: left;
  cursor: default;

  ${down("md")} {
    font-size: ${theme.fontSizes.superSmall};
  }
`;
export const FormContainer = styled.div`
  display: flex;
  width: 52vw;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;

  ${down("lg")} {
    width: 100vw;
  }

  @media (max-height: 823px) and (max-width: 420px) {
    height: 100%;
    width: 100%;
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 128px;
  @media (max-width: 1440px) and (max-height: 1000px) {
    margin-top: 50px;
  }

  ${up("xl")} {
    margin-left: 10vw;
  }

  ${down("lg")} {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0;
  }
`;
export const DontHaveAccount = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.secondary};
  cursor: default;

  ${down("lg")} {
    width: 335px;
  }
`;
export const LinkToSignup = styled(Link)`
  font-weight: ${theme.fontWeights.bold};
  margin-left: 5px;
  color: ${theme.colors.primary};
  text-decoration: underline;

  :focus {
    outline: 1px solid ${theme.colors.secondary};
    border-radius: 4px;
  }
`;
export const LinkToLogin = styled(Link)`
  font-weight: ${theme.fontWeights.bold};
  margin-left: 5px;
  color: ${theme.colors.primary};
  text-decoration: underline;

  :focus {
    outline: 1px solid ${theme.colors.secondary};
    border-radius: 4px;
  }
`;
export const HaveAccount = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.secondary};
  cursor: default;
  margin-bottom: 20px;

  ${down("md")} {
    width: 335px;
    margin-bottom: 90px;
  }
`;
export const PasswordRequirementInfo = styled.p`
  width: 385px;
  height: 42px;
  opacity: 0.75;
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.secondary};
  font-weight: ${theme.fontWeights.regular};
  margin: 12px 0 15px 0;
  padding-left: 25px;
  text-align: left;

  ${down("lg")} {
    font-size: ${theme.fontSizes.superSmall};
    width: 335px;
    padding: 0;
  }
`;
