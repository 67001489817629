import Modal from "../../ModalInstance/modal";
import { FC } from "react";
import {
  Button,
  Content,
  EmailLogo,
  Header,
  ModalDesktopContainer,
} from "../../ModalInstance/modalPopupStyles";
import { ModalInstanceProps } from "types/modalTypes";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "types/enums";
import { useAppSelector } from "store/hooks";
import { isModalVisible } from "app/features/utils";
import { useTranslation } from "react-i18next";

const SuccessSignUpModal: FC<ModalInstanceProps> = () => {
  const navigate = useNavigate();
  const showModal = useAppSelector(isModalVisible);
  const { t } = useTranslation();

  const redirectToLoginPage = () => {
    navigate(ROUTE.LOG_IN);
    window.location.reload();
  };

  return showModal ? (
    <Modal>
      <ModalDesktopContainer>
        <EmailLogo />
        <Header>{t("authorization.modalRegistration.checkYourEmail")}</Header>
        <Content>{t("authorization.modalRegistration.thankYou")}</Content>
        <Button onClick={redirectToLoginPage}>
          {t("authorization.modalRegistration.goToLogIn")}
        </Button>
      </ModalDesktopContainer>
    </Modal>
  ) : null;
};

export default SuccessSignUpModal;
