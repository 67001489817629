import React from "react";
import ReactDOM from "react-dom";
import { useAppSelector } from "store/hooks";
import { Overlay } from "./modalPopupStyles";
import { modalOverlay } from "app/features/utils";
import { ChildrenModalProps } from "types/modalTypes";

const Modal = ({ children }: ChildrenModalProps) => {
  const modalOverlayStyle = useAppSelector(modalOverlay);

  return ReactDOM.createPortal(
    <Overlay style={{ backgroundColor: `${modalOverlayStyle}` }}>{children}</Overlay>,
    document.getElementById("modal-root")!
  );
};

export default Modal;
