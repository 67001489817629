import { CardWrapper, ListingRow, ListingStyles } from "./Listing.styles";
import Container from "../Grid/Container";
import Header from "../Header";
import AliceCarousel from "react-alice-carousel";
import { Post } from "types/mediaPosts";
import React, { FC } from "react";
import { AxiosResponse } from "axios";
import styled from "styled-components";
import theme from "theme";

const Error = styled("div")`
  font-size: ${theme.fontSizes.xLarge};
  color: ${theme.colors.error};
`;

const Listing: FC<{
  listingPost: () => Promise<AxiosResponse<Post[]>>;
  style?: React.CSSProperties;
  title: string;
  id?: string;
}> = ({ listingPost, style, title, id }) => {
  const [listings, setListings] = React.useState<Post[]>([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    const fetchRoadMap = async () => {
      try {
        listingPost().then((data) => {
          if (data.status === 200) {
            setListings(data.data);
          } else {
            throw "Error fetch listings";
          }
        });
      } catch (err) {
        setIsError(true);
      }
    };
    fetchRoadMap();
  }, []);

  const syncActiveIndex = ({ item }: { item: number }) => setActiveIndex(item);
  const posts = listings.map(({ id, content, image_src: image, url }) => {
    return (
      <a href={url} target="_blank" rel="noreferrer" key={id}>
        <CardWrapper key={id}>
          <img src={image} width="200px" alt="logo" />
          {content}
        </CardWrapper>
      </a>
    );
  });

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };
  return (
    <ListingStyles>
      <Container style={style}>
        <Header align="center" level={2}>
          <span className="text-bold" id={id}>
            {title}
          </span>
        </Header>
        {isError && <Error>Something went wrong, please try again</Error>}
        <ListingRow>
          <AliceCarousel
            disableButtonsControls
            activeIndex={activeIndex}
            infinite
            responsive={responsive}
            items={posts}
            onSlideChanged={syncActiveIndex}
            autoPlay
            autoPlayInterval={3000}
          />
        </ListingRow>
      </Container>
    </ListingStyles>
  );
};

export default Listing;
