import styled from "styled-components";
import { down } from "styled-breakpoints";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { isModalVisible } from "app/features/utils";
import { userAuthToken, userInfoSelector } from "app/features/auth";
import { User } from "types/user";
import { useEffect } from "react";
import { getTransactionsList } from "app/api/payments";
import { setTransactions } from "app/features/transactions";
import { withdrawsList } from "app/api/withdraw";
import { setWithdrawsList } from "app/features/withdraw";

const WalletNavigation = React.lazy(() => import("./AllTabs"));
const BuyLucaPopup = React.lazy(() => import("./AllTabs/userBalanceSections/buyLucaPopup"));

const WalletWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1366px;
  height: 100%;
  padding: 64px 123px 85px 123px;
  @media (max-width: 1365px) and (min-width: 678px) {
    padding: 64px 70px 85px 70px;
    width: auto;
  }

  ${down("lg")} {
    padding: 40px 20px 48px 20px;
    width: 100vw;
    height: 100%;
    font-size: 28px;
  }
`;

const MainWalletScreen = () => {
  const showModal = useAppSelector(isModalVisible) as boolean;
  const userInfo = useAppSelector(userInfoSelector) as User;
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(userAuthToken);

  useEffect(() => {
    if (authToken) {
      getTransactionsList(`${authToken}`).then(({ data }) => {
        dispatch(setTransactions(data));
      });
      withdrawsList(`${authToken}`).then(({ data }) => {
        dispatch(setWithdrawsList(data));
      });
    }
  }, [authToken, dispatch]);

  return (
    <WalletWrapper>
      <React.Suspense fallback={<div>Loading...</div>}>
        <BuyLucaPopup isModalVisible={showModal} />
        <WalletNavigation userInfo={userInfo} />
      </React.Suspense>
    </WalletWrapper>
  );
};

export default MainWalletScreen;
