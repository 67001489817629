import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "theme";
import { ROUTE } from "types/enums";
import { useDispatch } from "react-redux";
import { setLocationToRedirect } from "app/features/utils";
import { NavigationTheme } from "types/navigation";
import React from "react";
import { useTranslation } from "react-i18next";

const LoginLinkStyles = styled(Link)<{ color?: NavigationTheme }>`
  color: ${theme.colors.black};
  text-decoration: none;
  transition: ${theme.transition};
  text-transform: ${theme.title.uppercase};
  font-weight: ${theme.fontWeights.regular};
  &:hover {
    color: ${theme.colors.primary};
    transition: ${theme.transition};
    text-decoration: underline;
  }
  margin-right: 16px;
  ${({ color }) =>
    color === "dark" &&
    css`
      color: ${theme.colors.white};
    `}
`;

const LoginLink: React.FunctionComponent<{ color?: NavigationTheme }> = ({ color }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <LoginLinkStyles
      to={ROUTE.LOG_IN}
      onClick={() => dispatch(setLocationToRedirect(location.pathname))}
      color={color}
    >
      {t("navigation.logIn")}
    </LoginLinkStyles>
  );
};

export default LoginLink;
