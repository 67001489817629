import axios from "axios";
import { apiRefreshTokenService, apiWithNoAuth } from "./axiosInterceptors";

export const baseURL = `${process.env.REACT_APP_API}/api/v1/`;
const re = /"/g;

export const getUserInfo = async (authToken: string) => {
  return await axios.get(baseURL + "users/me", {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const registerUser = async (data: FormData) => {
  return await apiWithNoAuth.post("users/sign_up", data);
};

export const generateOtp = async (data: FormData) => {
  return await apiWithNoAuth.post("users/generate_otp", data);
};

export const generateCryptoOtp = async (data: FormData) => {
  return await apiWithNoAuth.post("users/generate_otp_crypto", data);
};

export const userTwoFactorAuth = async (data: FormData) => {
  return await apiWithNoAuth.post("users/authorize_otp", data);
};

export const userForgotPassword = async (data: FormData) => {
  return await apiWithNoAuth.post("users/invoke_reset_password", data);
};

export const userCreateNewPassword = async (data: FormData) => {
  const resetToken = sessionStorage.getItem("resetToken");
  const resetTokenOutput = resetToken?.replace(re, "");
  return await apiWithNoAuth.post(`users/reset_password/${resetTokenOutput}`, data);
};

export const userChangePassword = async (data: FormData, authToken: string) => {
  return await axios.post(baseURL + "users/change_password", data, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const userActivateAccount = async (data: FormData) => {
  const activationToken = sessionStorage.getItem("activationToken");
  const activationTokenOutput = activationToken?.replace(re, "");
  return await apiWithNoAuth.post(`users/activate_account/${activationTokenOutput}`, data);
};

export const userRefreshToken = async () => {
  return await apiRefreshTokenService.post("refresh_token");
};

export const updateUserInfo = async (data: FormData, authToken: string) => {
  return await axios.patch(baseURL + "users/update", data, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const logoutUser = async (authToken: string) => {
  return await axios.post(
    baseURL + "users/sign_out",
    {},
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAnnouncements = async () => {
  return await apiWithNoAuth.get("daily_tickers/list");
};

export const getListing = async () => {
  return await apiWithNoAuth.get("placards/list");
};

export const getTeamMembers = async () => {
  return await apiWithNoAuth.get("team_members/list");
};

export const getPartnerships = async () => {
  return apiWithNoAuth.get("partnerships/list");
};

export const getMediaPosts = async () => {
  return await apiWithNoAuth.get("posts/list");
};

export const subscribeNewsletter = async (data: FormData) => {
  return await apiWithNoAuth.post("subscribers/create", data);
};

export const showBuySection = async () => {
  return await apiWithNoAuth.get("settings/show_buy_section");
};

export const showOurTeamSection = async () => {
  return await apiWithNoAuth.get("settings/show_our_team_section");
};

export const showStakingSection = async () => {
  return await apiWithNoAuth.get("settings/show_staking_section");
};
