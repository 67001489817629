import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import SideComponent from "../LeftSideSection/sideSection";
import { down } from "styled-breakpoints";
import {
  StyledLogo,
  BackNavWrapper,
  DefaultButton,
  DefaultInput,
  Errors,
  Form,
  FormContainer,
  HaveAccount,
  Info,
  InputContainer,
  LinkToLogin,
  MainWrapper,
  MobileLogoWrapper,
  Title,
} from "../authStyles";
import { ForgotPasswordProps } from "types/authTypes";
import NavigateToPrevious from "../BackButton";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "types/enums";
import { userForgotPassword } from "app/api/user";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const EmailInput = styled.input`
  ${DefaultInput};
  margin-bottom: 10px;

  ${down("lg")} {
    width: 335px;
  }
`;
const ButtonContinue = styled.button`
  ${DefaultButton};
  @media (max-height: 750px) and (max-width: 440px) {
    margin-top: 320px;
  }
  @media (max-height: 670px) and (max-width: 440px) {
    margin-top: 250px;
  }
  @media (min-height: 812px) and (max-width: 440px) {
    margin-top: 400px;
  }
`;

const ForgotPasswordForm: FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email().required(t("authorization.signUp.pleaseEmail")),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ForgotPasswordProps>({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const [formValue, setFormValue] = useState({
    email: "",
  });

  const handleNavigateToMainPage = () => navigate(ROUTE.HOME);

  const handleChange = (event: { target: HTMLInputElement } & React.FormEvent) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async () => {
    const forgotPasswordFormData = new FormData();
    forgotPasswordFormData.append("email", formValue.email);

    try {
      const response = await userForgotPassword(forgotPasswordFormData);
      if (response.status === 200) {
        toast.success(`${t("authorization.forgotPassword.inbox")}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          closeButton: false,
          pauseOnHover: true,
          progress: undefined,
        });
        setFormValue({ email: "" });
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError("email", {
          message: `${t("authorization.forgotPassword.resetNotAllowed")}`,
        });
      }
    }
  };

  return (
    <MainWrapper>
      <SideComponent />
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BackNavWrapper>
            <NavigateToPrevious />
            <MobileLogoWrapper>
              <StyledLogo onClick={handleNavigateToMainPage} />
            </MobileLogoWrapper>
          </BackNavWrapper>
          <Title>{t("authorization.forgotPassword.forgotPassword")}</Title>
          <Info>{t("authorization.forgotPassword.description")}</Info>
          <InputContainer>
            {errors?.email?.message && <Errors>{errors.email.message}</Errors>}
            <EmailInput
              type="email"
              placeholder={t("authorization.logIn.email")}
              value={formValue.email}
              {...register("email")}
              onChange={handleChange}
              invalid={Boolean(errors.email)}
            />
          </InputContainer>
          <ToastContainer className="toast-container" />
          <ButtonContinue type="submit">{t("authorization.forgotPassword.send")}</ButtonContinue>
          <HaveAccount>
            {t("authorization.signUp.haveAccount")}
            <LinkToLogin to={ROUTE.LOG_IN}>{t("navigation.logIn")}</LinkToLogin>
          </HaveAccount>
          <FormContainer />
        </Form>
      </FormContainer>
    </MainWrapper>
  );
};

export default ForgotPasswordForm;
