import {
  typography,
  colors,
  transition,
  iconSize,
  boxShadow,
  darkBoxShadow,
  borderRadius,
  border,
} from "./config";

const theme = {
  // Colors
  colors,
  breakpoints: {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },

  // Typography
  fonts: typography.fonts,
  fontWeights: {
    light: typography.fontWeights[0],
    regular: typography.fontWeights[1],
    bold: typography.fontWeights[2],
    medium: typography.fontWeights[3],
    semiBold: typography.fontWeights[4],
  },
  title: {
    capitalize: typography.title[0],
    uppercase: typography.title[1],
  },
  fontSizes: {
    superSmall: typography.fontSizes[0],
    small: typography.fontSizes[1],
    regular: typography.fontSizes[2],
    medium: typography.fontSizes[6],
    large: typography.fontSizes[3],
    xLarge: typography.fontSizes[4],
    xxLarge: typography.fontSizes[5],
    xxxLarge: typography.fontSizes[7],
  },
  headerFontSizes: {
    h6: typography.headerFontSizes[0],
    h5: typography.headerFontSizes[1],
    h4: typography.headerFontSizes[2],
    h3: typography.headerFontSizes[3],
    h2: typography.headerFontSizes[4],
    h1: typography.headerFontSizes[5],
  },
  mobileHeaderFontSizes: {
    h6: typography.mobileHeaderFontSizes[0],
    h5: typography.mobileHeaderFontSizes[1],
    h4: typography.mobileHeaderFontSizes[2],
    h3: typography.mobileHeaderFontSizes[3],
    h2: typography.mobileHeaderFontSizes[4],
    h1: typography.mobileHeaderFontSizes[5],
  },
  lineHeightExtraSmall: "18px",
  lineHeightSmall: typography.lineHeight[0],
  lineHeightMedium: typography.lineHeight[1],
  lineHeightLarge: typography.lineHeight[2],
  lineHeightXLarge: typography.lineHeight[3],
  lineHeightXXLarge: typography.lineHeight[4],
  // Animations
  transition,

  // Icons
  iconSize,

  // Shadow
  boxShadow,
  darkBoxShadow,

  // Border
  border,
  borderRadius,
};

export default theme;
