import styled, { css } from "styled-components";
import { down } from "styled-breakpoints";
import { ContainerProps } from "types/container";

const ContainerStyles = styled.div<ContainerProps>`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1440px;
  height: 100%;
  padding: 0 123px;
  @media (max-width: 1439px) and (min-width: 678px) {
    padding: 0 70px;
    width: 100%;
  }
  ${down("lg")} {
    padding: 0 20px;
    width: 100%;
  }
  ${({ heroImage }) =>
    heroImage &&
    css`
      padding-right: 0;
    `}
`;

const Container = ({ children, ...props }: ContainerProps) => (
  <ContainerStyles {...props}>{children}</ContainerStyles>
);

export default Container;
