import * as React from "react";
import { SVGIcon } from "assets/icons";
import { DropdownProps } from "types/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as UserIconClicked } from "assets/icons/user-clicked.svg";
import { ROUTE } from "types/enums";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setLogout, userAuthToken } from "app/features/auth";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useRef } from "react";
import { DropdownMenu, DesktopMenuStyles, DropdownIconsWrapper } from "./Dropdown.styles";
import { ReactComponent as ArrowDownSmall } from "assets/icons/navigation/arrow-down-small.svg";
import theme from "theme";
import { useTranslation } from "react-i18next";

const Menu: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const authToken = useAppSelector(userAuthToken);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(setLogout(`${authToken}`));
    navigate(ROUTE.HOME);
  };

  const { t } = useTranslation();

  return (
    <DesktopMenuStyles>
      <li>
        <Link to={ROUTE.WALLET} onClick={() => window.scrollTo(0, 0)}>
          <SVGIcon name="capital" />
          {t("navigation.userDropdown.wallet")}
        </Link>
      </li>
      <li>
        <a href="https://lucrosuspool.io/" target="_blank" rel="noreferrer">
          <SVGIcon name="referralCode" />
          {t("navigation.userDropdown.lucrosusPool")}
        </a>
      </li>
      <li>
        <Link to={ROUTE.SETTINGS} onClick={() => window.scrollTo(0, 0)}>
          <SVGIcon name="settings" />
          {t("navigation.userDropdown.settings")}
        </Link>
      </li>
      <li>
        <Link onClick={handleLogout} to={ROUTE.HOME}>
          <SVGIcon name="logout" />
          {t("navigation.userDropdown.logout")}
        </Link>
      </li>
    </DesktopMenuStyles>
  );
};

const Dropdown: React.FunctionComponent<DropdownProps> = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = () => setIsOpen(!isOpen);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <DropdownMenu ref={ref} isOpen={isOpen} onClick={handleClick}>
      {isOpen ? (
        <DropdownIconsWrapper>
          <UserIconClicked style={{ cursor: "pointer" }} />{" "}
          <ArrowDownSmall
            fill={theme.colors.white}
            style={{ transform: "rotate(0.5turn)", transition: "transform 0.2s ease" }}
          />
        </DropdownIconsWrapper>
      ) : (
        <DropdownIconsWrapper>
          <UserIcon stroke={theme.colors.primary} style={{ cursor: "pointer" }} />{" "}
          <ArrowDownSmall
            fill={theme.colors.primary}
            style={{ transform: "rotate(0turn)", transition: "transform 0.2s ease" }}
          />
        </DropdownIconsWrapper>
      )}
      {isOpen ? <Menu /> : null}
    </DropdownMenu>
  );
};

export default Dropdown;
