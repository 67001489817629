import { FC, useEffect } from "react";
import theme from "theme";
import TransactionCancel from "./transactionCancelPopup";
import TransactionSuccess from "./transactionSuccessPopup";
import TransactionFailed from "./transactionFailedPopup";
import { IOrderStatus, OrderState } from "types/order/order.types";
import { isModalVisible, setModalOverlayStyle } from "app/features/utils";
import { useAppDispatch, useAppSelector } from "store/hooks";

const OrderStatus: FC<IOrderStatus> = ({ status }) => {
  const showModal = useAppSelector(isModalVisible);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setModalOverlayStyle(theme.colors.primary800));
  }, [dispatch]);

  useEffect(() => {
    if (!showModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [showModal]);

  if (status === OrderState.Canceled) {
    return <TransactionCancel isModalVisible={showModal} />;
  }

  if (status === OrderState.Failed) {
    return <TransactionFailed isModalVisible={showModal} />;
  }

  if (status === OrderState.Success) {
    return <TransactionSuccess isModalVisible={showModal} />;
  } else {
    return null;
  }
};

export default OrderStatus;
