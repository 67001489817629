import React, { FunctionComponent } from "react";
import Col from "components/Grid/Col";
import Container from "components/Grid/Container";
import {
  ButtonBox,
  CTAButton,
  Description,
  Header,
  HeroRow,
  HeroStyles,
  HeroSVGWrapper,
  Title,
} from "./AnimatedHero.styles";
import DataUnderAnimation from "./dataUnderAnimations";
import Row from "components/Grid/Row";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as HeroLuxExt } from "assets/hero-lux-ext.svg";
import TextTransition, { presets } from "react-text-transition";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down, up } from "styled-breakpoints";
import { HashLink } from "react-router-hash-link";
import { HASH_ROUTE } from "types/enums";
import { scrollForPaymentGateway } from "utils/scrollForPaymentGateway";
import { useAppSelector } from "store/hooks";
import { selectBuySection } from "app/features/utils";

const AnimatedHero: FunctionComponent = () => {
  const { t } = useTranslation();
  const isShowBuySection = useAppSelector(selectBuySection);
  const {
    i18n: { language },
  } = useTranslation();
  const [index, setIndex] = React.useState(0);
  const wordsForCarousel = ["Crypto", "Metaverse", "NFTs", "DeFi", "Play2Earn"];

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <HeroStyles>
      <Container heroImage style={{ position: "relative" }}>
        <HeroRow justify="space-between">
          <Col justify="center">
            <Title>LUCA Coin</Title>
            {useBreakpoint(down("lg")) && (
              <Header style={{ fontSize: language === "id" ? "22px" : "24px", padding: "0 45px" }}>
                <Trans i18nKey="hero.title" />{" "}
                <div style={{ display: "inline-block", width: "130px" }}>
                  <p>
                    <TextTransition
                      text={wordsForCarousel[index % wordsForCarousel.length]}
                      springConfig={presets.wobbly}
                    />
                  </p>
                </div>
              </Header>
            )}
            {useBreakpoint(up("xl")) && (
              <Header style={{ fontSize: language === "id" ? "44px" : "48px", textAlign: "left" }}>
                <Trans i18nKey="hero.title" />{" "}
                <div style={{ display: "inline-block", width: "200px" }}>
                  <p>
                    <TextTransition
                      text={wordsForCarousel[index % wordsForCarousel.length]}
                      springConfig={presets.wobbly}
                    />
                  </p>
                </div>
              </Header>
            )}
            <Description>{t("hero.description")}</Description>
            <ButtonBox>
              <HashLink
                to={isShowBuySection ? HASH_ROUTE.BUY_LUCA : HASH_ROUTE.BUY_EXTERNAL_MARKETS}
                scroll={(el) => scrollForPaymentGateway(el, isShowBuySection)}
              >
                <CTAButton outlined>{t("hero.CTA").toUpperCase()}</CTAButton>
              </HashLink>
            </ButtonBox>
            <Row align="center" justify="center" style={{ marginTop: "30px" }}>
              <DataUnderAnimation />
            </Row>
          </Col>
          <Col justify="center" style={{ position: "relative" }}>
            <HeroSVGWrapper>
              <HeroLuxExt />
            </HeroSVGWrapper>
          </Col>
        </HeroRow>
      </Container>
    </HeroStyles>
  );
};

export default AnimatedHero;
