import styled, { css } from "styled-components";
import { down } from "styled-breakpoints";
import { GridProps } from "types/grid";

export default styled.div<GridProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${down("lg")} {
    align-items: center;
    justify-content: center;
  }
  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}
`;
