import * as React from "react";
import { PhotoContainerProps } from "types/photoContainer";
import styled, { css } from "styled-components";
import theme from "theme";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { ReactComponent as LinkedinWhiteIcon } from "assets/icons/custom/linkedin-white.svg";
import { ReactComponent as LinkedinIcon } from "assets/icons/custom/linkedin.svg";
import { down } from "styled-breakpoints";
import Header from "components/Header/Header";

const PhotoWrapper = styled.div<{
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
}>`
  text-align: center;
  border-radius: 8px;
  min-height: 500px;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Description = styled.div<{ align?: string; rotated?: boolean }>`
  min-width: 180px;
  padding: 20px 0;
  color: ${theme.colors.primary800};
  font-size: ${theme.fontSizes.regular};
  line-height: 160%;
  word-break: break-word;
  letter-spacing: 0.05em;
  text-align: justify;

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ rotated }) =>
    rotated &&
    css`
      padding: 30px;
      font-size: ${theme.fontSizes.small};
    `}
`;

const RotatedContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  position: relative;
`;

const PhotoContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  position: relative;

  img {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const DescriptionContainer = styled.div`
  position: absolute;
  bottom: 18px;
  left: 18px;
  border-radius: ${theme.borderRadius.md};
  background: ${theme.colors.gray600};
  height: 65px;
  padding: 8px 16px;
  text-align: left;
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${down("lg")} {
    width: 100%;
    left: 0;
    border-radius: 0;
  }
`;

const NameHeader = styled(Header)`
  line-height: 0;
  padding-bottom: 10px;
  font-size: ${theme.fontSizes.regular};

  ${down("sm")} {
    font-size: ${theme.fontSizes.small};
  }
`;

const RoleParagraph = styled.p`
  color: ${theme.colors.primary800};
  font-size: ${theme.fontSizes.regular};
  text-transform: uppercase;

  ${down("sm")} {
    font-size: ${theme.fontSizes.small};
  }
`;

const ImageContainer: React.FunctionComponent<PhotoContainerProps> = ({
  name,
  title,
  image,
  description,
  url,
}) => {
  const [isRotated, setIsRotated] = React.useState(false);
  const { width } = useWindowDimensions();

  const onMouseEnter = () => {
    if (width > 1024) {
      setIsRotated(true);
    }
  };

  const onMouseLeave = () => {
    if (width > 1024) {
      setIsRotated(false);
    }
  };

  return (
    <Container>
      <PhotoWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {isRotated ? (
          <RotatedContainer className={`photo ${isRotated ? "rotated" : ""}`}>
            <Description rotated>{description}</Description>
            <a href={url} target="_blank" rel="noreferrer">
              <LinkedinWhiteIcon />
            </a>
          </RotatedContainer>
        ) : (
          <PhotoContainer>
            <img src={image} alt={title} draggable={false} loading="lazy" />
            <DescriptionContainer>
              <Row align="center" justify="center">
                <Col justify="center">
                  <NameHeader level={3} fontWeight="bold">
                    {name}
                  </NameHeader>
                  <RoleParagraph>{title}</RoleParagraph>
                </Col>
                {width < 1024 && (
                  <Col align="flex-end">
                    <a href={url} target="_blank" rel="noreferrer">
                      <LinkedinIcon />
                    </a>
                  </Col>
                )}
              </Row>
            </DescriptionContainer>
          </PhotoContainer>
        )}
      </PhotoWrapper>
      {width < 1024 && <Description>{description}</Description>}
    </Container>
  );
};

export default ImageContainer;
