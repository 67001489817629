import Description from "components/Description";
import { down, up } from "styled-breakpoints";
import styled, { css } from "styled-components";
import theme from "theme";

export const TokenUtilityStyles = styled.div`
  padding: 48px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TitleDescription = styled(Description)`
  max-width: 490px;
  margin: 0;
  font-size: ${theme.fontSizes.regular};
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-bottom: 50px;
  margin-right: 20px;
  ${down("lg")} {
    width: 100%;
    ${TitleDescription} {
      max-width: 100%;
    }
  }
`;

export const CardDescription = styled(Description)<{ primary?: boolean; isMobile?: boolean }>`
  font-size: ${theme.fontSizes.small};
  opacity: 1;
  line-height: 160%;

  ${({ primary }) =>
    primary &&
    css`
      color: ${theme.colors.white};
    `};

  ${up("md")} {
    font-size: ${theme.fontSizes.regular};
    line-height: 140%;
  }

  ${down("lg")} {
    text-align: center;
    ${({ align }) =>
      align &&
      css`
        text-align: ${align};
      `}
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: ${theme.fontSizes.medium};
    `}
`;

export const Card = styled.div<{
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  primary?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-height: 400px;
  border: 1px solid ${theme.colors.gray200};
  background: ${theme.colors.gray600};
  width: 25%;
  padding: 0 16px;
  ${down("lg")} {
    width: 100%;
    padding: 0 16px;
  }

  h3 {
    font-size: ${theme.fontSizes.medium};
  }

  ${({ primary }) =>
    primary &&
    css`
      background: ${theme.colors.primary};
      h3 {
        color: ${theme.colors.white};
      }
    `}
`;

export const SVGWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  svg {
    width: 80px;
    height: 80px;
    align-self: flex-end;
  }
`;

export const LottieWrapper = styled.div`
  width: 80px;
  height: 80px;
`;
