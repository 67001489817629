import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/hooks";
import Cookies from "js-cookie";
import { ROUTE } from "types/enums";
import { logoutUser } from "../api/user";
import { User } from "types/user";

export const isUserLoggedIn = () => {
  return !(!Cookies.get("loginSession") || !localStorage.getItem("refresh_token"));
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    refresh_token: null,
    userInfo: null,
    loading: false,
    authCode: "",
    logout: "",
    logoutModal: false,
    authTokenExpiry: null,
  } as {
    user: null | any;
    token: null | string;
    refresh_token: null | string;
    userInfo: null | User;
    loading: boolean;
    authCode: string;
    logout: string;
    logoutModal: boolean;
    authTokenExpiry: number | null;
  },
  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, token, refresh_token },
      }: PayloadAction<{ user: any; token: string; refresh_token: string }>
    ) => {
      state.user = user;
      state.token = token;
      state.refresh_token = refresh_token;
      sessionStorage.clear();
      localStorage.setItem("refresh_token", refresh_token);
    },
    setUserInfo: (state, action: PayloadAction<User>) => {
      state.userInfo = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAuthCode: (state, action: PayloadAction<string>) => {
      state.authCode = action.payload;
    },
    setLogout: (state, action: PayloadAction<string>) => {
      state.logout = action.payload;
      logoutUser(state.logout).then((r) => r);
      sessionStorage.clear();
      localStorage.removeItem("refresh_token");
      Cookies.remove("loginSession", {
        path: ROUTE.HOME,
      });
      Cookies.remove("session", {
        path: ROUTE.HOME,
      });
    },
    setLogoutModal: (state, action: PayloadAction<boolean>) => {
      state.logoutModal = action.payload;
    },
    setAuthTokenExpiry: (state, action: PayloadAction<number>) => {
      state.authTokenExpiry = action.payload;
    },
  },
});
//actions

export const {
  setCredentials,
  setUserInfo,
  setLoading,
  setAuthCode,
  setLogout,
  setLogoutModal,
  setAuthTokenExpiry,
} = authSlice.actions;

//reducer

export default authSlice.reducer;

//selectors

export const selectCurrentUser = (state: RootState) => state.auth.user;

export const userAuthToken = (state: RootState) => state.auth.token;

export const isUserSubmitting = (state: RootState) => state.auth.loading;

export const userAuthCode = (state: RootState) => state.auth.authCode;

export const userInfoSelector = (state: RootState) => state.auth.userInfo;

export const userTokensSelector = (state: RootState) =>
  state.auth.userInfo ? state.auth.userInfo.raised_tokens : 0;

export const sessionLogoutModal = (state: RootState) => state.auth.logoutModal;

export const authTokenExpiration = (state: RootState) => state.auth.authTokenExpiry;
