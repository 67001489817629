import { SocialLinkProps } from "types/link";

export const socialsItems: SocialLinkProps[] = [
  {
    name: "Twitter",
    link: "https://twitter.com/LucrosusCapital",
    iconName: "twitter",
  },
  {
    name: "Telegram Chat",
    link: "https://t.me/lucrosus_capital",
    iconName: "telegramChat",
  },
  {
    name: "Telegram News",
    link: "https://t.me/lucrosus_capital_ann",
    iconName: "telegramNews",
  },
  {
    name: "Linkedin",
    link: "https://www.linkedin.com/company/lucrosus-capital/",
    iconName: "linkedin",
  },
  {
    name: "Medium",
    link: "https://lucrosuscapital.medium.com/",
    iconName: "medium",
  },
];
