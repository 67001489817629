import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTE } from "types/enums";
import { isUserLoggedIn } from "app/features/auth";

const ProtectedViews: React.FunctionComponent = () => {
  return isUserLoggedIn() ? <Outlet /> : <Navigate to={ROUTE.HOME} />;
};

export default ProtectedViews;
