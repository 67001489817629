import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/hooks";
import { ROUTE } from "types/enums";
import { showBuySection, showOurTeamSection, showStakingSection } from "app/api/user";

export const getShowBuySection = createAsyncThunk("utils/getShowBuySection", async () => {
  try {
    const response = await showBuySection();
    return response.data.value;
  } catch (err) {
    console.log(err);
  }
});

export const getShowOurTeamSection = createAsyncThunk("utils/getShowOurTeamSection", async () => {
  try {
    const response = await showOurTeamSection();
    return response.data.value;
  } catch (err) {
    console.log(err);
  }
});

export const getShowStakingSection = createAsyncThunk("utils/getShowStakingSection", async () => {
  try {
    const response = await showStakingSection();
    return response.data.value;
  } catch (err) {
    console.log(err);
  }
});

const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    modalVisible: false,
    paymentModalVisible: false,
    modalOverlayStyle: "",
    locationToRedirect: ROUTE.HOME,
    blockerPopup: false,
    navOpen: false,
    mobileLangModal: false,
    activeTab: 0,
    pageWithdraw: 0,
    showBuySection: false,
    showOurTeamSection: false,
    showStakingSection: false,
  } as {
    modalVisible: boolean;
    paymentModalVisible: boolean;
    modalOverlayStyle: null | string;
    locationToRedirect: null | string;
    blockerPopup: boolean;
    navOpen: boolean;
    mobileLangModal: boolean;
    activeTab: number;
    pageWithdraw: number;
    showBuySection: boolean;
    showOurTeamSection: boolean;
    showStakingSection: boolean;
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShowBuySection.fulfilled, (state, action) => {
        state.showBuySection = action.payload;
      })
      .addCase(getShowOurTeamSection.fulfilled, (state, action) => {
        state.showOurTeamSection = action.payload;
      })
      .addCase(getShowStakingSection.fulfilled, (state, action) => {
        state.showStakingSection = action.payload;
      });
  },
  reducers: {
    setModalVisible: (state, action: PayloadAction<boolean>) => {
      state.modalVisible = action.payload;
    },
    setPaymentModalVisible: (state, action: PayloadAction<boolean>) => {
      state.paymentModalVisible = action.payload;
    },
    setModalOverlayStyle: (state, action: PayloadAction<string>) => {
      state.modalOverlayStyle = action.payload;
    },
    setLocationToRedirect: (state, action: PayloadAction<string>) => {
      state.locationToRedirect = action.payload;
    },
    setBlockerPopup: (state, action: PayloadAction<boolean>) => {
      state.blockerPopup = action.payload;
    },
    setNavOpen: (state, action: PayloadAction<boolean>) => {
      state.navOpen = action.payload;
    },
    setModalLangOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileLangModal = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setModalVisible,
  setPaymentModalVisible,
  setModalOverlayStyle,
  setLocationToRedirect,
  setBlockerPopup,
  setNavOpen,
  setModalLangOpen,
  setActiveTab,
} = utilsSlice.actions;

export default utilsSlice.reducer;
export const isModalVisible = (state: RootState) => state.utils.modalVisible;
export const isPaymentModalVisible = (state: RootState) => state.utils.paymentModalVisible;
export const modalOverlay = (state: RootState) => state.utils.modalOverlayStyle;
export const selectLocation = (state: RootState) => state.utils.locationToRedirect;
export const blockedPopup = (state: RootState) => state.utils.blockerPopup;
export const isNavOpen = (state: RootState) => state.utils.navOpen;
export const isMobileLangModalOpen = (state: RootState) => state.utils.mobileLangModal;
export const selectedTab = (state: RootState) => state.utils.activeTab;
export const selectBuySection = (state: RootState) => state.utils.showBuySection;
export const selectOurTeamSection = (state: RootState) => state.utils.showOurTeamSection;
export const selectStakingSection = (state: RootState) => state.utils.showStakingSection;
