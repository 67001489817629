import * as React from "react";
import { icons } from "./icons";

export const paths = {
  ...icons,
  default: <path />,
} as const;

export const getPath = (name: PathName) => {
  return paths[name] ?? paths["default"];
};

export type PathName = keyof typeof paths;
