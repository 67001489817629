import { Container } from "components/Grid";
import Button from "components/Button";
import { HashLink } from "react-router-hash-link";
import styled, { css } from "styled-components";
import theme from "theme";
import Modal from "components/modal";
import { Link } from "react-router-dom";
import { NavigationTheme } from "types/navigation";
import { down } from "styled-breakpoints";

const changeNavigationProperties = (color: NavigationTheme) => {
  switch (color) {
    case "dark":
      return css`
        background-color: ${theme.colors.primary700};
        color: ${theme.colors.white};
        box-shadow: ${theme.darkBoxShadow};
        border-bottom: 1px solid ${theme.colors.primary700};
      `;
    case "light":
      return css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.primary800};
        box-shadow: ${theme.boxShadow};
        border-bottom: ${theme.border};
      `;
    default:
      return css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.primary800};
        box-shadow: 0 4px 12px 0 ${theme.colors.white};
        border-bottom: ${theme.border};
      `;
  }
};

const changeColor = (color: NavigationTheme) => {
  switch (color) {
    case "dark":
      return css`
        color: ${theme.colors.white};
      `;
    case "light":
      return css`
        color: ${theme.colors.primary800};
      `;
    default:
      return css`
        color: ${theme.colors.white};
      `;
  }
};

export const AppBar = styled.div<{ color?: NavigationTheme }>`
  position: sticky;
  top: 0;
  z-index: 4;
  width: 100%;
  height: auto;
  transition: all 0.2s ease;
  /* dropped font size by 2px due to long words in nederland translations */
  font-size: ${theme.fontSizes.small};
  ${({ color }) =>
    color &&
    css`
      ${changeNavigationProperties(color)};
    `};
`;

export const NavContainer = styled(Container)`
  padding: 0 38px 0 48px;
  width: 100%;
  ${down("lg")} {
    padding: 0 24px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
`;

export const NavItemStyles = css`
  color: ${theme.colors.primary800};
  text-decoration: none;
  transition: ${theme.transition};
  text-transform: ${theme.title.uppercase};
  margin: 0 16px;
  font-weight: ${theme.fontWeights.bold};
  &:hover {
    color: ${theme.colors.primary};
    transition: ${theme.transition};
    text-decoration: underline;
  }
  @media (min-width: 1199px) and (max-width: 1365px) {
    margin: 0 8px;
  }
`;

export const NavOutLink = styled.a<{ color?: NavigationTheme }>`
  ${NavItemStyles};
  ${({ color }) =>
    color &&
    css`
      ${changeColor(color)}
    `}
`;

export const NavLink = styled(HashLink)<{ color?: NavigationTheme }>`
  ${NavItemStyles};
  ${({ color }) =>
    color &&
    css`
      ${changeColor(color)}
    `}
`;

export const InnerLink = styled(Link)<{ color?: NavigationTheme }>`
  ${NavItemStyles};
  ${({ color }) =>
    color &&
    css`
      ${changeColor(color)}
    `}
`;

export const ProductsDropdown = styled.li<{ color?: NavigationTheme }>`
  ${NavItemStyles};
  cursor: pointer;
  display: inline;
  list-style: none;
  text-align: center;
  ${({ color }) =>
    color === "dark" &&
    css`
      color: ${theme.colors.white};
    `}
`;

export const MobileWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const BuyButton = styled(Button)`
  margin-left: 16px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  Button {
    min-width: 150px;
    width: auto;
  }
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DesktopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-left: 50px;
  width: 100%;
`;

// Improved mobile navigation

export const MainMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px 24px 24px;
`;

export const IconWrapper = styled.div`
  width: 24px;
  margin-right: 12px;
  height: 24px;
`;

export const ArrowWrapper = styled.button`
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
`;

export const ArrowWrapperMobile = styled(ArrowWrapper)`
  position: absolute;
  right: 0;
`;

export const MainMenuItem = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-start;
  margin: 24px 0;
  font-size: ${theme.fontSizes.regular};
  font-weight: ${theme.fontWeights.bold};
  width: 100%;
  color: ${theme.colors.primary700};
  position: relative;
  a {
    margin: 0;
  }
  ${down("lg")} {
    li {
      display: flex;
      list-style: none;
      align-items: center;
    }
  }
`;

export const SubMenuItem = styled(MainMenuItem)`
  padding: 12px 0;
  text-transform: none;
`;

export const BottomMenu = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px 24px 24px;
  z-index: 100;
  background: ${theme.colors.white};
  ${ButtonWrapper} {
    Button {
      width: 100%;
      margin: 10px 0;
      font-weight: ${theme.fontWeights.bold};
    }
  }
`;

export const ProductsItemWrapper = styled.div`
  display: flex;
  margin: 18px 0;
  flex-direction: column;
  color: ${theme.colors.primary700};
  gap: 40px;
`;

export const SubSubMenu = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-size: ${theme.fontSizes.regular};
  font-weight: ${theme.fontWeights.bold};
  width: 100%;
  color: ${theme.colors.primary700};
  position: relative;
  a {
    margin: 0;
  }
`;

export const SubMenuItemWrapper = styled(ProductsItemWrapper)`
  div {
    display: flex;
    align-items: center;
    text-transform: none;
    * {
      font-size: ${theme.fontSizes.small};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      margin-right: 10px;
    }
  }
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  border: 1px solid ${theme.colors.neutral300};
  margin: 0;
`;

export const StyledModal = styled(Modal)`
  height: auto;
  overflow-y: auto;
`;

export const MobileLangButton = styled.button`
  font-family: "Roboto", sans-serif;
  font-size: ${theme.fontSizes.regular};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.primary700};
  border: none;
  background-color: transparent;
  padding-left: 5px;
`;

export const LangSelectorMobileWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
