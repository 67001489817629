import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/hooks";

const transactionsSlice = createSlice({
  name: "Transactions",
  initialState: {
    currentPrice: 0 as number,
    transactions: [] as any,
    paymentError: null as string | null,
    dollarsQuantity: 0 as number,
    tokensQuantity: 100 as number,
  },
  reducers: {
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    setPaymentInitError: (state, action: PayloadAction<string | null>) => {
      state.paymentError = action.payload;
    },
    setDollarsQuantity: (state, action: PayloadAction<number>) => {
      state.dollarsQuantity = action.payload;
    },
    setTokensQuantity: (state, action: PayloadAction<number>) => {
      state.tokensQuantity = action.payload;
    },
    setCurrentPrice: (state, action: PayloadAction<number>) => {
      state.currentPrice = action.payload;
    },
  },
});

export const {
  setTransactions,
  setCurrentPrice,
  setPaymentInitError,
  setDollarsQuantity,
  setTokensQuantity,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;

export const userTransactions = (state: RootState) => state.transactions;
export const currentPhasePriceToken = (state: RootState) => state.transactions.currentPrice;
