import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import theme from "theme";
import {
  BackNavWrapper,
  DefaultInput,
  Errors,
  Form,
  FormContainer,
  HaveAccount,
  Info,
  InputContainer,
  LinkToLogin,
  MainWrapper,
  MobileLogoWrapper,
  PasswordRequirementInfo,
  StyledLogo,
  TermsInfo,
  TermsLabel,
  Title,
} from "../authStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import SuccessSignUpModal from "../../ModalInstance";
import SideComponent from "../LeftSideSection";
import { down } from "styled-breakpoints";
import { InputErrorProps, RegisterFieldProps } from "types/authTypes";
import { useNavigate } from "react-router-dom";
import NavigateToPrevious from "../BackButton";
import ReactGA from "react-ga";
import { ROUTE } from "types/enums";
import { trackPage } from "utils/adform";
import ReCaptcha from "../../Recaptcha";
import { registerUser } from "app/api/user";
import { ButtonContinue } from "services/Recaptcha";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { isUserSubmitting, setLoading } from "app/features/auth";
import { isModalVisible, setModalOverlayStyle, setModalVisible } from "app/features/utils";
import Cookies from "js-cookie";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";

const EmailInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const PasswordInput = styled.input`
  ${DefaultInput}
`;
const PasswordRepeatInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const ReferralInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const CheckboxErrors = styled.span`
  width: 285px;
  text-align: left;
  position: relative;
  color: ${theme.colors.error};
  font-size: ${theme.fontSizes.small};
  line-height: ${theme.lineHeightSmall};
  margin: -20px 0 0 -50px;

  ${down("md")} {
    margin: -20px 0 0 0;
    width: 285px;
  }
`;

const CheckboxContainer = styled.div`
  width: 385px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${down("md")} {
    width: 335px;
  }
`;
const Checkbox = styled.input<InputErrorProps>`
  display: flex;
  position: relative;
  cursor: pointer;
  margin: 5px 15px 0 0;

  :focus {
    outline: none;

    :after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      z-index: 1;
      width: 11px;
      height: 11px;
      border: 1px solid ${theme.colors.primary800};
      border-radius: 3px;
    }
  }

  ${({ invalid }) =>
    !invalid &&
    css`
      :after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        z-index: 1;
        width: 11px;
        height: 11px;
        border: 1px solid ${theme.colors.error};
        border-radius: 3px;
      }
    `};
`;

const LegalNoticeLink = styled.a`
  line-height: ${theme.lineHeightMedium};
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.medium};
  text-align: left;
  color: ${theme.colors.primary800};
  text-decoration: underline;
  cursor: pointer;

  :focus {
    outline: 1px solid ${theme.colors.primary800};
    border-radius: 4px;
  }

  ${down("md")} {
    font-size: ${theme.fontSizes.superSmall};
  }
`;

const SignUp: FC<RegisterFieldProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const signUpSchema = yup.object().shape({
    email: yup.string().email().required(t("authorization.signUp.pleaseEmail")),
    password: yup
      .string()
      .required(t("authorization.signUp.pleasePassword"))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t("authorization.signUp.req")
      ),
    passwordConfirmation: yup
      .string()
      .required(t("authorization.signUp.pleaseRepeatPassword"))
      .oneOf([yup.ref("password"), null], t("authorization.signUp.dontMatch")),
    termsAccepted: yup.bool().oneOf([true], t("authorization.signUp.pleaseAccept")).required(),
  });

  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [newsletterChecked, setNewsletterChecked] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>("");

  const dispatch = useAppDispatch();
  const showModal = useAppSelector(isModalVisible);
  const isSubmitting = useAppSelector(isUserSubmitting);
  const urlReferralFromSignUp = new URLSearchParams(window.location.search).get("referral");

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<RegisterFieldProps>({
    resolver: yupResolver(signUpSchema),
  });

  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
  });

  const toggleModal = () => {
    dispatch(setModalVisible(true));
    dispatch(setModalOverlayStyle("transparent"));
  };

  const handleNavigateToMainPage = () => navigate(ROUTE.HOME);

  const handleChange = (event: { target: HTMLInputElement } & React.FormEvent) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const onVerifyCaptcha = (token: string) => {
    setValue("captchaToken", token);
  };

  useEffect(() => {
    register("captchaToken", { required: true });
  });

  const onSubmit = async (formState: { isDirty: boolean }) => {
    if (!formState.isDirty) {
      setValue("referralCode", referralCode);
      setReferralCode(referralCode);
    }

    const signUpData: any = new FormData();
    signUpData.append("email", formValue.email);
    signUpData.append("password", formValue.password);
    signUpData.append("password_confirmation", formValue.passwordConfirmation);
    signUpData.append("referral_code", referralCode.length > 6 ? referralCode : "");
    signUpData.append("terms_accepted", termsChecked);
    signUpData.append("marketing_terms_accepted", newsletterChecked);
    signUpData.append("registration_promo", false);
    dispatch(setLoading(true));

    try {
      const response = await registerUser(signUpData);
      if (response.status === 201) {
        ReactGA.event({
          category: "Sign Up",
          action: "Submit Sign Up form",
          label: "New user has successfully registered - 1 step",
        });
        setFormValue({
          email: "",
          password: "",
          passwordConfirmation: "",
        });

        setTimeout(() => {
          dispatch(setLoading(false));
        }, 2000);
        setReferralCode("");
        setNewsletterChecked(false);
        setTermsChecked(false);
        toggleModal();
      }
    } catch (err: any) {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 2000);
      if (err.response.data.details[0].internal_status === 4221) {
        setError("referralCode", {
          type: "manual",
          message: "Invalid referral code.",
        });
        ReactGA.event({
          category: "Sign Up",
          action: "Submit Sign Up form",
          label: "The new user has not been successfully registered - 1 step",
        });
        trackPage("Sign Up Button");
      }
      if (err.response.data.details[0].internal_status === 4220) {
        setError("email", {
          type: "manual",
          message: t("authorization.signUp.alreadyExist"),
        });
        ReactGA.event({
          category: "Sign Up",
          action: "Submit Sign Up form",
          label: "The new user has not been successfully registered - 1 step",
        });
        trackPage("Sign Up Button");
      }
      if (err.response.status === 500) {
        setError("email", {
          type: "manual",
          message:
            "The site is so popular that the server is currently overloaded. Please try again later.",
        });
        ReactGA.event({
          category: "Sign Up",
          action: "Submit Sign Up form",
          label: "The new user has not been successfully registered - 1 step",
        });
        trackPage("Sign Up Button");
      }
    }
  };

  useEffect(() => {
    if (urlReferralFromSignUp) {
      setReferralCode(urlReferralFromSignUp);
    } else if (Cookies.get("referralCode")) {
      const referralCode = Cookies.get("referralCode") as string;
      setReferralCode(referralCode);
    }
    const withReferral = referralCode.length > 6 ? " with referral" : "";

    ReactGA.event({
      category: "Sign Up",
      action: "Display Sign Up form" + withReferral,
    });
    trackPage("Sign Up Page");
  }, [referralCode, setReferralCode]);

  return (
    <MainWrapper>
      <SuccessSignUpModal isModalVisible={showModal} />
      <SideComponent />
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BackNavWrapper>
            <NavigateToPrevious />
            <MobileLogoWrapper>
              <StyledLogo onClick={handleNavigateToMainPage} />
            </MobileLogoWrapper>
          </BackNavWrapper>
          <Title>{t("authorization.signUp.signUp")}</Title>
          <Info>{t("authorization.signUp.partner")}</Info>
          <InputContainer>
            {errors?.email?.message && <Errors>{errors.email.message}</Errors>}
            <EmailInput
              type="email"
              placeholder={t("authorization.signUp.email")}
              value={formValue.email}
              {...register("email")}
              onChange={handleChange}
              invalid={Boolean(errors.email)}
            />
            {errors?.password?.message && <Errors>{errors.password.message}</Errors>}
            <PasswordInput
              type="password"
              placeholder={t("authorization.signUp.password")}
              value={formValue.password}
              {...register("password")}
              onChange={handleChange}
              invalid={Boolean(errors.password)}
            />
            <PasswordRequirementInfo>
              {t("authorization.signUp.passwordHint")}
            </PasswordRequirementInfo>
            {errors?.passwordConfirmation?.message && (
              <Errors>{errors.passwordConfirmation.message}</Errors>
            )}
            <PasswordRepeatInput
              type="password"
              placeholder={t("authorization.signUp.repeatPassword")}
              value={formValue.passwordConfirmation}
              {...register("passwordConfirmation")}
              onChange={handleChange}
              invalid={Boolean(errors.passwordConfirmation)}
            />
            {errors?.referralCode?.message && <Errors>{errors.referralCode.message}</Errors>}
            <ReferralInput
              type="text"
              defaultValue={referralCode}
              placeholder={referralCode || t("authorization.signUp.referralCode")}
              {...register("referralCode", {
                value: referralCode,
                onChange: (e) => setReferralCode(e.target.value),
              })}
              invalid={false}
            />

            {errors.termsAccepted?.message && (
              <CheckboxErrors>{errors.termsAccepted.message}</CheckboxErrors>
            )}
          </InputContainer>
          <CheckboxContainer>
            <TermsLabel>
              <Checkbox
                type="checkbox"
                id="newsletter"
                checked={newsletterChecked}
                {...register("newsletter")}
                onChange={() => setNewsletterChecked(!newsletterChecked)}
                invalid={!errors.newsletter}
              />
              <TermsInfo>
                <Trans i18nKey="authorization.signUp.newsletter" />
              </TermsInfo>
            </TermsLabel>
            <TermsLabel>
              <Checkbox
                type="checkbox"
                id="termsAccepted"
                checked={termsChecked}
                {...register("termsAccepted")}
                onChange={() => setTermsChecked(!termsChecked)}
                invalid={!errors.termsAccepted}
              />
              <TermsInfo>
                {t("authorization.signUp.agreements")}{" "}
                <LegalNoticeLink
                  href="https://docs.lucrosus.capital/legal/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("authorization.signUp.terms")}{" "}
                </LegalNoticeLink>{" "}
                {t("authorization.signUp.and")}{" "}
                <LegalNoticeLink
                  href="https://docs.lucrosus.capital/legal/data-protection-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {t("authorization.signUp.data")}
                </LegalNoticeLink>{" "}
                {t("authorization.signUp.turk")}
              </TermsInfo>
            </TermsLabel>
          </CheckboxContainer>
          {isSubmitting ? (
            <ButtonContinue disabled={isSubmitting}>
              {t("authorization.signUp.sending")}
            </ButtonContinue>
          ) : (
            <ReCaptcha
              onVerifyCaptcha={onVerifyCaptcha}
              type="submit"
              size="invisible"
              badge="bottom"
            />
          )}
          <HaveAccount>
            {t("authorization.signUp.haveAccount")}
            <LinkToLogin to={ROUTE.LOG_IN}>{t("authorization.signUp.loginLong")}</LinkToLogin>
          </HaveAccount>
        </Form>
      </FormContainer>
    </MainWrapper>
  );
};

export default SignUp;
