import styled from "styled-components";
import theme from "theme";
import { down } from "styled-breakpoints";

export const ListingStyles = styled.div`
  background: ${theme.colors.white};
  margin-bottom: 50px;
  font-family: ${theme.fonts.body};

  ${down("md")} {
    padding: 20px 0;
  }
`;

export const ListingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    max-width: 243px;
  }

  ${down("md")} {
    flex-direction: column;
    align-items: center;

    & img {
      margin: 12px 0;
    }
  }

  .alice-carousel__dots-item {
    background-color: ${theme.colors.gray600};
    width: 12px;
    height: 12px;

    &.__active {
      background-color: ${theme.colors.primary};
    }
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 26px;
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
  font-weight: ${theme.fontWeights.bold};

  img {
    margin-bottom: 20px;
  }
`;
