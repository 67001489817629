import { useOnClickOutside } from "hooks/useOnClickOutside";
import { FunctionComponent, useRef, useState } from "react";
import { DropdownItemProps, DropdownProps } from "types/dropdown";
import { HASH_ROUTE, ROUTE } from "types/enums";
import {
  IconWrapper,
  ProductDescription,
  ProductDropdownMenu,
  ProductItemHeader,
  ProductItemWrapper,
  ProductMenuStyles,
} from "./Dropdown.styles";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ArrowWrapper, ProductsDropdown } from "components/Navigation/navigation.styles";
import { ReactComponent as NFTIcon } from "assets/icons/products/nft.svg";
import { ReactComponent as TokenIcon } from "assets/icons/products/token.svg";
import { HashLink } from "react-router-hash-link";
import theme from "theme";
import { Col, Row } from "components/Grid";
import scrollWithOffset from "utils/scrollWithOffset";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MenuItem: FunctionComponent<DropdownItemProps> = ({
  name,
  description,
  link,
  icon,
  bgColor,
}) => {
  const lucrosusPoolAnchor = HASH_ROUTE.LUCROSUS_POOL.substring(2, HASH_ROUTE.LUCROSUS_POOL.length);
  const tokenAnchor = HASH_ROUTE.TOKEN.substring(2, HASH_ROUTE.TOKEN.length);

  return (
    <ProductItemWrapper>
      {link ? (
        name === "Token" || name === "Lucrosus Pool" ? (
          location.pathname === "/" ? (
            <HashLink to={link} scroll={(el) => scrollWithOffset(el)} smooth>
              <Row align="center">
                <div style={{ width: 50 }}>
                  <IconWrapper color={bgColor}>{icon}</IconWrapper>
                </div>
                <Col style={{ marginLeft: 10 }}>
                  <ProductItemHeader>{name}</ProductItemHeader>
                  <ProductDescription>{description}</ProductDescription>
                </Col>
              </Row>
            </HashLink>
          ) : (
            <Link
              to={ROUTE.HOME}
              state={{ id: name === "Token" ? tokenAnchor : lucrosusPoolAnchor }}
              color={"light"}
            >
              <Row align="center">
                <div style={{ width: 50 }}>
                  <IconWrapper color={bgColor}>{icon}</IconWrapper>
                </div>
                <Col style={{ marginLeft: 10 }}>
                  <ProductItemHeader>{name}</ProductItemHeader>
                  <ProductDescription>{description}</ProductDescription>
                </Col>
              </Row>
            </Link>
          )
        ) : (
          <Link to={link} onClick={() => window.scrollTo(0, 0)}>
            <Row align="center">
              <div style={{ width: 50 }}>
                <IconWrapper color={bgColor}>{icon}</IconWrapper>
              </div>
              <Col style={{ marginLeft: 10 }}>
                <ProductItemHeader>{name}</ProductItemHeader>
                <ProductDescription>{description}</ProductDescription>
              </Col>
            </Row>
          </Link>
        )
      ) : (
        <Row align="center">
          <div style={{ width: 50 }}>
            <IconWrapper color={bgColor}>{icon}</IconWrapper>
          </div>
          <Col style={{ marginLeft: 10 }}>
            <ProductItemHeader>{name}</ProductItemHeader>
            <ProductDescription>{description}</ProductDescription>
          </Col>
        </Row>
      )}
    </ProductItemWrapper>
  );
};

const Menu: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ProductMenuStyles>
      <MenuItem
        name={t("navigation.token")}
        description={t("navigation.productDropdown.tokenDescription")}
        link={HASH_ROUTE.TOKEN}
        icon={<TokenIcon />}
        bgColor={theme.colors.secondary100}
      />
      <MenuItem
        name={t("navigation.lucrosusPool")}
        description={t("navigation.productDropdown.poolDescription")}
        link={HASH_ROUTE.LUCROSUS_POOL}
        icon={<TokenIcon />}
        bgColor={theme.colors.primary100}
      />
      <ProductItemWrapper>
        <a href="https://www.lucrosus.gallery/" target="_blank" rel="noreferrer">
          <Row align="center">
            <div style={{ width: 50 }}>
              <IconWrapper color={theme.colors.neutral300}>
                <NFTIcon />
              </IconWrapper>
            </div>
            <Col style={{ marginLeft: 10 }}>
              <ProductItemHeader>{t("navigation.gallery")}</ProductItemHeader>
              <ProductDescription>
                {t("navigation.productDropdown.galleryDescription")}
              </ProductDescription>
            </Col>
          </Row>
        </a>
      </ProductItemWrapper>
    </ProductMenuStyles>
  );
};

const ProductDropdown: FunctionComponent<DropdownProps> = ({ color }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));
  const { t } = useTranslation();

  const ArrowStyles = {
    transform: isOpen ? "rotate(0.5turn)" : "none",
    transition: "transform 0.2s ease",
  };

  return (
    <ProductDropdownMenu ref={ref} isOpen={isOpen} onClick={handleClick}>
      <ProductsDropdown color={color}>
        {t("navigation.products")}{" "}
        <ArrowWrapper>
          <ArrowDownIcon
            stroke={color === "dark" ? theme.colors.white : theme.colors.primary800}
            style={ArrowStyles}
          />
        </ArrowWrapper>
      </ProductsDropdown>
      {isOpen && <Menu />}
    </ProductDropdownMenu>
  );
};

export default ProductDropdown;
