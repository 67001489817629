import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "locales/en/translation.json";
import turkishTranslation from "locales/tr/translation.json";
import frenchTranslation from "locales/fr/translation.json";
import polishTranslation from "locales/pl/translation.json";
import spainTranslation from "locales/es/translation.json";
import germanTranslation from "locales/de/translation.json";
import indonesianTranslation from "locales/id/translation.json";
import hinduTranslation from "locales/hi/translation.json";
import nederlandTranslation from "locales/nl/translation.json";
import chinaTranslation from "locales/zh/translation.json";
import GreatBritainFlag from "./assets/flags/british.svg";
import TurkishFlag from "./assets/flags/turkish.svg";
import FrenchFlag from "./assets/flags/french.svg";
import PolishFlag from "./assets/flags/polish.svg";
import SpainFlag from "./assets/flags/spain.svg";
import GermanFlag from "./assets/flags/german.svg";
import IndonesianFlag from "./assets/flags/indonesian.svg";
import HindiFlag from "./assets/flags/hindu.svg";
import NederlandFlag from "./assets/flags/nederland.svg";
import ChinaFlag from "./assets/flags/china.svg";

const resources = {
  en: englishTranslation,
  tr: turkishTranslation,
  fr: frenchTranslation,
  pl: polishTranslation,
  es: spainTranslation,
  de: germanTranslation,
  id: indonesianTranslation,
  hi: hinduTranslation,
  nl: nederlandTranslation,
  zh: chinaTranslation,
};

export const getFlag = () => {
  switch (i18n.language) {
    case "en":
      return GreatBritainFlag;
    case "tr":
      return TurkishFlag;
    case "fr":
      return FrenchFlag;
    case "pl":
      return PolishFlag;
    case "es":
      return SpainFlag;
    case "de":
      return GermanFlag;
    case "id":
      return IndonesianFlag;
    case "hi":
      return HindiFlag;
    case "nl":
      return NederlandFlag;
    case "zh":
      return ChinaFlag;
    default:
      return GreatBritainFlag;
  }
};

export const languages = () => {
  if (i18n.language === "en") {
    return t("navigation.eng");
  } else if (i18n.language === "fr") {
    return t("navigation.french");
  } else if (i18n.language === "tr") {
    return t("navigation.turk");
  } else if (i18n.language === "pl") {
    return t("navigation.polish");
  } else if (i18n.language === "es") {
    return t("navigation.spain");
  } else if (i18n.language === "de") {
    return t("navigation.german");
  } else if (i18n.language === "id") {
    return t("navigation.indonesian");
  } else if (i18n.language === "hi") {
    return t("navigation.hindu");
  } else if (i18n.language === "nl") {
    return t("navigation.nederland");
  } else if (i18n.language === "zh") {
    return t("navigation.china");
  }
};

const checkDefaultLanguage = () => {
  const lang = localStorage.getItem("Lang");
  const availableLanguages = ["tr", "fr", "pl", "es", "de", "id", "hi", "nl", "zh"];
  const browserDefaultLanguage = navigator.languages[0].substring(0, 2);

  function checkBrowserLang() {
    if (lang) {
      return lang;
    } else if ([...availableLanguages].includes(browserDefaultLanguage)) {
      return browserDefaultLanguage;
    }
  }

  return checkBrowserLang() || "en";
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: checkDefaultLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
