import * as React from "react";
import { ReactComponent as Logo } from "assets/navigation-logo.svg";
import useWindowDimensions from "hooks/useWindowDimensions";
import { NavigationProps } from "types/navigation";
import AnnouncementBar from "components/AnnouncementBar";
import { useNavigate } from "react-router-dom";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import { AppBar, Nav, Logos, NavContainer } from "./navigation.styles";
import { ROUTE } from "types/enums";
import { getPricePerToken } from "components/ProgressBar/utils";
import camelcaseKeys from "camelcase-keys";
import LogoutModal from "components/LogoutModal";
import { getSalesPhases } from "app/api/phases";
import { setCurrentPhase, setNextPhase, setSalesStats } from "app/features/phases";
import { setCurrentPrice } from "app/features/transactions";
import {
  isUserLoggedIn,
  setAuthTokenExpiry,
  setCredentials,
  setLogout,
  setLogoutModal,
  setUserInfo,
  userAuthToken,
  userInfoSelector,
} from "app/features/auth";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback, useEffect } from "react";
import { getUserInfo, userRefreshToken } from "app/api/user";
import Cookies from "js-cookie";
import theme from "theme";
import { handleSetCookie } from "utils/setCookies";

const Navigation: React.FunctionComponent<NavigationProps> = () => {
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const MAX_WIDTH = 1200;
  const navigate = useNavigate();
  const userInfo = useAppSelector(userInfoSelector);
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const authToken = useAppSelector(userAuthToken);

  const token = localStorage.getItem("refresh_token");

  const checkForAuthTokenAndRenewIfNeeded = useCallback(async () => {
    if (authToken) {
      return authToken;
    } else {
      return await userRefreshToken().then(
        (response) =>
          dispatch(setCredentials(response.data)) &&
          dispatch(setAuthTokenExpiry(Date.now() + response.data.token_expiry_in * 1000)) &&
          handleSetCookie(
            "loginSession",
            new Date(Date.now() + response.data.token_expiry_in * 1000)
          ) &&
          localStorage.setItem("refresh_token", response.data.refresh_token)
      );
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    const sessions = setInterval(async () => {
      if (token && !Cookies.get("loginSession")) {
        dispatch(setLogout(`${authToken}`));
        navigate(ROUTE.HOME);
        dispatch(setLogoutModal(true));
      }
    }, 1000);
    return () => {
      clearInterval(sessions);
    };
  }, [authToken, dispatch, navigate, token]);

  useEffect(() => {
    const sessions = setInterval(async () => {
      if (token && Cookies.get("loginSession") && Cookies.get("session")) {
        await checkForAuthTokenAndRenewIfNeeded();
      }
    }, 1000);
    return () => {
      clearInterval(sessions);
    };
  }, [checkForAuthTokenAndRenewIfNeeded, dispatch, token]);

  useEffect(() => {
    const sessions = setInterval(async () => {
      if (token && Cookies.get("loginSession") && Cookies.get("session") && !userInfo) {
        getUserInfo(`${authToken}`).then(({ data }) => {
          dispatch(setUserInfo(data));
        });
      }
    }, 1000);
    return () => {
      clearInterval(sessions);
    };
  });

  const [currentPhasePriceToken, setCurrentPhasePriceToken] = React.useState<number>(0);

  const handleNavigateToMainPage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(ROUTE.HOME);
  };

  const getPhases = React.useCallback(() => {
    getSalesPhases().then((response) => {
      if (response.status === 200) {
        const {
          totalProceed,
          totalEthProceed,
          totalOperationsProceed,
          totalProceedForCurrentPhase,
          totalReferralsProceed,
          totalUsers,
        } = camelcaseKeys(response.data, { deep: true });
        const phases = camelcaseKeys(response.data, { deep: true });
        dispatch(setCurrentPhase(phases.currentSalesPhase));
        dispatch(setNextPhase(phases.nextSalesPhase));

        const currentPhasePriceToken = getPricePerToken(phases.currentSalesPhase.pricePerToken);

        setCurrentPhasePriceToken(currentPhasePriceToken);
        dispatch(setCurrentPrice(currentPhasePriceToken));
        dispatch(
          setSalesStats({
            totalProceed,
            totalEthProceed,
            totalOperationsProceed,
            totalProceedForCurrentPhase,
            totalReferralsProceed,
            totalUsers,
          })
        );
      } else {
        throw new Error(response.statusText);
      }
    });
  }, [dispatch]);

  React.useEffect(() => {
    getPhases();
  }, [getPhases]);

  return (
    <AppBar color={scrollPosition >= 100 ? "light" : "dark"}>
      <AnnouncementBar />
      <NavContainer>
        <Nav as="nav">
          <Logos>
            <Logo
              fill={scrollPosition >= 100 ? theme.colors.primary800 : theme.colors.white}
              style={{ cursor: "pointer", width: "90%" }}
              onClick={handleNavigateToMainPage}
            />
            {scrollPosition >= 100 ? (
              <img
                src={require("assets/hacken-logo-black.png")}
                alt="logo"
                style={{ width: "180px", height: "10px", marginTop: "5px" }}
              />
            ) : (
              <img
                src={require("assets/hacken-logo-white.png")}
                alt="logo"
                style={{ width: "180px", height: "10px", marginTop: "5px" }}
              />
            )}
          </Logos>
          {width >= MAX_WIDTH ? (
            <DesktopNavigation
              isLogged={isUserLoggedIn()}
              pricePerToken={currentPhasePriceToken}
              color={scrollPosition >= 100 ? "light" : "dark"}
            />
          ) : (
            <MobileNavigation
              isLogged={isUserLoggedIn()}
              pricePerToken={currentPhasePriceToken}
              email={userInfo?.email || ""}
              color={scrollPosition >= 100 ? "light" : "dark"}
            />
          )}
        </Nav>
      </NavContainer>
      <LogoutModal isModalVisible={false} />
    </AppBar>
  );
};

export default Navigation;
