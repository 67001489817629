import React, { FC, useEffect } from "react";
import {
  ModalDesktopContainer,
  Header,
  Content,
  Button,
  ModalContainer,
} from "../ModalInstance/modalPopupStyles";
import { ModalInstanceProps } from "types/modalTypes";
import Modal from "../ModalInstance/modal";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "theme";
import moment from "moment";
import { ReactComponent as Logo } from "assets/logo.svg";
import ReactGA from "react-ga";
import { ROUTE } from "types/enums";
import { trackPage } from "utils/adform";
import { getTransactionsList } from "app/api/payments";
import { setTransactions } from "app/features/transactions";
import { getUserInfo } from "app/api/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setUserInfo, userAuthToken } from "app/features/auth";
import { useTranslation } from "react-i18next";

const StyledModalContainer = styled(ModalContainer)`
  height: 448px;
  width: 624px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 32px;

  button {
    margin-top: 32px;
    width: 100%;
  }

  ${down("md")} {
    padding: 10px;
    height: 440px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const StyledModalDesktopContainer = styled(ModalDesktopContainer)`
  display: flex;
  justify-content: flex-start;
  height: 488px;
  width: 624px;

  ${down("md")} {
    height: 480px;
    width: 90%;
  }
`;

const StyledHeader = styled(Header)`
  margin-top: 18px;
  width: 100%;
  text-align: left;
  font-size: 20px;

  span {
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      height: 20px;
      width: 20px;
      border-top: 1px solid #000;
      position: absolute;
      top: 0;
      right: 20px;
      transform: rotate(-45deg);
    }

    &:before {
      right: 34px;
      transform: rotate(45deg);
    }

    &:hover {
      opacity: 0.3;
    }
  }

  ${down("md")} {
    text-align: center;

    span {
      &:after,
      &:before {
        top: -5px;
        right: 6px;
      }

      &:before {
        right: 20px;
      }
    }
  }
`;

const StyledContent = styled(Content)`
  text-align: left;
  width: 100%;
  line-height: 24px;
  margin-top: 10px;

  ${down("md")} {
    text-align: center;
    font-size: ${theme.fontSizes.small};
  }
`;

const LogoWrapper = styled.div`
  margin-top: -35px;
  width: 100%;

  ${down("md")} {
    margin-top: -20px;
  }
`;

const TransactionDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;

  p {
    color: ${theme.colors.primary800};
    opacity: 0.75;
    line-height: 24px;
  }

  span {
    :first-of-type {
      margin-bottom: 12px;
    }

    :last-of-type {
      font-size: 24px;
      font-weight: ${theme.fontWeights.bold};
    }
  }

  ${down("md")} {
    text-align: center;
  }
`;

const StyledLogo = styled(Logo)`
  cursor: pointer;
  height: 80px;

  ${down("md")} {
    width: 100px;
    height: 32px;
  }
`;

const TransactionSuccess: FC<ModalInstanceProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = useAppSelector(userAuthToken);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const redirectToWallet = async () => {
    await getTransactionsList(`${authToken}`).then(({ data }) => {
      dispatch(setTransactions(data));
    });
    await getUserInfo(`${authToken}`).then(({ data }) => {
      dispatch(setUserInfo(data));
    });
    navigate(ROUTE.WALLET);
  };

  const transactionData = new URLSearchParams(location.search).get("payment_created_at");

  useEffect(() => {
    ReactGA.event({
      category: "Checkout",
      action: "Successful checkout",
      label: "Display Order success confirmation popup",
    });
    trackPage("Purchase");
  });

  return (
    <Modal>
      <StyledModalDesktopContainer>
        <StyledModalContainer>
          <LogoWrapper>
            <StyledLogo onClick={redirectToWallet} />
          </LogoWrapper>
          <StyledHeader>
            {t("authorization.successPaymentModal.title")} <span onClick={redirectToWallet} />
          </StyledHeader>
          <StyledContent>{t("authorization.successPaymentModal.description")}</StyledContent>
          <TransactionDetails>
            <p>{t("authorization.successPaymentModal.date")}</p>
            <span>{moment(Number(transactionData) * 1000).format("DD/MM/YYYY")}</span>
          </TransactionDetails>
          <Button onClick={redirectToWallet}>{t("common.backToWallet").toUpperCase()}</Button>
        </StyledModalContainer>
      </StyledModalDesktopContainer>
    </Modal>
  );
};

export default TransactionSuccess;
