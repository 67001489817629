import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/hooks";
import { FilterList } from "types/withdraw";
import { Withdraws } from "types/table";

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState: {
    personForm: {},
    KYCForm: {},
    page: 0,
    formList: [],
    addressTypes: [],
    idTypes: [],
    activeStep: 0,
    withdraws: [],
    showWithdrawsTable: false,
  } as {
    personForm: any;
    KYCForm: any;
    page: number;
    formList: FilterList[];
    addressTypes: FilterList[];
    idTypes: FilterList[];
    activeStep: number;
    withdraws: any;
    showWithdrawsTable: boolean;
  },
  reducers: {
    setPageWithdraw: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setFormList: (state, action: PayloadAction<FilterList[]>) => {
      state.formList = action.payload;
    },
    setAddressTypes: (state, action: PayloadAction<FilterList[]>) => {
      state.addressTypes = action.payload;
    },
    setIdTypes: (state, action: PayloadAction<FilterList[]>) => {
      state.idTypes = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setPersonForm: (state, action: PayloadAction<any>) => {
      state.personForm = action.payload;
    },
    setKYCForm: (state, action: PayloadAction<any>) => {
      state.KYCForm = action.payload;
    },
    setWithdrawsList: (state, action: PayloadAction<Withdraws[]>) => {
      state.withdraws = action.payload;
    },
    setShowWithdrawsTable: (state, action: PayloadAction<boolean>) => {
      state.showWithdrawsTable = action.payload;
    },
  },
});

export default withdrawSlice.reducer;

export const {
  setPageWithdraw,
  setFormList,
  setAddressTypes,
  setIdTypes,
  setActiveStep,
  setPersonForm,
  setKYCForm,
  setWithdrawsList,
  setShowWithdrawsTable,
} = withdrawSlice.actions;

export const page = (state: RootState) => state.withdraw.page;
export const formList = (state: RootState) => state.withdraw.formList;
export const addressTypes = (state: RootState) => state.withdraw.addressTypes;
export const idTypes = (state: RootState) => state.withdraw.idTypes;
export const currentStep = (state: RootState) => state.withdraw.activeStep;
export const personForm = (state: RootState) => state.withdraw.personForm;
export const KYCForm = (state: RootState) => state.withdraw.KYCForm;
export const withdraws = (state: RootState) => state.withdraw.withdraws;
export const isWithdrawsTableShouldShow = (state: RootState) => state.withdraw.showWithdrawsTable;
