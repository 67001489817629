import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import theme from "theme";
import {
  BackNavWrapper,
  DefaultButton,
  DefaultInput,
  Errors,
  Form,
  FormContainer,
  Info,
  InputContainer,
  MainWrapper,
  MobileLogoWrapper,
  StyledLogo,
  Title,
} from "../authStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import SideComponent from "../LeftSideSection/sideSection";
import { down } from "styled-breakpoints";
import { TwoFactorFieldProps } from "types/authTypes";
import { useNavigate } from "react-router-dom";
import NavigateToPrevious from "../BackButton";
import { ROUTE } from "types/enums";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  isUserSubmitting,
  setAuthCode,
  setAuthTokenExpiry,
  setCredentials,
  setLoading,
  setUserInfo,
  userAuthCode,
} from "app/features/auth";
import { toast, ToastContainer } from "react-toastify";
import { generateCryptoOtp, getUserInfo, userTwoFactorAuth } from "app/api/user";
import { getTransactionsList } from "app/api/payments";
import { setTransactions } from "app/features/transactions";
import Cookies from "js-cookie";
import { selectLocation } from "app/features/utils";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";
import { handleSetCookie } from "utils/setCookies";

const TwoFactorAuthenticationCode = styled.input`
  ${DefaultInput}
`;
const ButtonContinue = styled.button`
  ${DefaultButton};
  @media (max-height: 740px) and (max-width: 440px) {
    margin-top: 230px;
  }
  @media (max-height: 670px) and (max-width: 440px) {
    margin-top: 150px;
  }
  @media (min-height: 812px) and (max-width: 440px) {
    margin-top: 320px;
  }
`;
const Counter = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.primary800};
  margin-top: 30px;
  cursor: default;

  button {
    border: none;
    margin-left: 5px;
    background-color: ${theme.colors.white};
    font-size: ${theme.fontSizes.regular};
    color: ${theme.colors.primary};
    text-decoration: underline;
    font-weight: ${theme.fontWeights.bold};
    cursor: pointer;
  }

  ${down("lg")} {
    width: 335px;
  }
`;
const Resend = styled.span`
  font-weight: ${theme.fontWeights.bold};
  margin: 0 5px;
`;

const TwoFactorAuthentication: FC<TwoFactorFieldProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector(isUserSubmitting);
  const authCode = useAppSelector(userAuthCode);
  const locationToRedirect = useAppSelector(selectLocation);

  const twoFactorAuthSchema = yup.object().shape({
    authCode: yup.string().required(t("authorization.2fa.input")),
  });

  const [counter, setCounter] = useState(() => {
    const counterValue = Number(sessionStorage.getItem("2fa-counter"));
    if (counterValue === 0) {
      return 120;
    } else {
      return counterValue;
    }
  });

  const updatedUserKey = () => {
    const userDataKey = sessionStorage.getItem("u-key");
    if (userDataKey) {
      return userDataKey;
    }
  };

  const userKey: ReturnType<typeof updatedUserKey> =
    updatedUserKey() === null || undefined ? "" : updatedUserKey();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<TwoFactorFieldProps>({
    resolver: yupResolver(twoFactorAuthSchema),
  });

  const resendAuthCode = async () => {
    dispatch(setAuthCode(""));
    setCounter(120);
    const data = new FormData();

    data.append("data", `${userKey}`);

    try {
      const response = await generateCryptoOtp(data);
      if (response.status === 200) {
        sessionStorage.clear();
        sessionStorage.setItem("u-key", response.data.data);
        navigate(ROUTE.TWO_FACTOR_AUTH);
      }
    } catch (err: any) {
      // eslint-disable-next-line
      if (err.response.status === 401 || 422) {
        setError("authCode", {
          type: "manual",
          message: t("authorization.2fa.invalid"),
        });
        toast.error(t("authorization.2fa.notification"), {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          closeButton: false,
          pauseOnHover: true,
          progress: undefined,
        });
      }
      if (err.response.status === 500) {
        setError("authCode", {
          type: "manual",
          message:
            "The site is so popular that the server is currently overloaded. Please try again later.",
        });
      }
    }
  };

  useEffect(() => {
    window.sessionStorage.setItem("2fa-counter", `${counter}`);
    const timer = setTimeout(async () => {
      if (Number(counter) > 0) {
        setCounter(Number(counter) - 1);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [counter, setCounter]);

  const handleNavigateToMainPage = () => navigate(ROUTE.HOME);

  const onSubmit = async (formState: { isDirty: boolean }) => {
    if (!formState.isDirty) {
      setValue("authCode", authCode);
      dispatch(setAuthCode(""));
    }
    const twoFactorData = new FormData();

    twoFactorData.append("data", `${userKey}`);
    twoFactorData.append("otp", authCode);
    dispatch(setLoading(true));

    try {
      const response = await userTwoFactorAuth(twoFactorData);
      if (response.status === 201) {
        dispatch(setAuthCode(""));
        dispatch(setAuthTokenExpiry(Date.now() + response.data.token_expiry_in * 1000));

        setTimeout(() => {
          dispatch(setLoading(false));
        }, 2000);

        handleSetCookie("session", new Date(Date.now() + 1000000 * 60 * 1000));
        handleSetCookie(
          "loginSession",
          new Date(Date.now() + response.data.token_expiry_in * 1000)
        );

        dispatch(setCredentials(response.data));
        Cookies.remove("goldTicker", { path: ROUTE.HOME });

        navigate(`${locationToRedirect}`);

        await getTransactionsList(response.data.token).then(({ data }) =>
          dispatch(setTransactions(data))
        );

        await getUserInfo(response.data.token).then(({ data }) => {
          dispatch(setUserInfo(data));
        });
      }
    } catch (err: any) {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 2000);
      // eslint-disable-next-line
      if (err.response.status === 401 || 422) {
        setError("authCode", {
          type: "manual",
          message: t("authorization.2fa.invalid"),
        });
      }
      if (err.response.status === 500) {
        setError("authCode", {
          type: "manual",
          message:
            "The site is so popular that the server is currently overloaded. Please try again later.",
        });
      }
    }
  };

  return (
    <MainWrapper>
      <SideComponent />
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BackNavWrapper>
            <NavigateToPrevious />
            <MobileLogoWrapper>
              <StyledLogo onClick={handleNavigateToMainPage} />
            </MobileLogoWrapper>
          </BackNavWrapper>
          <Title>{t("authorization.2fa.2fa")}</Title>
          <Info>
            <Trans i18nKey={"authorization.2fa.confirm"} />
          </Info>
          <InputContainer>
            {errors?.authCode?.message && <Errors>{errors.authCode.message}</Errors>}
            <TwoFactorAuthenticationCode
              type="text"
              placeholder={t("authorization.2fa.input")}
              {...register("authCode", {
                value: authCode,
                onChange: (e) => dispatch(setAuthCode(e.target.value)),
              })}
              invalid={Boolean(errors.authCode)}
            />
          </InputContainer>
          <ToastContainer className="toast-container" />
          {isSubmitting ? (
            <ButtonContinue disabled={isSubmitting}>
              {t("authorization.signUp.sending")}
            </ButtonContinue>
          ) : (
            <ButtonContinue type="submit">{t("authorization.signUp.continue")}</ButtonContinue>
          )}
          {Number(counter) > 0 ? (
            <Counter>
              {t("authorization.2fa.resend")}
              <Resend>
                {counter} {t("authorization.2fa.seconds")}
              </Resend>
              {t("authorization.2fa.resendTurk")}
            </Counter>
          ) : (
            <Counter>
              {t("authorization.2fa.didntReceive")}
              <button onClick={resendAuthCode}>{t("authorization.2fa.resendCode")}</button>
            </Counter>
          )}
        </Form>
      </FormContainer>
    </MainWrapper>
  );
};

export default TwoFactorAuthentication;
