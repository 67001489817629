import styled, { css } from "styled-components";
import { down } from "styled-breakpoints";
import { GridProps } from "types/grid";

export default styled.div<GridProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${down("lg")} {
    flex-direction: column;
  }
  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify}};
    `}
`;
