import * as React from "react";
import styled from "styled-components";
import theme from "theme";
import BottomFooter from "./BottomFooter";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as EmailIcon } from "assets/icons/custom/email.svg";
import { ReactComponent as HouseIcon } from "assets/icons/custom/house.svg";
import { down } from "styled-breakpoints";
import { socialsItems } from "./footerItems";
import Container from "components/Grid/Container";
import Row from "components/Grid/Row";
import { socialIcons } from "./icons";
import { useTranslation } from "react-i18next";
import { LinkProps } from "types/link";

const FooterWrapper = styled.div`
  background: ${theme.colors.gray500};
  min-height: 500px;
  padding: 64px 0;
  width: 100%;
  color: ${theme.colors.primary800};
`;

const FooterRow = styled(Row)`
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  ${down("xs")} {
    text-align: center;
  }
`;

const AddressRow = styled.div`
  flex-direction: column;
  display: flex;

  ${down("lg")} {
    width: 100%;
    flex-direction: row;

    & > div {
      width: 50%;
    }
  }

  ${down("xs")} {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

const NavigationCategory = styled.div`
  ${down("lg")} {
    width: 50%;
  }

  ${down("xs")} {
    width: 100%;
  }
`;

const Heading = styled.h3`
  font-size: ${theme.fontSizes.large};
  color: ${theme.colors.primary800};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 50px;

  ${down("lg")} {
    margin-bottom: 0;
    margin-top: 32px;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

const Item = styled.li`
  color: ${theme.colors.primary800};
  text-decoration: none;
  transition: ${theme.transition};
  text-align: left;

  span {
    display: flex;
    align-items: center;

    ${IconWrapper} {
      margin-right: 10px;
    }
  }

  &:hover {
    text-decoration: underline;
  }
`;

const EmailWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  ${down("xs")} {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 8px;
    }
  }

  svg {
    margin-right: 5px;
  }

  span {
    font-weight: ${theme.fontWeights.bold};
  }
`;

const AddressWrapper = styled.div`
  margin-top: 25px;

  ${down("lg")} {
    margin-top: 0;
  }

  ${down("xs")} {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 8px;
    }
  }

  svg {
    margin-right: 5px;
  }

  span {
    font-weight: ${theme.fontWeights.bold};
  }
`;

const ItemsWrapper = styled.ul`
  list-style-type: none;

  li {
    margin-top: 15px;
    margin-right: 20px;
  }

  ${down("xs")} {
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      margin: 8px;
    }
  }
`;

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const legalItems: LinkProps[] = [
    {
      name: t("footer.whitePaper"),
      link: "https://lucrosus-production.s3.eu-central-1.amazonaws.com/store/WhitePaper.pdf",
    },
    {
      name: t("footer.terms"),
      link: "https://docs.lucrosus.capital/legal/terms-of-use",
    },
    {
      name: t("footer.aml"),
      link: "https://docs.lucrosus.capital/legal/aml-and-kyc-policy",
    },
    {
      name: t("footer.dataProtection"),
      link: "https://docs.lucrosus.capital/legal/data-protection-policy",
    },
    {
      name: t("footer.docs"),
      link: "https://docs.lucrosus.capital/",
    },
    {
      name: t("footer.faq"),
      link: "https://docs.lucrosus.capital/faq",
    },
    {
      name: t("footer.brands"),
      link: "https://drive.google.com/file/d/1rEMGcViLLENoL6CYXr0GZym-TDkHvK4N/view?usp=sharing",
    },
  ];

  return (
    <>
      <FooterWrapper>
        <Container>
          <FooterRow>
            <AddressRow>
              <div>
                <Logo style={{ marginBottom: 50 }} />
                <EmailWrapper>
                  <EmailIcon /> <span>{t("footer.contact")}</span>
                </EmailWrapper>
                <a href="mailto:contact@lucrosus.capital">contact@lucrosus.capital</a>
              </div>
              <div>
                <AddressWrapper>
                  <HouseIcon /> <span>{t("footer.address")}</span>
                </AddressWrapper>
                <ItemsWrapper>
                  <li>Lucrosus Capital LPS</li>
                  <li>Griva Digeni 3, Office 202</li>
                  <li>6030 Larnaca Cyprus</li>
                </ItemsWrapper>
              </div>
            </AddressRow>

            <NavigationCategory>
              <Heading>{t("footer.docs")}</Heading>
              <ItemsWrapper>
                {legalItems.map(({ link, name }) => (
                  <Item key={name}>
                    <a href={link} target="_blank" rel="noreferrer">
                      {name}
                    </a>
                  </Item>
                ))}
              </ItemsWrapper>
            </NavigationCategory>

            <NavigationCategory>
              <Heading>{t("footer.community")}</Heading>
              <ItemsWrapper>
                {socialsItems.map(({ name, link, iconName }) => (
                  <Item key={name}>
                    <a href={link} target="_blank" rel="noreferrer">
                      <span>
                        <IconWrapper>
                          {socialIcons.map(({ name, element }) => {
                            if (name === iconName) {
                              return element;
                            }
                            return null;
                          })}
                        </IconWrapper>
                        {name}
                      </span>
                    </a>
                  </Item>
                ))}
              </ItemsWrapper>
            </NavigationCategory>
          </FooterRow>
        </Container>
      </FooterWrapper>
      <BottomFooter />
    </>
  );
};

export default Footer;
