import React from "react";
import Button from "components/Button";
import Dropdown from "components/dropdown";
import { Link } from "react-router-dom";
import { NavigationProps } from "types/navigation";
import { BuyButton, DesktopWrapper } from "./navigation.styles";
import NavigationList from "./NavigationList";
import LoginLink from "components/LoginLink";
import { HASH_ROUTE, ROUTE } from "types/enums";
import { Col, Row } from "components/Grid";
import ProductDropdown from "components/dropdown/ProductDropdown";
import { ReactComponent as VerticalLine } from "assets/icons/navigation/vertical-line.svg";
import { LanguageSelector } from "components/LanguageSelector";
import theme from "theme";
import { useTranslation } from "react-i18next";
import { LinkProps } from "types/link";
import { HashLink } from "react-router-hash-link";
import { scrollForPaymentGateway } from "utils/scrollForPaymentGateway";
import { useAppSelector } from "store/hooks";
import { selectBuySection } from "app/features/utils";

const DesktopNavigation: React.FunctionComponent<NavigationProps> = ({ isLogged, color }) => {
  const { t } = useTranslation();
  const isShowBuySection = useAppSelector(selectBuySection);

  const navigationItems: LinkProps[] = [
    { name: "Staking 36%", to: ROUTE.STAKING },
    { name: "NEWS", to: HASH_ROUTE.BUY_LUCA_REPORT },
    { name: "POOL", link: "https://lucrosuspool.io/", target: "_blank" },
    {
      name: t("navigation.legal"),
      link: "https://docs.lucrosus.capital/",
      target: "_blank",
    },
  ];

  return (
    <DesktopWrapper>
      <Row align="center">
        <Col align="flex-start">
          <div>
            <ProductDropdown color={color} />
            <NavigationList items={navigationItems} color={color} />
          </div>
        </Col>
        <Col align="flex-end">
          {isLogged ? (
            <div
              style={{
                display: "flex",
                gap: 20,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <HashLink
                to={isShowBuySection ? HASH_ROUTE.BUY_LUCA : HASH_ROUTE.BUY_EXTERNAL_MARKETS}
                scroll={(el) => scrollForPaymentGateway(el, isShowBuySection)}
              >
                <BuyButton>{t("navigation.CTA").toUpperCase()}</BuyButton>
              </HashLink>
              <Dropdown />
              <VerticalLine />
              <LanguageSelector color={color} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LoginLink color={color} />
              <Link to={ROUTE.SIGN_UP}>
                <Button>{t("navigation.signUp").toUpperCase()}</Button>
              </Link>
              <HashLink
                to={isShowBuySection ? HASH_ROUTE.BUY_LUCA : HASH_ROUTE.BUY_EXTERNAL_MARKETS}
                scroll={(el) => scrollForPaymentGateway(el, isShowBuySection)}
              >
                <BuyButton>{t("navigation.CTA").toUpperCase()}</BuyButton>
              </HashLink>
              <VerticalLine
                stroke={color === "dark" ? theme.colors.white : theme.colors.primary800}
                style={{ margin: "0 20px" }}
              />
              <LanguageSelector color={color} />
            </div>
          )}
        </Col>
      </Row>
    </DesktopWrapper>
  );
};

export default DesktopNavigation;
