import { Container, Row } from "components/Grid";
import { FC } from "react";
import {
  Footer,
  IconWrapper,
  LogoWrapper,
  MaintenanceStyles,
  MiddleWrapper,
  StyledDescription,
  StyledHeader,
} from "./Maintenance.styles";
import { ReactComponent as MaintenanceSVG } from "assets/icons/maintenace/maintenance.svg";
import { ReactComponent as LucrosusLogo } from "assets/logo.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/socials/twitter.svg";
import { ReactComponent as MediumIcon } from "assets/icons/socials/medium.svg";
import { ReactComponent as LinkedinIcon } from "assets/icons/socials/linkedin.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/socials/telegramChat.svg";

const MaintenancePage: FC = () => {
  return (
    <MaintenanceStyles>
      <Container>
        <LogoWrapper>
          <LucrosusLogo />
        </LogoWrapper>
        <MiddleWrapper>
          <Row align="center" justify="center" style={{ marginTop: 50 }}>
            <MaintenanceSVG />
          </Row>
          <Row align="center" justify="center">
            <StyledHeader fontWeight="bold" mb="0">
              Under maintenance
            </StyledHeader>
          </Row>
          <Row align="center" justify="center">
            <StyledDescription mb="4">
              Our website is currently under scheduled maintenance, will be right back in a few
              minutes.
              <br />
              Thank you for your patience!
            </StyledDescription>
          </Row>
        </MiddleWrapper>
        <Footer>
          <IconWrapper>
            <a href="https://twitter.com/LucrosusCapital" target="_blank" rel="noreferrer">
              <TwitterIcon />
            </a>
          </IconWrapper>
          <IconWrapper>
            <a href="https://lucrosuscapital.medium.com/" target="_blank" rel="noreferrer">
              <MediumIcon />
            </a>
          </IconWrapper>
          <IconWrapper>
            <a
              href="https://www.linkedin.com/company/lucrosus-capital/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon />
            </a>
          </IconWrapper>
          <IconWrapper>
            <a href="https://t.me/lucrosus_capital" target="_blank" rel="noreferrer">
              <TelegramIcon />
            </a>
          </IconWrapper>
        </Footer>
      </Container>
    </MaintenanceStyles>
  );
};

export default MaintenancePage;
