import theme from "theme";
import styled from "styled-components";
import Description from "components/Description";
import Header from "components/Header";
import { down } from "styled-breakpoints";

export const MaintenanceStyles = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const IconWrapper = styled.span`
  margin: 0 5px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100px;
  width: 100%;
  top: 0;
  left: 0;
  svg {
    height: 50%;
  }
`;

export const MiddleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${down("sm")} {
    svg {
      max-width: 300px;
    }
  }
`;

export const Footer = styled.footer`
  height: 80px;
  background-color: ${theme.colors.gray500};
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDescription = styled(Description)`
  font-size: ${theme.fontSizes.regular};
  ${down("lg")} {
    font-size: ${theme.fontSizes.small};
  }
`;

export const StyledHeader = styled(Header)`
  font-size: ${theme.fontSizes.large};
  ${down("lg")} {
    font-size: ${theme.fontSizes.medium};
  }
`;
