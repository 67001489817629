export enum ROUTE {
  HOME = "/",
  REFERRAL_PROGRAM = "/referral-program",
  WALLET = "/wallet",
  SETTINGS = "/settings",
  SIGN_UP = "/sign-up",
  LOG_IN = "/log-in",
  RESET_PASSWORD = "/reset-password",
  TWO_FACTOR_AUTH = "/two-factor-auth",
  FORGOT_PASSWORD = "/forgot-password",
  ACTIVATION = "/activation",
  TRANSACTION_FAILED = "/transaction-failed",
  TRANSACTION_SUCCESS = "/transaction-success",
  TRANSACTION_CANCEL = "/transaction-cancel",
  MAINTENANCE = "/maintenance",
  STAKING = "/staking",
  ERROR = "*",
}

export enum HASH_ROUTE {
  BUY_LUCA = "/#buy-luca",
  LUCROSUS_POOL = "/#lucrosus-pool",
  BUY_EXTERNAL_MARKETS = "/#external_markets",
  TOKEN = "/#token",
  OUR_TEAM = "/#our-team",
  PAYMENTS = "/#to-payments",
  REFERRALS_LEADERBOARD = "/#referrals-leaderboard",
  BUY_LUCA_REPORT = "/#buy-luca-report",
}
