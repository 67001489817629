import { palette } from "./palette";

export const colors = {
  white: palette.white,
  black: palette.black,
  primary: palette.green.green300,
  primary100: palette.primary.primary100,
  primary200: palette.primary.primary200,
  primary300: palette.primary.primary300,
  primary400: palette.primary.primary400,
  primary500: palette.primary.primary500,
  primary600: palette.primary.primary600,
  primary700: palette.primary.primary700,
  primary800: palette.primary.primary800,
  primary900: palette.primary.primary900,
  primaryYellow: palette.yellow,
  secondary: palette.green.green500,
  placeholder: palette.gray.gray600,
  error: palette.error,
  gradient: palette.gradient,
  shadow: palette.green.shadow,
  success: palette.success,
  info: palette.info,
  alert: palette.alert,
  warning: palette.error,
  green100: palette.green.green100,
  green200: palette.green.green200,
  green250: palette.green.green250,
  green300: palette.green.green300,
  green400: palette.green.green400,
  green500: palette.green.green500,
  gray500: palette.gray.gray500,
  gray600: palette.gray.gray600,
  gray200: palette.gray.gray200,
  creamy: palette.creamy,
  lightGray: palette.gray.gray200,
  mediumGray: palette.gray.gray300,
  transparent: "transparent",
  neutral: palette.neutral.neutral700,
  neutral200: palette.neutral.neutral200,
  neutral300: palette.neutral.neutral300,
  neutral400: palette.neutral.neutral400,
  neutral500: palette.neutral.neutral500,
  neutral600: palette.neutral.neutral600,
  neutral700: palette.neutral.neutral700,
  neutral800: palette.neutral.neutral800,
  secondary100: palette.secondary.secondary100,
  secondary200: palette.secondary.secondary200,
  secondary300: palette.secondary.secondary300,
  secondary400: palette.secondary.secondary400,
  secondary500: palette.secondary.secondary500,
  secondary600: palette.secondary.secondary600,
  secondary700: palette.secondary.secondary700,
  secondary800: palette.secondary.secondary800,
  secondary900: palette.secondary.secondary900,
};

export type SingleColors =
  | "white"
  | "black"
  | "primary"
  | "secondary"
  | "placeholder"
  | "error"
  | "gradient"
  | "info"
  | "alert"
  | "warning"
  | "creamy"
  | "transparent";

export const typography = {
  fonts: {
    body: "Roboto, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  fontSizes: ["12px", "14px", "16px", "24px", "32px", "40px", "18px", "48px"],
  fontWeights: [300, 400, 700, 500, 600],
  lineHeight: ["21px", "24px", "32px", "48px", "51.2px"],
  title: ["capitalize", "uppercase"],
  titleCase: "capitalize",
  headerFontSizes: ["16px", "18px", "20px", "24px", "32px", "40px"],
  mobileHeaderFontSizes: ["14px", "16px", "18px", "24px", "28px", "32px"],
};

export const transition = "all 0.4s ease";

export const iconSize = {
  sm: "0.875rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "3rem",
};

export const boxShadow = `0px 4px 12px 0px ${colors.shadow}`;
export const darkBoxShadow = `0px 1px 12px 0px ${colors.primary800}`;

export const borderRadius = {
  sm: "0.25rem",
  md: "0.5rem",
  lg: "1rem",
  xl: "2rem",
};

export const border = "1px solid #e0e0e0";
