import * as React from "react";
import styled, { css } from "styled-components";
import { down } from "styled-breakpoints";
import Button from "components/Button/Button";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import Header from "components/Header/Header";
import theme from "theme";
import Container from "components/Grid/Container";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import Box from "components/Box";
import { useTranslation, Trans } from "react-i18next";

const InfoWidgetStyles = styled.div`
  background: white;
  padding: 80px 0 56px 0;

  ${down("lg")} {
    padding-top: 48px;
    padding-bottom: 24px;
  }

  & iframe {
    max-width: 524px;
  }
`;

const InfoWidgetHeader = styled(Header)`
  ${down("lg")} {
    margin-top: 24px;
  }
`;

const LeftCol = styled(Col)`
  justify-content: center;
`;

const RightCol = styled(Col)`
  padding-left: 24px;

  ${down("lg")} {
    padding-left: 0;
  }
`;

const Description = styled.div<{ big?: boolean }>`
  line-height: 28px;
  font-size: ${theme.fontSizes.regular};
  font-weight: ${theme.fontWeights.regular};
  max-width: 485px;
  color: ${theme.colors.primary800};
  margin-bottom: 44px;
  opacity: 0.75;

  ${down("lg")} {
    max-width: 800px;
    text-align: center;
    margin-bottom: 24px;
  }

  div:first-of-type {
    margin-bottom: 20px;
  }

  ${({ big }) =>
    big &&
    css`
      font-size: ${theme.fontSizes.medium};
      margin-top: 20px;
      text-align: center;
      line-height: 24px;
      max-width: 560px;
    `}
`;

const ButtonWrapper = styled(Box)`
  flex-direction: row;
  justify-content: flex-start;

  ${down("lg")} {
    justify-content: center;
  }

  ${down("md")} {
    flex-direction: column;

    Button {
      margin-right: 0;
      margin-bottom: 10px;
      text-transform: initial;
    }
  }

  a {
    text-decoration: none;

    :hover {
      text-decoration: none;
    }

    :first-of-type {
      margin-right: 20px;
    }
  }
`;

const InfoWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <InfoWidgetStyles>
      <Container>
        <Row>
          <LeftCol>
            <iframe
              style={{ borderRadius: "8px", border: 0 }}
              width={useBreakpoint(down("lg")) ? "100%" : 524}
              height="295"
              src="https://www.youtube.com/embed/7IO9SbFUzg0?showinfo=0&rel=0"
              title="Lucrosus Capital - Explainer"
              allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </LeftCol>
          <RightCol>
            <InfoWidgetHeader level={2}>
              <Trans i18nKey="infoWidget.title" />
            </InfoWidgetHeader>
            <Description>
              <div>
                <Trans i18nKey="infoWidget.description1" />
              </div>
              <div>{t("infoWidget.description2")}</div>
            </Description>
            <ButtonWrapper>
              <a
                href="https://lucrosus-production.s3.eu-central-1.amazonaws.com/store/WhitePaper.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Button fullWidth icon outlined>
                  {t("infoWidget.buttons.whitepaper")} <ArrowRightIcon />
                </Button>
              </a>

              <a href="https://docs.lucrosus.capital/" target="_blank" rel="noreferrer">
                <Button fullWidth icon outlined>
                  {t("infoWidget.buttons.docs")} <ArrowRightIcon stroke={theme.colors.primary} />
                </Button>
              </a>
            </ButtonWrapper>
          </RightCol>
        </Row>
      </Container>
    </InfoWidgetStyles>
  );
};

export default InfoWidget;
