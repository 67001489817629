import * as React from "react";
import Header from "components/Header/Header";
import styled from "styled-components";
import theme from "theme";
import { down, up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { ReactComponent as ArrowRight } from "assets/icons/slider/arrow-right-2.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/slider/arrow-left-2.svg";
import PhotoContainer from "components/PhotoContainer";
import { PhotoContainerData } from "types/photoContainer";
import Button from "components/Button/Button";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Container from "components/Grid/Container";
import Description from "components/Description";
import { getTeamMembers } from "app/api/user";
import { s3bucket } from "utils/magicVariables";
import { useTranslation, Trans } from "react-i18next";

const OurTeamStyles = styled.div`
  background: ${theme.colors.gray500};
  padding: 50px 0;
  min-height: 600px;
`;

const PrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: -50px;
  border: none;
  background-color: ${theme.colors.transparent};
  cursor: pointer;
`;
const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: -50px;
  border: none;
  background-color: ${theme.colors.transparent};
  cursor: pointer;
`;

const MeetTeamButton = styled(Button)`
  margin-top: 36px;
  min-width: 350px;
  ${down("xs")} {
    min-width: auto;
    width: 100%;
  }
`;

const OurTeam: React.FunctionComponent = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [teamMembers, setTeamMembers] = React.useState<PhotoContainerData[]>([]);

  const slidePrev = () => {
    return activeIndex === 0
      ? setActiveIndex(teamMembers.length - 1)
      : setActiveIndex(activeIndex - 1);
  };
  const slideNext = () => {
    return activeIndex === teamMembers.length - 1
      ? setActiveIndex(0)
      : setActiveIndex(activeIndex + 1);
  };

  const syncActiveIndex = ({ item }: { item: number }) => setActiveIndex(item);

  const { t } = useTranslation();

  React.useEffect(() => {
    getTeamMembers().then(({ data }) => {
      setTeamMembers(
        data.sort(
          (firstMember: PhotoContainerData, secondMember: PhotoContainerData) =>
            firstMember.id - secondMember.id
        )
      );
    });
  }, []);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const items = teamMembers.map(
    ({ id, name, job_title: title, image_src: image, description, url }) => {
      return (
        <PhotoContainer
          data-value={id - 1}
          name={name}
          title={title}
          image={image}
          description={description}
          url={url}
          key={id}
        />
      );
    }
  );

  return (
    <OurTeamStyles id="our-team">
      <Container>
        <Header level={2} align="center">
          <Trans i18nKey="ourTeam.title" />
        </Header>
        <Description mb={4}>{t("ourTeam.description")}</Description>
        <div style={{ position: "relative" }}>
          {useBreakpoint(up("xl")) && (
            <PrevButton>
              <ArrowLeft onClick={slidePrev} />
            </PrevButton>
          )}
          <AliceCarousel
            disableDotsControls
            disableButtonsControls
            activeIndex={activeIndex}
            infinite
            responsive={responsive}
            items={items}
            onSlideChanged={syncActiveIndex}
            mouseTracking
            touchTracking
            autoPlay
            autoPlayInterval={5000}
          />
          {useBreakpoint(up("xl")) && (
            <NextButton>
              <ArrowRight onClick={slideNext} />
            </NextButton>
          )}
        </div>
        <div style={{ textAlign: "center", userSelect: "none" }}>
          <a href={s3bucket + `store/WhitePaper.pdf#page=33`} target="_blank" rel="noreferrer">
            <MeetTeamButton className="text-bold">{t("ourTeam.CTA")}</MeetTeamButton>
          </a>
        </div>
      </Container>
    </OurTeamStyles>
  );
};

export default OurTeam;
