import styled, { css } from "styled-components";
import theme from "theme";
import { typography, space, SpaceProps, TypographyProps } from "styled-system";
import { down } from "styled-breakpoints";
import React from "react";

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

type HeaderProps = {
  children: React.ReactNode;
  level?: HeadingLevel;
  align?: string;
  color?: string;
  marginBottom?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
};

const changeAttrs = (level: HeadingLevel) => {
  switch (level) {
    case 6:
      return theme.headerFontSizes.h6;
    case 5:
      return theme.headerFontSizes.h5;
    case 4:
      return theme.headerFontSizes.h4;
    case 3:
      return theme.headerFontSizes.h3;
    case 2:
      return theme.headerFontSizes.h2;
    case 1:
      return theme.headerFontSizes.h1;
    default:
      return theme.headerFontSizes.h1;
  }
};

const changeMobileAttrs = (level: HeadingLevel) => {
  switch (level) {
    case 6:
      return theme.mobileHeaderFontSizes.h6;
    case 5:
      return theme.mobileHeaderFontSizes.h5;
    case 4:
      return theme.mobileHeaderFontSizes.h4;
    case 3:
      return theme.mobileHeaderFontSizes.h3;
    case 2:
      return theme.mobileHeaderFontSizes.h2;
    case 1:
      return theme.mobileHeaderFontSizes.h1;
  }
};

const StyledHeader = styled.h1<HeaderProps>`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.large};
  line-height: 150%;
  padding: 15px 0;
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.primary800};

  span.text-bold {
    font-weight: ${theme.fontWeights.bold};
  }

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ level }) =>
    level &&
    css`
      font-size: ${changeAttrs(level)};
      ${down("md")} {
        font-size: ${changeMobileAttrs(level)};
      }
    `}

    ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom || "16px"};
    `}

  ${typography};
  ${space};
`;

const Header = ({ children, level, ...props }: HeaderProps & SpaceProps & TypographyProps) => {
  return (
    <StyledHeader
      as={level ? (`h${level}` as keyof JSX.IntrinsicElements) : "h1"}
      level={level || 1}
      {...props}
    >
      {children}
    </StyledHeader>
  );
};

export default Header;
