import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import theme from "theme";
import ToggleSwitch from "components/ToggleSwitch";
import { getUserInfo, updateUserInfo } from "app/api/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setUserInfo, userAuthToken } from "app/features/auth";
import { useTranslation } from "react-i18next";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Title = styled.h2`
  margin-top: 62px;
  width: 100%;
  text-align: left;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.xLarge};
  line-height: ${theme.lineHeightXXLarge};
  margin-bottom: 10px;
  color: ${theme.colors.primary800};
  cursor: default;
`;

export const Info = styled.p`
  max-width: 486px;
  line-height: ${theme.lineHeightMedium};
  text-align: left;
  color: ${theme.colors.primary800};
  opacity: 0.75;
  font-weight: ${theme.fontWeights.regular};
  margin-bottom: 18px;
  cursor: default;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 486px;
  width: 100%;
  justify-content: space-between;
`;

export const Label = styled.div`
  font-size: ${theme.fontSizes.large};
  line-height: 150%;
`;

const ChangeSubscription: FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const authToken = useAppSelector(userAuthToken);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setIsChecked(value);
    try {
      const userUpdateForm = new FormData();
      userUpdateForm.append("marketing_terms_accepted", `${value}`);
      const response = await updateUserInfo(userUpdateForm, `${authToken}`);
      if (response.status === 201) {
        dispatch(setUserInfo(response.data));
        return response;
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        return err;
      }
    }
  };

  const checkUserInfo = useCallback(() => {
    if (authToken) {
      getUserInfo(`${authToken}`).then(({ data }) => {
        dispatch(setUserInfo(data));
        setIsChecked(data.marketing_terms_accepted || false);
      });
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    checkUserInfo();
  }, [checkUserInfo]);

  return (
    <MainWrapper>
      <Title>{t("authorization.settings.newsletter")}</Title>
      <Info>{t("authorization.settings.description")}</Info>

      <InputWrapper>
        <Label>{t("authorization.settings.subscribe")}</Label>
        <ToggleSwitch handleChange={handleChange} isChecked={isChecked} />
      </InputWrapper>
    </MainWrapper>
  );
};

export default ChangeSubscription;
