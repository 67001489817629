import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import React from "react";
import styled from "styled-components";
import { DefaultButton } from "../Auth/authStyles";
import { useTranslation } from "react-i18next";

export const ButtonContinue = styled.button`
  ${DefaultButton};
  @media (max-height: 740px) and (max-width: 440px) {
    margin-top: 40px;
  }
  @media (max-height: 670px) and (max-width: 440px) {
    margin-top: 25px;
  }
  @media (min-height: 812px) and (max-width: 440px) {
    margin-top: 80px;
  }
`;

const CaptchaButton = ({ onVerifyCaptcha }: any) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("submit");

    onVerifyCaptcha(token);
  };

  return (
    <ButtonContinue onClick={clickHandler}>{t("authorization.signUp.continue")}</ButtonContinue>
  );
};

export const ReCaptcha = ({ onVerifyCaptcha }: any) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}>
    <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
  </GoogleReCaptchaProvider>
);
