import { Container } from "react-awesome-styled-grid";
import ReactModal from "react-modal";
import { ModalInstanceProps } from "types/modalTypes";
import styled from "styled-components";
import { FC } from "react";
import { ROUTE } from "types/enums";
import Button from "components/Button";
import Divider from "components/Divider";
import Description from "components/Description";
import theme from "theme";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { sessionLogoutModal, setLogoutModal } from "app/features/auth";

const customStyles = {
  content: {
    width: "350px",
    height: "200px",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    boxShadow: theme.boxShadow,
    border: `1px solid ${theme.colors.gray600}`,
  },
  overlay: {
    zIndex: 100,
  },
};

const Wrapper = styled(Container)`
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  Button {
    width: 120px;
  }
`;

const LogoutModal: FC<ModalInstanceProps> = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(sessionLogoutModal);

  return (
    <ReactModal style={customStyles} ariaHideApp={false} isOpen={showModal}>
      <Wrapper>
        <Description align="center">
          Your session has expired. <br />
          Please log in again…
        </Description>
        <Divider marginTop="30px" />
        <ButtonsWrapper>
          <Link to={ROUTE.LOG_IN}>
            <Button onClick={() => dispatch(setLogoutModal(false))}>LOG IN</Button>
          </Link>
          <Button outlined onClick={() => dispatch(setLogoutModal(false))}>
            CLOSE
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </ReactModal>
  );
};

export default LogoutModal;
