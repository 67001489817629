import { ReactComponent as Logo } from "assets/white_logo.svg";
import { ReactComponent as Desk } from "assets/images/luca.svg";
import React, { FC } from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import theme from "theme";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "types/enums";
import { useTranslation } from "react-i18next";

const SideSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  width: 48vw;
  background: ${theme.colors.gradient};

  ${down("lg")} {
    display: none;
  }
`;
const UpperWrapper = styled.div`
  height: 50vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 120px 0 0 80px;
  @media (max-width: 1440px) and (max-height: 1000px) {
    padding: 40px 0 0 80px;
  }
`;
const LogoWrapper = styled.a`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
`;
const WelcomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
`;
const Welcome = styled.h1`
  width: 350px;
  line-height: ${theme.lineHeightMedium};
  font-size: ${theme.fontSizes.large};
  text-align: left;
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.regular};
  text-transform: ${theme.title.uppercase};
  cursor: default;
`;
const LucrosusCapital = styled.h1`
  width: 337px;
  line-height: ${theme.lineHeightMedium};
  font-size: ${theme.fontSizes.xLarge};
  font-weight: ${theme.fontWeights.bold};
  text-align: left;
  color: ${theme.colors.white};
  margin: 8px 0 8px 0;
  text-transform: ${theme.title.uppercase};
  cursor: default;
`;
const SideInfo = styled.p`
  width: 337px;
  font-weight: ${theme.fontWeights.light};
  line-height: ${theme.lineHeightSmall};
  font-size: ${theme.fontSizes.small};
  text-align: left;
  color: ${theme.colors.white};
  cursor: default;
`;
const DeskSvgContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
  overflow: hidden;
  @media (max-height: 600px) {
    display: none;
  }
`;
const StyledSvg = styled(Desk)`
  height: 100%;
  width: 100%;
`;

const SideComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigateToMainPage = () => navigate(ROUTE.HOME);

  return (
    <SideSection>
      <UpperWrapper>
        <LogoWrapper onClick={handleNavigateToMainPage}>
          <Logo />
        </LogoWrapper>
        <WelcomeWrapper>
          <Welcome>{t("authorization.welcome")}</Welcome>
          <LucrosusCapital>{t("authorization.company")}</LucrosusCapital>
          <SideInfo>{t("authorization.description")}</SideInfo>
        </WelcomeWrapper>
      </UpperWrapper>
      <DeskSvgContainer>
        <StyledSvg />
      </DeskSvgContainer>
    </SideSection>
  );
};

export default SideComponent;
