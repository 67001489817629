import axios from "axios";
import { baseURL } from "./user";

const paymentUrl = baseURL + "payments/";

export const initializeCoinbaseTransaction = async (data: FormData, authToken: string) => {
  return await axios.post(paymentUrl + "coinbase_payment", data, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const initializeNowPaymentTransaction = async (data: FormData, authToken: string) => {
  return await axios.post(paymentUrl + "now_payments_payment", data, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};
export const getTransactionsList = async (authToken: string) => {
  return await axios.get(paymentUrl + "list", {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const validateAdvCashTransaction = async (data: FormData, authToken: string) => {
  return await axios.post(paymentUrl + "adv_cash_payment", data, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};
