import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/hooks";

const stakingSlice = createSlice({
  name: "staking",
  initialState: {
    stepFormActive: false,
    depositBox: null,
    stepError: "",
    stepErrorInput: "",
    stakeValue: 0,
    checkbox: false,
    showDepositForm: false,
    depositList: [],
  } as {
    stepFormActive: boolean;
    depositBox: null | number;
    stepError: null | string;
    stepErrorInput: null | string;
    stakeValue: null | number;
    checkbox: boolean;
    showDepositForm: boolean;
    depositList: any;
  },
  reducers: {
    setStepFormActive: (state, action: PayloadAction<boolean>) => {
      state.stepFormActive = action.payload;
    },
    setDepositBox: (state, action: PayloadAction<number>) => {
      state.depositBox = action.payload;
    },
    setStepError: (state, action: PayloadAction<string>) => {
      state.stepError = action.payload;
    },
    setStepErrorInput: (state, action: PayloadAction<string>) => {
      state.stepErrorInput = action.payload;
    },
    setStakeValue: (state, action: PayloadAction<number>) => {
      state.stakeValue = action.payload;
    },
    setChecked: (state, action: PayloadAction<boolean>) => {
      state.checkbox = action.payload;
    },
    setShowDepositForm: (state, action: PayloadAction<boolean>) => {
      state.showDepositForm = action.payload;
    },
    setDepositList: (state, action: PayloadAction<any>) => {
      state.depositList = action.payload;
    },
  },
});

export default stakingSlice.reducer;
export const {
  setStepFormActive,
  setDepositBox,
  setStepError,
  setStepErrorInput,
  setStakeValue,
  setChecked,
  setShowDepositForm,
  setDepositList,
} = stakingSlice.actions;

export const isStepFormActive = (state: RootState) => state.staking.stepFormActive;
export const selectedDepositBox = (state: RootState) => state.staking.depositBox;
export const stepError = (state: RootState) => state.staking.stepError;
export const stepErrorInput = (state: RootState) => state.staking.stepErrorInput;
export const stakeValue = (state: RootState) => state.staking.stakeValue;
export const checkbox = (state: RootState) => state.staking.checkbox;
export const showDepositForm = (state: RootState) => state.staking.showDepositForm;
export const depositList = (state: RootState) => state.staking.depositList;
