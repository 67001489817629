import { down } from "styled-breakpoints";
import styled from "styled-components";

const Box = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  ${down("md")} {
    display: block;
    width: 100%;
  }
`;

export default Box;
