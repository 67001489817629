import * as React from "react";

export const icons = {
  arrowDown: (
    <>
      <path
        d="M16.5 1.87402L9.35333 9.02002C9.25967 9.11382 9.13255 9.16653 9 9.16653C8.86745 9.16653 8.74033 9.11382 8.64667 9.02002L1.5 1.87402"
        stroke="#137D6A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  capital: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.998 23.3229H9.99799L10.748 19.0029H15.248L15.998 23.3229V23.3229Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.74799 23.3223H18.248"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74799 4.72265C1.74799 3.0658 3.09113 1.72266 4.74799 1.72266H21.248C22.9048 1.72266 24.248 3.0658 24.248 4.72266V16.0027C24.248 17.6595 22.9048 19.0027 21.248 19.0027H4.74799C3.09113 19.0027 1.74799 17.6595 1.74799 16.0027V4.72265Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.74799 11.803L5.71799 7.99273C5.85865 7.8576 6.04949 7.78168 6.24849 7.78168C6.44748 7.78168 6.63832 7.8576 6.77899 7.99273L8.71799 9.85417C8.85865 9.98929 9.04949 10.0652 9.24849 10.0652C9.44748 10.0652 9.63832 9.98929 9.77899 9.85417L13.748 6.04297"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.74799 16.123H24.248"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.248 10.3623V12.5223"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.248 10.3623V12.5223"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.248 8.20312V12.5231"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  referralCode: (
    <>
      <path
        d="M8.25 20.25L9 15.75"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 20.25H9"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 12.75H15.133"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.75H3.75C2.09315 15.75 0.75 14.4069 0.75 12.75V3.75C0.75 2.09315 2.09315 0.75 3.75 0.75H20.25C21.9069 0.75 23.25 2.09315 23.25 3.75V8.25"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 20.25C14.7426 20.25 15.75 19.2426 15.75 18C15.75 16.7574 14.7426 15.75 13.5 15.75C12.2574 15.75 11.25 16.7574 11.25 18C11.25 19.2426 12.2574 20.25 13.5 20.25Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 23.25C22.2426 23.25 23.25 22.2426 23.25 21C23.25 19.7574 22.2426 18.75 21 18.75C19.7574 18.75 18.75 19.7574 18.75 21C18.75 22.2426 19.7574 23.25 21 23.25Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 15.75C22.2426 15.75 23.25 14.7426 23.25 13.5C23.25 12.2574 22.2426 11.25 21 11.25C19.7574 11.25 18.75 12.2574 18.75 13.5C18.75 14.7426 19.7574 15.75 21 15.75Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.425 16.8453L19.075 14.6553"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.588 18.835L18.912 20.165"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  settings: (
    <>
      <path
        d="M8.25 20.25L9 15.75"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 20.25H8.25"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 12.75H8.383"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.75H3.75C2.09315 15.75 0.75 14.4069 0.75 12.75V3.75C0.75 2.09315 2.09315 0.75 3.75 0.75H20.25C21.9069 0.75 23.25 2.09315 23.25 3.75V9"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 18C18.0784 18 18.75 17.3284 18.75 16.5C18.75 15.6716 18.0784 15 17.25 15C16.4216 15 15.75 15.6716 15.75 16.5C15.75 17.3284 16.4216 18 17.25 18Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5243 10.6999L18.9663 12.1529C19.1176 12.6533 19.6308 12.951 20.1403 12.8339L21.6123 12.4929C22.185 12.3639 22.775 12.6229 23.0675 13.1318C23.3601 13.6408 23.287 14.281 22.8873 14.7109L21.8563 15.8219C21.4993 16.2058 21.4993 16.8001 21.8563 17.1839L22.8873 18.2949C23.287 18.7249 23.3601 19.365 23.0675 19.874C22.775 20.383 22.185 20.642 21.6123 20.5129L20.1403 20.1719C19.6308 20.0549 19.1176 20.3525 18.9663 20.8529L18.5243 22.2999C18.3556 22.8626 17.8377 23.2481 17.2503 23.2481C16.6628 23.2481 16.145 22.8626 15.9763 22.2999L15.5343 20.8469C15.3829 20.3465 14.8698 20.0489 14.3603 20.1659L12.8883 20.5069C12.3156 20.636 11.7256 20.377 11.433 19.868C11.1405 19.359 11.2135 18.7189 11.6133 18.2889L12.6443 17.1779C13.0012 16.7941 13.0012 16.1998 12.6443 15.8159L11.6133 14.7049C11.2135 14.275 11.1405 13.6348 11.433 13.1258C11.7256 12.6169 12.3156 12.3579 12.8883 12.4869L14.3603 12.8279C14.8698 12.945 15.3829 12.6473 15.5343 12.1469L15.9763 10.6939C16.1463 10.1316 16.6651 9.7474 17.2525 9.74878C17.84 9.75017 18.3569 10.1368 18.5243 10.6999V10.6999Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  logout: (
    <>
      <path
        d="M20.25 18.7539V20.2539C20.25 21.0823 19.5784 21.7539 18.75 21.7539H14.25"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 3.75391H18.75C19.5784 3.75391 20.25 4.42548 20.25 5.25391V6.75391"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.394 23.2456L1.394 21.9596C1.02444 21.9072 0.749803 21.5908 0.75 21.2176V3.72455C0.749709 3.37061 0.996901 3.06464 1.343 2.99055L10.343 0.769553C10.5646 0.722123 10.7957 0.777395 10.9718 0.919928C11.1479 1.06246 11.2502 1.27698 11.25 1.50355V22.5036C11.2499 22.7209 11.1555 22.9275 10.9912 23.0698C10.827 23.2122 10.6091 23.2763 10.394 23.2456Z"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 9.75293L15 12.7529H23.25"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 15.7529L15 12.7529"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87499 11.629C7.72295 11.629 7.58597 11.7208 7.52817 11.8614C7.47037 12.0021 7.50319 12.1637 7.61127 12.2706C7.71935 12.3775 7.8813 12.4086 8.02129 12.3493C8.16128 12.29 8.25161 12.152 8.24999 12C8.25026 11.9005 8.21083 11.8049 8.14045 11.7345C8.07007 11.6642 7.97453 11.6247 7.87499 11.625"
        stroke="#F7B668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  burgerMenu: (
    <>
      <path
        d="M1.25 13.0029H20.75"
        stroke="#052C27"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 7.00293H20.75"
        stroke="#052C27"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 1.00293H20.75"
        stroke="#052C27"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  arrowTop: (
    <>
      <path
        d="M12 23.25L12 0.75"
        stroke="#137D6A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 11.25L12 0.75L1.5 11.25"
        stroke="#137D6A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
};
