export const palette = {
  white: "#FFFFFF",
  black: "#000000",
  yellow: "#F7B668",
  green: {
    green100: "#24B5B4",
    green200: "#4FB8A1",
    green250: "#27AE60",
    green300: "#137D6A",
    green400: "#125649",
    green500: "#052C27",
    shadow: "rgba(19, 125, 106, 0.12)",
  },
  primary: {
    primary100: "#D5E8E4",
    primary200: "#AAD0C9",
    primary300: "#87BDB4",
    primary400: "#59A396",
    primary500: "#137D6A",
    primary600: "#106455",
    primary700: "#0C4A3F",
    primary800: "#083029",
    primary900: "#041411",
  },
  secondary: {
    secondary100: "#FDEEDB",
    secondary200: "#FCE0BE",
    secondary300: "#FAD4A6",
    secondary400: "#FACE99",
    secondary500: "#F7B96E",
    secondary600: "#D19A58",
    secondary700: "#BF8D51",
    secondary800: "#966F3F",
    secondary900: "#6D512E",
  },
  gray: {
    gray200: "#E0E0E0",
    gray300: "#778C8C",
    gray500: "#F8F8F8",
    gray600: "#F2F2F2",
  },
  gradient: "linear-gradient(155.64deg, #125649 0%, #4fb8a1 100%)",
  error: "#EB5757",
  success: "#6FCF97",
  info: "#56CCF2",
  alert: "#F2C94C",
  creamy: "#F9CB8F",
  neutral: {
    neutral200: "#F8F8F8",
    neutral300: "#EDF2F2",
    neutral400: "#CED8D9",
    neutral500: "#BECCCC",
    neutral600: "#A1B2B2",
    neutral700: "#778C8C",
    neutral800: "#364947",
  },
};
