import styled, { css } from "styled-components";
import theme from "theme";
import { space, SpaceProps } from "styled-system";
import React from "react";

type TextAlign = "left" | "center" | "right" | "justify";

interface DescriptionProps {
  align?: TextAlign;
  children: React.ReactNode;
}

const StyledDescription = styled.div<DescriptionProps>`
  line-height: 160%;
  margin: 0 auto;
  font-size: ${theme.fontSizes.regular};
  font-weight: ${theme.fontWeights.regular};
  text-align: center;
  color: ${theme.colors.primary800};
  opacity: 0.8;
  word-break: break-word;
  letter-spacing: 0.05em;

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${space};
`;

const Description = ({ children, align, ...props }: DescriptionProps & SpaceProps) => {
  return (
    <StyledDescription align={align || "center"} {...props}>
      {children}
    </StyledDescription>
  );
};

export default Description;
