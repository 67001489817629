export default function formatDollar(num: number) {
  const number = num.toFixed(2).split(".");
  return (
    number[0]
      .split("")
      .reverse()
      .reduce(function (acc, num, i) {
        return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
      }, "") +
    "." +
    number[1]
  );
}

export function numberWithSpaces(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
