import Cookies from "js-cookie";
import { ROUTE } from "types/enums";

export const handleSetCookie = (name: string, expires: Date) => {
  return Cookies.set(name, "true", {
    path: ROUTE.HOME,
    secure: true,
    sameSite: "strict",
    expires: expires,
  });
};
