import { down } from "styled-breakpoints";
import styled, { css } from "styled-components";
import theme from "theme";
import { ButtonProps } from "types/button";

const disabledStyles = `
  cursor: not-allowed;
  color: ${theme.colors.green200};
  background-color: ${theme.colors.primary};
`;

const ButtonStyles = styled.button<ButtonProps>`
  font-family: ${theme.fonts.body};
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  border: 0.1rem solid ${theme.colors.primary};
  padding: 5px 15px;
  font-weight: ${theme.fontWeights.regular};
  cursor: pointer;
  border-radius: 8px;
  min-height: 52px;
  height: auto;
  font-size: 1rem;
  transition: ${theme.transition};
  /* border: 1px solid ${theme.colors.white}; */
  text-decoration: none;
  width: auto;

  ${down("md")} {
    width: 100%;
    text-transform: uppercase;
  }

  &:hover {
    transition: ${theme.transition};
    background-color: ${theme.colors.green400};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${disabledStyles};
      &:hover {
        ${disabledStyles}
      }
    `};

  ${({ outlined }) =>
    outlined &&
    css`
      color: ${theme.colors.primary};
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.primary};
      &:hover {
        background-color: ${theme.colors.gray600};
      }
    `};

  ${({ icon }) =>
    icon &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 10px;
        stroke: ${theme.colors.primary};
      }
      &:hover {
        svg {
          stroke: ${theme.colors.green200};
        }
      }
    `};

  ${({ large }) =>
    large &&
    css`
      font-weight: ${theme.fontWeights.bold};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      width: 100%;
      ${down("md")} {
        width: 100%;
        height: 56px;
      }
    `};

  a {
    text-decoration: none;
    color: inherit;
    :hover {
      text-decoration: none;
    }
  }

  ${({ color }) =>
    color &&
    css`
      background: ${color};
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const Button = ({ children, ...props }: ButtonProps) => (
  <ButtonStyles {...props}>{children}</ButtonStyles>
);

export default Button;
