import styled from "styled-components";
import { down } from "styled-breakpoints";
import { FC, useState } from "react";
import theme from "theme";
import { ActiveTabProps } from "types/wallet";
import Container from "components/Grid/Container";
import ChangePasswordForm from "./ChangePassword";
import ChangeSubscription from "./ChangeSubscription";
import { useTranslation } from "react-i18next";

const SettingsWrapper = styled(Container)`
  .user-settings {
    margin: 64px 0 85px;
    width: 100%;

    &__title {
      width: 495px;
      height: 51px;
      font-size: ${theme.fontSizes.xxLarge};
      text-transform: ${theme.title.capitalize};
      cursor: default;

      ${down("lg")} {
        height: 72px;
        width: 243px;
        font-size: 28px;
      }
    }

    &__navigation {
      position: relative;
      margin-top: 28px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      ${down("lg")} {
        margin-top: 40px;
      }
    }

    &__tabs {
      display: flex;

      ${down("lg")} {
        width: 100vw;
        margin-left: -20px;
        border-bottom: 2px solid ${theme.colors.gray600};
      }
    }
  }
`;

const Tab = styled.li<ActiveTabProps>`
  border-bottom: 2px solid ${theme.colors.gray600};
  color: ${theme.colors.primary800};
  font-weight: ${(props) =>
    props.active ? `${theme.fontWeights.medium}` : `${theme.fontWeights.regular}`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 183px;
  height: 64px;
  list-style: none;
  text-align: left;
  cursor: pointer;
  border-radius: 12px 12px 0 0;
  border-bottom: ${(props) => (props.active ? `2px solid ${theme.colors.green300}` : "")};
  background-color: ${(props) => (props.active ? `${theme.colors.gray500}` : "")};
  opacity: 0.8;

  ${down("lg")} {
    border-bottom: none;
    height: 21px;
    margin: 0 -22px -2px 20px;
    line-height: ${theme.lineHeightSmall};
    padding-bottom: 21px;
    width: 162px;
    font-size: ${theme.fontSizes.small};
    background-color: ${theme.colors.transparent};
    border-bottom: ${(props) => (props.active ? `2px solid ${theme.colors.green500}` : "")};
    transition: none;
  }
`;

const Settings: FC = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const { t } = useTranslation();

  return (
    <SettingsWrapper>
      <div className="user-settings">
        <h1 className="user-settings__title">{t("authorization.settings.settings")}</h1>
        <div className="user-settings__navigation">
          <ul className="user-settings__tabs">
            <Tab onClick={() => setActiveTab("tab1")} active={activeTab === "tab1"}>
              {t("authorization.settings.newsletter")}
            </Tab>
            <Tab onClick={() => setActiveTab("tab2")} active={activeTab === "tab2"}>
              {t("authorization.settings.changePassword")}
            </Tab>
          </ul>
        </div>
        {activeTab === "tab1" ? (
          <ChangeSubscription />
        ) : (
          <ChangePasswordForm password={""} newPassword={""} newPasswordConfirmation={""} />
        )}
      </div>
    </SettingsWrapper>
  );
};

export default Settings;
