export const paramGaSearcher = () => {
  const urlUtmSource = new URLSearchParams(window.location.search).get("utm_source");
  const urlUtmCampaign = new URLSearchParams(window.location.search).get("utm_campaign");
  const urlUtmContent = new URLSearchParams(window.location.search).get("utm_content");
  const urlUtmMedium = new URLSearchParams(window.location.search).get("utm_medium");
  const urlUtmTerm = new URLSearchParams(window.location.search).get("utm_term");
  const referrer = document.referrer;

  return {
    source: urlUtmSource,
    campaign: urlUtmCampaign,
    content: urlUtmContent,
    medium: urlUtmMedium,
    referrer,
    term: urlUtmTerm,
  };
};
