import Col from "components/Grid/Col";
import Row from "components/Grid/Row";
import Header from "components/Header";
import { FC } from "react";
import { RootState, useAppSelector } from "store/hooks";
import styled from "styled-components";
import theme from "theme";
import { numberWithSpaces } from "utils/moneyFormatter";
import { useTranslation } from "react-i18next";

const NumberTitle = styled(Header)`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.bold};
  font-size: 22px;
  font-family: ${theme.fonts.heading};
  text-align: left;
  line-height: ${theme.lineHeightSmall};
  span {
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.creamy};
  }
`;

const Subtitle = styled.div`
  text-transform: uppercase;
  text-align: left;
  font-size: 14px;
`;

const DataUnderAnimation: FC = () => {
  const salesStats = useAppSelector((state: RootState) => state.phases.salesStats);
  const { t } = useTranslation();

  return (
    salesStats && (
      <Row style={{ gap: 20 }}>
        <Col align="flex-start">
          <NumberTitle>
            {salesStats.totalUsers && numberWithSpaces(Number(salesStats.totalUsers))}
            <span> +</span>
          </NumberTitle>
          <Subtitle>{t("hero.dataUnderAnimation.supporters")}</Subtitle>
        </Col>
      </Row>
    )
  );
};

export default DataUnderAnimation;
