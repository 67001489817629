import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/hooks";
import { AnnouncementData } from "types/announcment/announcment.types";

const marketingSlice = createSlice({
  name: "marketing",
  initialState: {
    announcement: {} as AnnouncementData | null,
  },
  reducers: {
    setAnnouncement: (state, action: PayloadAction<AnnouncementData | null>) => {
      state.announcement = action.payload;
    },
  },
});

export const { setAnnouncement } = marketingSlice.actions;
export default marketingSlice.reducer;
export const marketing = (state: RootState) => state.marketing;
