import axios from "axios";

function refreshTokenHeader() {
  const refreshToken = localStorage.getItem("refresh_token");
  if (refreshToken) {
    return {
      Refresh: refreshToken,
      "Content-Type": "application/json",
    };
  } else {
    return null;
  }
}

export const apiWithNoAuth = axios.create({
  baseURL: `${process.env.REACT_APP_API}/api/v1/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiRefreshTokenService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/api/v1/users/`,
});

apiRefreshTokenService.interceptors.request.use(function (config: any) {
  config.headers = refreshTokenHeader();
  return config;
});
