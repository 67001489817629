import Box from "components/Box";
import Row from "components/Grid/Row";
import { HashLink } from "react-router-hash-link";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";
import theme from "theme";
import Button from "components/Button";

export const HeroStyles = styled.div`
  background: linear-gradient(90deg, rgba(12, 74, 63, 1) 0%, rgba(13, 43, 37, 0.95) 100%);
  padding: 0 0 60px 0;
  color: ${theme.colors.white};
  position: relative;
`;

export const HeroRow = styled(Row)`
  z-index: 1;

  ${down("lg")} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const HeroSVGWrapper = styled.div`
  position: relative;

  & svg {
    max-width: 100%;
    height: 600px;

    ${down("lg")} {
      max-width: 500px;
      height: auto;
    }

    ${down("xs")} {
      max-width: 100%;
      height: auto;
    }
  }
`;

export const NavLink = styled(HashLink)`
  color: ${theme.colors.secondary};
  text-decoration: none;
  transition: ${theme.transition};

  &:hover {
    color: ${theme.colors.primary};
    transition: ${theme.transition};
    text-decoration: underline;
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-weight: ${theme.fontWeights.bold};
  font-size: 48px;
  line-height: ${theme.lineHeightMedium};
  margin: 24px 0;
  color: ${theme.colors.secondary500};
  ${down("lg")} {
    font-size: ${theme.fontSizes.xLarge};
  }
`;

export const Header = styled.h1`
  font-size: ${theme.fontSizes.xLarge};
  font-weight: ${theme.fontWeights.bold};
  width: 520px;
  line-height: 41px;
  text-align: center;

  ${up("xl")} {
    font-size: 48px;
    line-height: 61px;
  }

  ${down("xs")} {
    width: 100%;
  }
`;

export const CTAButton = styled(Button)`
  padding: 16px 32px;
  font-weight: ${theme.fontWeights.bold};
  height: auto;
  background-color: #e2071c;
  color: ${theme.colors.white};
  :hover {
    color: ${theme.colors.secondary};
  }
`;

export const ButtonBox = styled(Box)`
  justify-content: flex-start;

  ${down("lg")} {
    justify-content: center;
  }
`;

export const Description = styled.div`
  font-size: ${theme.fontSizes.regular};
  width: 500px;
  max-width: 100%;
  line-height: ${theme.lineHeightMedium};
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

  ${up("xl")} {
    text-align: left;
    font-size: 20px;
    line-height: ${theme.lineHeightLarge};
  }
`;
