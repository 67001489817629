import { apiWithNoAuth } from "./axiosInterceptors";
import { baseURL } from "./user";
import axios from "axios";

export const filterList = async () => {
  return await apiWithNoAuth.get(baseURL + "filters/list");
};

export const kycVerify = async (data: FormData, authToken: string) => {
  return await axios.post(baseURL + "users/kyc_verification", data, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const withdrawsList = async (authToken: string) => {
  return await axios.get(baseURL + "withdraws/list", {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const withdrawPhases = async (authToken: string) => {
  return await axios.get(baseURL + "withdraw_phases/list", {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};

export const createWithdraw = async (data: FormData, authToken: string) => {
  return await axios.post(baseURL + "withdraws/create", data, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });
};
