import {
  BackNavWrapper,
  DefaultButton,
  FormContainer,
  Info,
  MainWrapper,
  StyledLogo,
} from "../authStyles";
import SideComponent from "../LeftSideSection";
import { ReactComponent as SuccessLogo } from "assets/icons/account-activation/Success.svg";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import theme from "theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AccountActivationProps } from "types/authTypes";
import { FC, useEffect } from "react";
import ReactGA from "react-ga";
import { ROUTE } from "types/enums";
import { userActivateAccount } from "app/api/user";
import { useTranslation } from "react-i18next";

const Form = styled.form`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${up("xl")} {
    margin-left: 10vw;
  }

  ${down("lg")} {
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0;
  }
`;
const MobileLogoWrapper = styled.div`
  display: none;

  ${down("lg")} {
    width: 400px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 30px 0 30px 0;
  }
`;
const Success = styled(SuccessLogo)`
  width: 100px;
  height: 100px;
`;
const Title = styled.h1`
  margin-top: 30px;
  width: 450px;
  text-align: left;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.xxLarge};
  line-height: ${theme.lineHeightXXLarge};
  margin-bottom: 10px;
  color: ${theme.colors.primary800};
  cursor: default;

  ${down("lg")} {
    width: 335px;
    font-size: ${theme.fontSizes.xLarge};
    margin-top: 30px;
    line-height: ${theme.lineHeightXLarge};
  }
`;
const ButtonContinue = styled.button`
  ${DefaultButton};
  @media (max-height: 740px) and (max-width: 440px) {
    margin-top: 230px;
  }
  @media (max-height: 670px) and (max-width: 440px) {
    margin-top: 150px;
  }
  @media (min-height: 812px) and (max-width: 440px) {
    margin-top: 320px;
  }
`;

const AccountActivation: FC<AccountActivationProps> = () => {
  const { handleSubmit } = useForm<AccountActivationProps>();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const redirectToLoginPage = () => {
    return navigate(ROUTE.LOG_IN);
  };

  const activationToken = new URLSearchParams(location.search).get("token");
  sessionStorage.setItem("activationToken", JSON.stringify(activationToken));

  const onSubmit = async () => {
    const accountActivationFormData = new FormData();
    accountActivationFormData.append("activation_token", JSON.stringify(activationToken));

    try {
      const response = await userActivateAccount(accountActivationFormData);
      if (response.status === 201) {
        sessionStorage.clear();
        return response;
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        return err;
      }
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: "Sign Up",
      action: "Successful Sign Up",
      label: "Display welcome page",
    });
    const submitForm = setTimeout(async () => {
      await onSubmit();
    }, 1000);
    return () => {
      clearTimeout(submitForm);
    };
  });

  const handleNavigateToMainPage = () => navigate(ROUTE.HOME);

  return (
    <MainWrapper>
      <SideComponent />
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BackNavWrapper>
            <MobileLogoWrapper>
              <StyledLogo onClick={handleNavigateToMainPage} />
            </MobileLogoWrapper>
          </BackNavWrapper>
          <Success />
          <Title>{t("authorization.activation.activationSuccess")}</Title>
          <Info>{t("authorization.activation.description")}</Info>
          <ButtonContinue onClick={redirectToLoginPage}>
            {t("authorization.activation.backToLogin")}
          </ButtonContinue>
        </Form>
      </FormContainer>
    </MainWrapper>
  );
};
export default AccountActivation;
