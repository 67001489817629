import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack, BackLink } from "../authStyles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const NavigateToPrevious: FC = () => {
  const navigate = useNavigate();
  const { key: keyLocation } = useLocation();
  const { t } = useTranslation();

  const handleGoBack: VoidFunction = () => {
    const isInitialLocation: boolean = keyLocation === "default";
    const to: any = isInitialLocation ? "/" : -1;
    navigate(to);
  };

  return (
    <BackLink onClick={handleGoBack}>
      <ArrowBack style={{ marginRight: "10px" }} />
      {t("authorization.signUp.back")}
    </BackLink>
  );
};
export default NavigateToPrevious;
