export const hackenLink = "https://hacken.io/audits/#lucrosus_capital";
export const adFormLink = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
export const s3bucket = "https://lucrosus-production.s3.eu-central-1.amazonaws.com/";
export const bsScanLink = "https://bscscan.com/address/0xf82aa46120314904cd8119dac84f6bcc7d90ed2e";

export const mockData = false;
export const bigIntConverter = (value: number) => {
  return value / 100000;
};

export const bigIntEthConverter = 100000000;

// CALCULATOR ESTIMATIONS
export const startingCirculatingSupply = 750000000;
export const startingPrice = 0.06;
export const shareForBuyBacks = 0.25;
export const estimationMembersInTier: number[] = [300, 200, 200, 75, 20];
