import * as React from "react";
import { DividerProps } from "types/divider";
import styled, { css } from "styled-components";

const DividerStyles = styled.div<DividerProps>`
  width: auto;
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}

    ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}

    ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}

    ${({ right }) =>
    right &&
    css`
      margin-right: ${right};
    `}

    ${({ left }) =>
    left &&
    css`
      margin-left: ${left};
    `}
`;

const Divider: React.FunctionComponent<DividerProps> = ({
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  right,
  left,
}) => {
  return (
    <DividerStyles
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight || right}
      marginLeft={marginLeft || left}
    />
  );
};

export default Divider;
