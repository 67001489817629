import { adFormLink } from "utils/magicVariables";

const adfId = process.env.REACT_APP_ADFORM_ID;

declare global {
  interface Window {
    _adftrack: any;
    adf: any;
  }
}

export function trackPage(
  page: string,
  order: { sales: string; orderid: number } | null = null
): void {
  window._adftrack = Array.isArray(window._adftrack)
    ? window._adftrack
    : window._adftrack
    ? [window._adftrack]
    : [];

  window._adftrack.push({
    HttpHost: "a1.adform.net",
    pm: adfId,
    divider: encodeURIComponent("|"),
    pagename: encodeURIComponent(page),
    order,
  });

  (function () {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = adFormLink;
    const x = document.getElementsByTagName("script")[0];
    x.parentNode?.insertBefore(s, x);
  })();
}

export function trackClick(path: string, label: string): void {
  if (window.adf) {
    window.adf.ClickTrack({ href: path }, adfId, label, {});
  }
}
