import styled, { css } from "styled-components";
import theme from "theme";
import { NavigationTheme } from "types/navigation";

export const DropdownMenu = styled.div<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 48px;
  background: ${theme.colors.gray600};
  border-radius: 8px;
  cursor: pointer;
  transition: ${theme.transition};
  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${theme.colors.primary};
    `}
`;

export const DropdownIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const MenuStyles = styled.ul`
  position: absolute;
  top: 100%;
  width: 250px;
  background: ${theme.colors.white};
  border-radius: 12px;
  border: ${theme.border};
  padding: 10px;
  z-index: 1;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 14px rgba(34, 69, 63, 0.21);

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 10px;
    font-size: ${theme.fontSizes.small};

    svg {
      margin: 0 10px;
    }

    a,
    Link {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${theme.colors.black};
      text-decoration: none;
      transition: ${theme.transition};
      &:hover {
        color: ${theme.colors.primary};
        transition: ${theme.transition};
        text-decoration: underline;
      }
    }
  }
`;

export const DesktopMenuStyles = styled(MenuStyles)`
  right: 25px;
  @media only screen and (min-width: 1450px) {
    right: initial;
  }
`;

export const ProductDropdownMenu = styled.div<{ isOpen?: boolean; color?: NavigationTheme }>`
  display: inline;
  ${({ color }) =>
    color &&
    css`
      color: ${theme.colors.primary800};
    `}
`;

export const ProductMenuStyles = styled(MenuStyles)`
  width: 500px;
  padding: 16px;
`;

export const IconWrapper = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: ${theme.borderRadius.md};
  background: ${({ color }) => color || theme.colors.gray600};
`;

export const ProductItemHeader = styled.p`
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.bold};
  line-height: 150%;
  color: ${theme.colors.primary800};
  letter-spacing: 0.03em;
`;

export const ProductItemWrapper = styled.div`
  padding: 16px;
  &:hover {
    background: ${theme.colors.neutral300};
    border-radius: ${theme.borderRadius.md};
    a,
    Link {
      text-decoration: none;
    }
  }
`;

export const ProductDescription = styled.p`
  font-size: ${theme.fontSizes.small};
  line-height: 150%;
  color: ${theme.colors.neutral};
  letter-spacing: 0.03em;
`;
