import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import theme from "theme";
import {
  BackNavWrapper,
  DefaultInput,
  DontHaveAccount,
  Errors,
  Form,
  FormContainer,
  Info,
  InputContainer,
  LinkToSignup,
  MainWrapper,
  MobileLogoWrapper,
  StyledLogo,
  Title,
} from "../authStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import SideComponent from "../LeftSideSection/sideSection";
import { down } from "styled-breakpoints";
import { LoginFieldProps } from "types/authTypes";
import { Link, useNavigate } from "react-router-dom";
import NavigateToPrevious from "../BackButton";
import { ROUTE } from "types/enums";
import ReCaptcha, { ButtonContinue } from "../../Recaptcha";
import { generateOtp } from "app/api/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { isUserSubmitting, setAuthCode, setLoading } from "app/features/auth";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const EmailInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const PasswordInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const ForgotPasswordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 385px;

  ${down("lg")} {
    width: 335px;
  }
`;
const ForgotPasswordLink = styled(Link)`
  line-height: ${theme.lineHeightSmall};
  font-size: ${theme.fontSizes.small};
  text-decoration: none;
  margin-top: -15px;
  color: ${theme.colors.primary800};
  opacity: 0.75;
  cursor: pointer;

  :focus {
    outline: 1px solid ${theme.colors.primary800};
    border-radius: 4px;
  }
`;

const LogIn: FC<LoginFieldProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("authorization.signUp.invalidEmail"))
      .required(t("authorization.signUp.pleaseEmail")),
    password: yup.string().required(t("authorization.signUp.pleasePassword")),
  });

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector(isUserSubmitting);

  const {
    handleSubmit,
    setError,
    register,
    setValue,
    formState: { errors },
  } = useForm<LoginFieldProps>({
    resolver: yupResolver(loginSchema),
  });

  const handleNavigateToMainPage = () => navigate(ROUTE.HOME);

  const onVerifyCaptcha = (token: string) => {
    setValue("captchaToken", token);
  };

  useEffect(() => {
    if (sessionStorage.getItem("2fa-counter")) {
      sessionStorage.removeItem("2fa-counter");
    }
  }, []);

  useEffect(() => {
    register("captchaToken", { required: true });
  });

  const onSubmit = async (formState: { isDirty: boolean }) => {
    if (!formState.isDirty) {
      setValue("email", email);
      setValue("password", password);
      setEmail(email);
      setPassword(password);
    }
    const loginFormData = new FormData();
    loginFormData.append("email", email);
    loginFormData.append("password", password);
    dispatch(setLoading(true));

    try {
      const response = await generateOtp(loginFormData);
      if (response.status === 200) {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 2000);
        sessionStorage.setItem("u-key", response.data.data);
        sessionStorage.removeItem("2fa-counter");
        setEmail("");
        setPassword("");
        navigate(ROUTE.TWO_FACTOR_AUTH);
        dispatch(setAuthCode(""));
      }
    } catch (err: any) {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 2000);
      // eslint-disable-next-line
      if (err.response.status === 401 || 422) {
        setError("password", {
          type: "manual",
          message: t("authorization.logIn.incorrect"),
        });
      }
      if (err.response.status === 500) {
        setError("password", {
          type: "manual",
          message:
            "The site is so popular that the server is currently overloaded. Please try again later.",
        });
      }
    }
  };

  return (
    <MainWrapper>
      <SideComponent />
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BackNavWrapper>
            <NavigateToPrevious />
            <MobileLogoWrapper>
              <StyledLogo onClick={handleNavigateToMainPage} />
            </MobileLogoWrapper>
          </BackNavWrapper>
          <Title>{t("authorization.logIn.titleLong")}</Title>
          <Info>{t("authorization.logIn.description")}</Info>
          <InputContainer>
            {errors?.email?.message && <Errors>{errors.email.message}</Errors>}
            <EmailInput
              type="email"
              placeholder={t("authorization.logIn.email")}
              {...register("email", {
                value: email,
                onChange: (e) => setEmail(e.target.value),
              })}
              invalid={Boolean(errors.email)}
            />
            {errors?.password?.message && <Errors>{errors.password.message}</Errors>}
            <PasswordInput
              type="password"
              placeholder={t("authorization.logIn.password")}
              {...register("password", {
                value: password,
                onChange: (e) => setPassword(e.target.value),
              })}
              invalid={Boolean(errors.password)}
            />
          </InputContainer>
          <ForgotPasswordWrapper>
            <ForgotPasswordLink to="/forgot-password">
              {t("authorization.logIn.forgotPassword")}
            </ForgotPasswordLink>
          </ForgotPasswordWrapper>
          {isSubmitting ? (
            <ButtonContinue disabled={isSubmitting}>
              {t("authorization.signUp.sending")}
            </ButtonContinue>
          ) : (
            <ReCaptcha
              onVerifyCaptcha={onVerifyCaptcha}
              type="submit"
              size="invisible"
              badge="bottom"
            />
          )}
          <DontHaveAccount>
            {t("authorization.logIn.dontHaveAccount")}
            <LinkToSignup to={ROUTE.SIGN_UP}>{t("authorization.logIn.signUpLong")}</LinkToSignup>
          </DontHaveAccount>
        </Form>
      </FormContainer>
    </MainWrapper>
  );
};

export default LogIn;
