import styled from "styled-components";
import theme from "theme";
import HackenLogo from "assets/hacken-logo.svg";
import HackenMask from "assets/hacken-mask.svg";
import Container from "../Grid/Container";
import { down } from "styled-breakpoints";
import { hackenLink } from "utils/magicVariables";
import { useTranslation } from "react-i18next";

const HackenSection = styled.div`
  background-color: ${theme.colors.green300};
  width: 100%;
  height: 80px;

  p {
    margin: 0 12px;
    font-family: ${theme.fonts.heading};
    font-style: normal;
    font-weight: normal;
    font-size: ${theme.fontSizes.regular};
  }

  a {
    display: flex;
    justify-content: right;
    color: ${theme.colors.white};
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    -webkit-box-pack: end;
    -webkit-box-align: end;
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;

    ${down("md")} {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;

const Mask = styled.img`
  height: 80px;
  justify-content: left;
  position: absolute;
`;

const AuditedByHacken = () => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  const placeWordsAfterHacken = () => {
    const listOfLanguagesToPlaceAfterHacken = ["tr", "hi"];
    return listOfLanguagesToPlaceAfterHacken.includes(language);
  };

  return (
    <HackenSection>
      <Container>
        <Mask src={HackenMask} alt="Hacken Mask" />
        <a rel="noreferrer" target="_blank" href={hackenLink}>
          {!placeWordsAfterHacken() && <p>{t("common.auditedByHacken")}</p>}
          <img src={HackenLogo} alt="Hacken Logo" />
          {placeWordsAfterHacken() && <p>{t("common.auditedByHacken")}</p>}
          {language === "zh" && <p>{t("common.hackenZH")}</p>}
        </a>
      </Container>
    </HackenSection>
  );
};

export default AuditedByHacken;
