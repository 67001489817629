import * as React from "react";
import styled from "styled-components";
import theme from "theme";
import { down } from "styled-breakpoints";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import Container from "../Grid/Container";
import { getAnnouncements } from "app/api/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setAnnouncement } from "app/features/marketing";
import { AnnouncementData } from "types/announcment";
import { isUserLoggedIn } from "app/features/auth";

const AnnouncementBarStyled = styled.div`
  width: 100%;
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  position: relative;
  padding: 0 32px;

  ${down("xs")} {
    padding-left: 0;
  }

  .announcement {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 48px;
    padding: 10px 0;

    &__btn {
      padding: 0 10px;
      color: ${theme.colors.white};
      text-decoration: underline;
      font-weight: ${theme.fontWeights.bold};
    }

    &__goldBtn {
      color: ${theme.colors.black};
      text-decoration: underline;
      font-weight: ${theme.fontWeights.bold};

      ${down("sm")} {
        padding-top: 20px;
      }
    }

    &__close {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 18px;
      right: 18px;
    }
    ${down("xs")} {
      flex-direction: column;
      p {
        font-size: ${theme.fontSizes.superSmall};
        padding-bottom: 5px;
      }
    }
  }
`;

const AnnouncementBar: React.FunctionComponent = () => {
  const announcement = useAppSelector(
    (state) => state.marketing.announcement
  ) as AnnouncementData | null;

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    getAnnouncements().then((data) => {
      const payload = data.data.length ? data.data[0] : true;
      if (!payload || (payload?.logged_in_only && !isUserLoggedIn())) {
        dispatch(setAnnouncement(null));
      } else {
        dispatch(setAnnouncement(payload));
      }
    });
    // eslint-disable-next-line
  }, []);

  if (announcement === null) return null;
  return (
    <AnnouncementBarStyled>
      <Container className="announcement">
        <p>{announcement.content}</p>
        {announcement.url_label && (
          <a href={announcement.url} className="announcement__btn" target="_blank" rel="noreferrer">
            {announcement.url_label}
          </a>
        )}
      </Container>
      <div className="announcement__close" onClick={() => dispatch(setAnnouncement(null))}>
        <CloseIcon />
      </div>
    </AnnouncementBarStyled>
  );
};

export default AnnouncementBar;
