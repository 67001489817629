import NewsletterSubscription from "../NewsletterSubscription";
import React, { useLayoutEffect, Suspense, lazy, useEffect } from "react";
import { isUserLoggedIn } from "app/features/auth";
import { setDepositBox } from "app/features/staking";
import { useAppDispatch } from "store/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import { getShowStakingSection } from "app/features/utils";

const Deposit = lazy(() => import("./Deposit"));
const HowItWorks = lazy(() => import("./HowItWorks"));
const StakingHero = lazy(() => import("./StakingHero"));

const Staking = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getShowStakingSection());
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setDepositBox(5));
  });

  return (
    <div>
      <Suspense
        fallback={
          <div>
            <CircularProgress />
          </div>
        }
      >
        <StakingHero />
        <Deposit isLogged={isUserLoggedIn()} />
        <HowItWorks />
        <NewsletterSubscription />
      </Suspense>
    </div>
  );
};

export { Staking };
