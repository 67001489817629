import { createGlobalStyle } from "styled-components";
import { typography } from "theme/config";
import theme from "theme/index";

const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background: ${theme.colors.white};
    font-family: ${typography.fonts.body};
    font-size: ${theme.fontSizes.regular};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${typography.fonts.heading};
  }


  a {
    color: ${theme.colors.green500};
    text-decoration: none;
    transition: ${theme.transition};

    &:hover {
      text-decoration: underline;
    }
  }

  .toast-container {
    position: relative;
    border-radius: 8px;
    width: 394px;
    margin-left: 12px;
    margin-top: 100px;
    margin-bottom: -300px;
    @media (max-width: 992px) {
      width: 335px;
      margin: 10px 0 -100px 0;
    }
  }

  .text-bold {
    font-weight: ${theme.fontWeights.bold};
  }

  .photo.rotated {
    transform: perspective(600px);
    background: ${theme.colors.creamy};
    transition: ${theme.transition};
  }

  .grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
  }
  .grecaptcha-badge:hover {
    width: 256px !important;
  }
`;

export default GlobalStyle;
