import { configureStore } from "@reduxjs/toolkit";
import auth from "app/features/auth";
import marketing from "app/features/marketing";
import phases from "app/features/phases";
import transactions from "app/features/transactions";
import utils from "app/features/utils";
import staking from "app/features/staking";
import withdraw from "app/features/withdraw";

const store = configureStore({
  reducer: {
    auth,
    phases,
    transactions,
    marketing,
    utils,
    staking,
    withdraw,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
