import * as React from "react";
import styled from "styled-components";
import theme from "theme";
import { SVGIcon } from "assets/icons/SVGIcon";
import { ReactComponent as CircleIcon } from "assets/icons/custom/circle.svg";

const FooterStyles = styled.footer`
  height: 100px;
  width: 100%;
  background: ${theme.colors.gradient};
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

const CircleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
`;

const CopyrightStyles = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
  color: ${theme.colors.white};
`;

const BottomFooter: React.FunctionComponent = () => {
  return (
    <FooterStyles>
      <CircleWrapper>
        <CircleIcon
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          style={{ cursor: "pointer" }}
        />
        <ArrowWrapper
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          style={{ cursor: "pointer" }}
        >
          <SVGIcon name="arrowTop" color="transparent" />
        </ArrowWrapper>
      </CircleWrapper>
      <CopyrightStyles>
        © Copyright {new Date().getFullYear()} Lucrosus Capital. All Rights Reserved
      </CopyrightStyles>
    </FooterStyles>
  );
};

export default BottomFooter;
