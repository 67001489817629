import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "index.css";
import GlobalStyle from "./theme/globalStyles";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./services/Auth/SignUp";
import { LogIn, TwoFactorAuthentication } from "./services/Auth/LogIn";
import { CreateNewPasswordForm, ForgotPasswordForm } from "./services/Auth/ForgotPassword";
import AccountActivation from "./services/Auth/AccountActivation";
import Settings from "services/Settings";
import MainWalletScreen from "services/Wallet";
import Layout from "components/Layout";
import ProtectedViews from "components/ProtectedViews";
import ReactGA from "react-ga";
import NoMatch from "services/NoMatch";
import { ROUTE } from "types/enums";
import { Provider } from "react-redux";
import store from "./store";
import OrderStatus from "services/Orders/OrderStatus";
import { OrderState } from "types/order/order.types";
import MaintenancePage from "services/Maintenance";
import "./i18n";
import { Staking } from "./components/Staking/Staking";
import CircularProgress from "@mui/material/CircularProgress";

ReactGA.initialize(process.env.REACT_APP_GA as string);
ReactGA.pageview(window.location.pathname + window.location.search);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_SENTRY_TRACE as string],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_RATE as string),
});

const GDPR = lazy(() => import("components/GDPR"));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <GlobalStyle />
        <Suspense
          fallback={
            <div>
              <CircularProgress />
            </div>
          }
        >
          <GDPR />
        </Suspense>
        <Routes>
          <Route
            path={ROUTE.SIGN_UP}
            element={
              <SignUp
                email=""
                password=""
                passwordConfirmation=""
                termsAccepted
                newsletter
                referralCode=""
                isDirty
              />
            }
          />
          <Route path={ROUTE.LOG_IN} element={<LogIn email="" password="" isDirty />} />
          <Route
            path={ROUTE.TWO_FACTOR_AUTH}
            element={<TwoFactorAuthentication authCode="" isDirty />}
          />
          <Route
            path={ROUTE.RESET_PASSWORD}
            element={<CreateNewPasswordForm password="" passwordConfirmation="" passwordToken="" />}
          />
          <Route path={ROUTE.FORGOT_PASSWORD} element={<ForgotPasswordForm email="" />} />
          <Route path={ROUTE.ACTIVATION} element={<AccountActivation activationToken="" />} />
          <Route path={ROUTE.HOME} element={<Layout />}>
            <Route index element={<App />} />
            <Route element={<ProtectedViews />}>
              <Route path={ROUTE.SETTINGS} element={<Settings />} />
              <Route path={ROUTE.WALLET} element={<MainWalletScreen />} />
              <Route
                path={ROUTE.TRANSACTION_FAILED}
                element={<OrderStatus status={OrderState.Failed} />}
              />
              <Route
                path={ROUTE.TRANSACTION_SUCCESS}
                element={<OrderStatus status={OrderState.Success} />}
              />
              <Route
                path={ROUTE.TRANSACTION_CANCEL}
                element={<OrderStatus status={OrderState.Canceled} />}
              />
            </Route>
            <Route path={ROUTE.ERROR} element={<NoMatch />} />
            <Route path={ROUTE.STAKING} element={<Staking />} />
          </Route>
          <Route path={ROUTE.MAINTENANCE} element={<MaintenancePage />} />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
