import styled from "styled-components";
import logo from "assets/icons/email.svg";
import theme from "theme";
import { down } from "styled-breakpoints";
import { DefaultButton } from "../Auth/authStyles";

export const ModalContainer = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 57px;
`;

export const ModalDesktopContainer = styled(ModalContainer)`
  border: 1px solid ${theme.colors.placeholder};
  border-radius: 8px;
  width: 457px;
  height: 318px;
  ${down("md")} {
    width: 335px;
    height: 300px;
    justify-content: center;
    padding: 30px 10px 20px 10px;
  }
`;

export const EmailLogo = styled.img.attrs({
  src: `${logo}`,
})`
  height: 38px;
  width: 56px;
  margin: 10px 0 20px 0;
`;

export const Header = styled.h3`
  height: 32px;
  width: auto;
  color: ${theme.colors.primary800};
  font-size: 22px;
  line-height: ${theme.lineHeightLarge};
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  margin-bottom: 40px;
`;

export const Content = styled.p`
  font-size: ${theme.fontSizes.small};
  text-align: center;
  letter-spacing: 0.25px;
  line-height: 23px;
  font-weight: 500;
  color: ${theme.colors.primary800};
  opacity: 0.75;
`;

export const Button = styled.button`
  ${DefaultButton};
  text-transform: none;
  font-family: Manrope, sans-serif;
  font-weight: 700;
  width: 343px;
  ${down("md")} {
    width: 315px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  z-index: 3;
`;
