import { FC } from "react";
import {
  Button,
  Content,
  Header,
  ModalContainer,
  ModalDesktopContainer,
} from "../ModalInstance/modalPopupStyles";
import { ModalInstanceProps } from "types/modalTypes";
import Modal from "../ModalInstance/modal";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Warning } from "assets/icons/error-orange-icon.svg";
import { ROUTE } from "types/enums";
import theme from "theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setUserInfo, userAuthToken } from "app/features/auth";
import { getTransactionsList } from "app/api/payments";
import { setTransactions } from "app/features/transactions";
import { getUserInfo } from "app/api/user";
import { useTranslation } from "react-i18next";

const StyledModalContainer = styled(ModalContainer)`
  height: 430px;
  width: 470px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 0 15px 0;

  button {
    margin-top: 32px;
    width: 252px;
  }

  ${down("md")} {
    padding: 0;
    height: 650px;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
`;

const StyledModalDesktopContainer = styled(ModalDesktopContainer)`
  display: flex;
  justify-content: flex-start;
  height: 480px;
  width: 480px;

  ${down("md")} {
    justify-content: center;
    height: 490px;
    width: 90%;
  }
`;

const StyledHeader = styled(Header)`
  margin-top: 25px;
  width: 350px;
  text-align: center;
  font-size: 20px;

  span {
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      height: 25px;
      width: 25px;
      border-top: 1px solid #000;
      position: absolute;
      top: 0;
      right: 16px;
      transform: rotate(-45deg);
    }

    &:before {
      right: 34px;
      transform: rotate(45deg);
    }

    &:hover {
      opacity: 0.3;
    }
  }

  ${down("md")} {
    width: 350px;

    span {
      &:after,
      &:before {
        top: -5px;
        right: -10px;
      }

      &:before {
        right: 7px;
      }
    }
  }
`;

const StyledContent = styled(Content)`
  text-align: center;
  width: 380px;
  font-weight: ${theme.fontWeights.regular};
  line-height: 24px;
  margin-top: 10px;
  font-size: ${theme.fontSizes.regular};

  a {
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeights.bold};
    text-decoration: underline;
  }

  ${down("md")} {
    width: 300px;
    font-size: ${theme.fontSizes.small};
  }
`;

const TransactionCancel: FC<ModalInstanceProps> = () => {
  const navigate = useNavigate();
  const authToken = useAppSelector(userAuthToken);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  const redirectToWallet = async () => {
    await getTransactionsList(`${authToken}`).then(({ data }) => {
      dispatch(setTransactions(data));
    });
    await getUserInfo(`${authToken}`).then(({ data }) => {
      dispatch(setUserInfo(data));
    });
    navigate(ROUTE.WALLET);
  };

  return (
    <Modal>
      <StyledModalDesktopContainer>
        <StyledModalContainer>
          <Warning />
          <StyledHeader>
            {t("authorization.cancelPaymentModal.title")} <span onClick={redirectToWallet} />
          </StyledHeader>
          <StyledContent>
            {t("authorization.cancelPaymentModal.description")}{" "}
            {language !== "tr" && (
              <a href="mailto:support@lucrosus.capital">support@lucrosus.capital</a>
            )}
            {language === "tr" && (
              <a href="mailto:support@lucrosus.capital">support@Lucrosus.capital'de</a>
            )}{" "}
            {t("authorization.cancelPaymentModal.descriptionTurk")}
          </StyledContent>
          <Button onClick={redirectToWallet}>{t("common.backToWallet").toUpperCase()}</Button>
        </StyledModalContainer>
      </StyledModalDesktopContainer>
    </Modal>
  );
};

export default TransactionCancel;
