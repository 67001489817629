import { useLocation, useNavigate } from "react-router-dom";
import { LinkProps } from "types/link";
import { HASH_ROUTE, ROUTE } from "types/enums";
import { InnerLink, NavLink, NavOutLink } from "./navigation.styles";
import scrollWithOffset from "utils/scrollWithOffset";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setLogout, userAuthToken } from "app/features/auth";
import styled from "styled-components";
import ComingBG from "assets/icons/coming-soon/coming_bg.svg";
import theme from "theme";
import { NavigationTheme } from "types/navigation";
import { FC } from "react";
import { selectStakingSection } from "app/features/utils";

const StyledDiv = styled.ul`
  display: inline;
  @media (min-width: 1200px) {
    li:first-of-type {
      background-image: url(${ComingBG});
      background-size: cover;
      background-position: center;

      & :hover {
        color: ${theme.colors.white};
      }
    }
  }
`;

const NavigationList: FC<{
  items: LinkProps[];
  color?: NavigationTheme;
  handleClick?: () => void;
  logout?: boolean;
}> = ({ items, handleClick, color }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(userAuthToken);
  const location = useLocation();
  const ourTeamAnchor = HASH_ROUTE.OUR_TEAM.substring(2, HASH_ROUTE.OUR_TEAM.length);
  const isShowStakingSection = useAppSelector(selectStakingSection);

  const handleLogout = () => {
    dispatch(setLogout(`${authToken}`));
    navigate(ROUTE.HOME);
  };

  const listItems = items.map(({ name, link, to, target }) => (
    <li
      style={{
        listStyle: "none",
        display: window.innerWidth < 1200 ? "" : "inline",
      }}
      key={name}
    >
      {link && (
        <NavOutLink href={link} target={target} rel="noreferrer" color={color}>
          {name}
        </NavOutLink>
      )}
      {to &&
        (name === "NEWS" ? (
          location.pathname === "/" ? (
            <NavLink
              to={to}
              scroll={(el) => scrollWithOffset(el)}
              onClick={handleClick}
              color={color}
              smooth
            >
              {name}
            </NavLink>
          ) : (
            <NavLink
              to={ROUTE.HOME}
              state={{ id: ourTeamAnchor }}
              onClick={handleClick}
              color={color}
              smooth
            >
              {name}
            </NavLink>
          )
        ) : name === "Logout" ? (
          <NavLink
            to={to}
            scroll={(el) => scrollWithOffset(el)}
            onClick={handleLogout}
            color={color}
            smooth
          >
            {name}
          </NavLink>
        ) : (
          <>
            {isShowStakingSection ? (
              <InnerLink to={to} onClick={() => window.scrollTo(0, 0)} color={color}>
                {name}
              </InnerLink>
            ) : null}
          </>
        ))}
    </li>
  ));
  return <StyledDiv>{listItems}</StyledDiv>;
};

export default NavigationList;
