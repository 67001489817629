import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  StyledLogo,
  BackNavWrapper,
  DefaultButton,
  DefaultInput,
  Errors,
  Form,
  FormContainer,
  HaveAccount,
  Info,
  InputContainer,
  LinkToLogin,
  MainWrapper,
  MobileLogoWrapper,
  PasswordRequirementInfo,
  Title,
} from "../authStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import SideComponent from "../LeftSideSection/sideSection";
import { SetNewPasswordProps } from "types/authTypes";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateToPrevious from "../BackButton";
import { ROUTE } from "types/enums";
import { userCreateNewPassword } from "app/api/user";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const PasswordInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const RepeatedPasswordInput = styled.input`
  ${DefaultInput};
  margin-bottom: 5px;
`;
const ButtonContinue = styled.button`
  ${DefaultButton};
  @media (max-height: 740px) and (max-width: 440px) {
    margin-top: 200px;
  }
  @media (max-height: 670px) and (max-width: 440px) {
    margin-top: 100px;
  }
  @media (min-height: 812px) and (max-width: 440px) {
    margin-top: 280px;
  }
`;

const CreateNewPasswordForm: FC<SetNewPasswordProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const createNewPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .required(t("authorization.signUp.pleasePassword"))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t("authorization.signUp.passwordHint")
      ),
    passwordConfirmation: yup
      .string()
      .required(t("authorization.signUp.pleaseRepeatPassword"))
      .oneOf([yup.ref("password"), null], t("authorization.signUp.dontMatch")),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SetNewPasswordProps>({
    resolver: yupResolver(createNewPasswordSchema),
  });
  const [formValue, setFormValue] = useState({
    password: "",
    passwordConfirmation: "",
  });

  const handleNavigateToMainPage = () => navigate(ROUTE.HOME);

  const handleChange = (event: { target: HTMLInputElement } & React.FormEvent) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const token = new URLSearchParams(location.search).get("token");
  sessionStorage.setItem("resetToken", JSON.stringify(token));

  const onSubmit = async () => {
    const newPasswordForm = new FormData();
    newPasswordForm.append("reset_password_token", JSON.stringify(token));
    newPasswordForm.append("password", formValue.password);
    newPasswordForm.append("password_confirmation", formValue.passwordConfirmation);

    try {
      const response = await userCreateNewPassword(newPasswordForm);
      if (response.status === 200) {
        setFormValue({
          password: "",
          passwordConfirmation: "",
        });
        sessionStorage.clear();
        navigate(ROUTE.LOG_IN);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError("password", {
          message: "Invalid password reset token or expired",
        });
      }
    }
  };

  return (
    <MainWrapper>
      <SideComponent />
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BackNavWrapper>
            <NavigateToPrevious />
            <MobileLogoWrapper>
              <StyledLogo onClick={handleNavigateToMainPage} />
            </MobileLogoWrapper>
          </BackNavWrapper>
          <Title>{t("authorization.passwordReset.title")}</Title>
          <Info>{t("authorization.passwordReset.description")}</Info>
          <InputContainer>
            {errors?.password?.message && <Errors>{errors.password.message}</Errors>}
            <PasswordInput
              type="password"
              placeholder={t("authorization.passwordReset.newPassword")}
              value={formValue.password}
              {...register("password")}
              onChange={handleChange}
              invalid={Boolean(errors.password)}
            />
            {errors?.passwordConfirmation?.message && (
              <Errors>{errors.passwordConfirmation.message}</Errors>
            )}
            <RepeatedPasswordInput
              type="password"
              placeholder={t("authorization.passwordReset.repeatPassword")}
              value={formValue.passwordConfirmation}
              {...register("passwordConfirmation")}
              onChange={handleChange}
              invalid={Boolean(errors.passwordConfirmation)}
            />
            <PasswordRequirementInfo>
              {t("authorization.signUp.passwordHint")}
            </PasswordRequirementInfo>
          </InputContainer>
          <ButtonContinue type="submit">{t("authorization.passwordReset.title")}</ButtonContinue>
          <HaveAccount>
            {t("authorization.signUp.haveAccount")}
            <LinkToLogin to={ROUTE.LOG_IN}>{t("navigation.logIn")}</LinkToLogin>
          </HaveAccount>
        </Form>
      </FormContainer>
    </MainWrapper>
  );
};

export default CreateNewPasswordForm;
