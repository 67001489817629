import { ReactComponent as TwitterIcon } from "assets/icons/socials/twitter.svg";
import { ReactComponent as MediumIcon } from "assets/icons/socials/medium.svg";
import { ReactComponent as LinkedinIcon } from "assets/icons/socials/linkedin.svg";
import { ReactComponent as TelegramChatIcon } from "assets/icons/socials/telegramChat.svg";
import { ReactComponent as TelegramNewsIcon } from "assets/icons/socials/telegramNews.svg";

export const socialIcons = [
  { name: "twitter", element: <TwitterIcon key="twitter" /> },
  { name: "telegramChat", element: <TelegramChatIcon key="telegramChat" /> },
  { name: "telegramNews", element: <TelegramNewsIcon key="telegramNews" /> },
  { name: "linkedin", element: <LinkedinIcon key="linkedin" /> },
  { name: "medium", element: <MediumIcon key="medium" /> },
];
