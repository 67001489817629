import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  DefaultButton,
  DefaultInput,
  Errors,
  PasswordRequirementInfo,
} from "../../Auth/authStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangePasswordProps } from "types/authTypes";
import { useNavigate } from "react-router-dom";
import theme from "theme";
import { ROUTE } from "types/enums";
import { userChangePassword } from "app/api/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setLogout, userAuthToken } from "app/features/auth";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.h2`
  margin-top: 62px;
  width: 100%;
  text-align: left;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.xLarge};
  line-height: ${theme.lineHeightXXLarge};
  margin-bottom: 10px;
  color: ${theme.colors.primary800};
  cursor: default;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Info = styled.p`
  max-width: 350px;
  line-height: ${theme.lineHeightMedium};
  text-align: left;
  color: ${theme.colors.primary800};
  opacity: 0.75;
  font-weight: ${theme.fontWeights.regular};
  margin-bottom: 18px;
  cursor: default;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0 0 0;
`;

const PasswordInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const NewPasswordInput = styled.input`
  ${DefaultInput};
  margin-bottom: 25px;
`;
const NewPasswordConfirmationInput = styled.input`
  ${DefaultInput};
  margin-bottom: 5px;
`;
const ButtonContinue = styled.button`
  ${DefaultButton};
`;

const CustomErrors = styled(Errors)`
  max-width: 385px;
  display: block;
`;

const ChangePasswordForm: FC<ChangePasswordProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(userAuthToken);
  const { t } = useTranslation();

  const changePasswordSchema = yup.object().shape({
    password: yup.string().required(t("authorization.settings.errorCurrentPassword")),
    newPassword: yup
      .string()
      .required(t("authorization.settings.errorNewPassword"))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t("authorization.signUp.passwordHint")
      ),
    newPasswordConfirmation: yup
      .string()
      .required(t("authorization.settings.errorRepeatPassword"))
      .oneOf([yup.ref("newPassword"), null], t("authorization.signUp.dontMatch")),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ChangePasswordProps>({
    resolver: yupResolver(changePasswordSchema),
  });
  const [formValue, setFormValue] = useState({
    password: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });

  const handleChange = (event: { target: HTMLInputElement } & React.FormEvent) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async () => {
    const re = /"/g;
    const changePasswordFormData = new FormData();
    changePasswordFormData.append("password", formValue.password);
    changePasswordFormData.append("new_password", formValue.newPassword);
    changePasswordFormData.append("new_password_confirmation", formValue.newPasswordConfirmation);

    try {
      const response = await userChangePassword(
        changePasswordFormData,
        JSON.stringify(authToken)?.replace(re, "")
      );
      if (response.status === 200) {
        dispatch(setLogout(`${authToken}`));
        navigate(ROUTE.LOG_IN);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError("password", {
          message: t("authorization.settings.provideValidCurrentPassword"),
        });
      }
    }
  };

  return (
    <MainWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>{t("authorization.settings.changeMyPassword")}</Title>
        <Info>{t("authorization.settings.hereYouCan")}</Info>
        <InputContainer>
          {errors?.password?.message && <Errors>{errors.password.message}</Errors>}
          <PasswordInput
            type="password"
            placeholder={t("authorization.settings.currentPassword")}
            value={formValue.password}
            {...register("password")}
            onChange={handleChange}
            invalid={Boolean(errors.password)}
          />
          {errors?.newPassword?.message && (
            <CustomErrors>{errors.newPassword.message}</CustomErrors>
          )}
          <NewPasswordInput
            type="password"
            placeholder={t("authorization.settings.newPassword")}
            value={formValue.newPassword}
            {...register("newPassword")}
            onChange={handleChange}
            invalid={Boolean(errors.newPassword)}
          />

          {errors?.newPasswordConfirmation?.message && (
            <Errors>{errors.newPasswordConfirmation.message}</Errors>
          )}
          <NewPasswordConfirmationInput
            type="password"
            placeholder={t("authorization.settings.repeatNewPassword")}
            value={formValue.newPasswordConfirmation}
            {...register("newPasswordConfirmation")}
            onChange={handleChange}
            invalid={Boolean(errors.newPasswordConfirmation)}
          />
          <PasswordRequirementInfo>
            {t("authorization.signUp.passwordHint")}
          </PasswordRequirementInfo>
        </InputContainer>
        <ButtonContinue type="submit">
          {t("authorization.settings.changeMyPassword")}
        </ButtonContinue>
      </Form>
    </MainWrapper>
  );
};

export default ChangePasswordForm;
