export const getPricePerToken = (price: number): number => {
  let result = 0;
  if (price) result = price / 100000;
  return result;
};

export const getPriceDifference = (nextPrice: number, currentPrice: number): number => {
  return nextPrice - currentPrice;
};

export const getFixedPrice = (value: number, fixedNumber = 5) => {
  return value.toFixed(fixedNumber).toLocaleString();
};
