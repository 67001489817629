import { FC, useRef, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-left.svg";
import theme from "theme";
import { NavigationTheme } from "types/navigation";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import GreatBritainFlag from "assets/flags/british.svg";
import TurkishFlag from "assets/flags/turkish.svg";
import FrenchFlag from "assets/flags/french.svg";
import PolishFlag from "assets/flags/polish.svg";
import SpainFlag from "assets/flags/spain.svg";
import GermanFlag from "assets/flags/german.svg";
import IndonesianFlag from "assets/flags/indonesian.svg";
import HindiFlag from "assets/flags/hindu.svg";
import NederlandFlag from "assets/flags/nederland.svg";
import ChinaFlag from "assets/flags/china.svg";
import { DesktopMenuStyles, SelectorWrapper } from "./LanguageSelector.styles";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { isMobileLangModalOpen, setModalLangOpen } from "app/features/utils";
import useWindowDimensions from "hooks/useWindowDimensions";
import { LangSelectorMobileWrapper, MainMenuItem } from "../Navigation/navigation.styles";
import { ReactComponent as CrossIcon } from "assets/icons/custom/cross.svg";
import Button from "components/Button";
import { getFlag } from "i18n";

export const LanguageSelector: FC<{ color?: NavigationTheme }> = ({ color }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const openModal = useAppSelector(isMobileLangModalOpen);
  const { width } = useWindowDimensions();
  const MOBILE_WIDTH = 1200 > width;
  const dispatch = useAppDispatch();

  const changeLanguage = (lng: string) => {
    localStorage.setItem("Lang", lng);
    i18n.changeLanguage(lng);
  };

  const handleClick = () => setIsOpen(!isOpen);

  useOnClickOutside(ref, () => setIsOpen(false));

  const ArrowStyles = {
    transform: isOpen ? "rotate(0.5turn)" : "none",
    transition: "transform 0.2s ease",
  };

  return (
    <>
      {MOBILE_WIDTH ? (
        <Modal isOpen={openModal}>
          <LangSelectorMobileWrapper>
            <div>
              <MainMenuItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <ArrowRightIcon
                      style={{ marginRight: "45px", cursor: "pointer" }}
                      onClick={() => dispatch(setModalLangOpen(false))}
                    />
                    {t("navigation.chooseLang")}
                  </div>
                  <div>
                    <CrossIcon
                      stroke={theme.colors.black}
                      style={{ cursor: "pointer" }}
                      onClick={() => dispatch(setModalLangOpen(false))}
                    />
                  </div>
                </div>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("en")}>
                  <img
                    src={GreatBritainFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="Great Britain Flag"
                  />
                  {t("navigation.eng")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("tr")}>
                  <img
                    src={TurkishFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="Turkish Flag"
                  />{" "}
                  {t("navigation.turk")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("fr")}>
                  <img
                    src={FrenchFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="French Flag"
                  />{" "}
                  {t("navigation.french")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("pl")}>
                  <img
                    src={PolishFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="Polish Flag"
                  />{" "}
                  {t("navigation.polish")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("es")}>
                  <img
                    src={SpainFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="Spain Flag"
                  />{" "}
                  {t("navigation.spain")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("de")}>
                  <img
                    src={GermanFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="German Flag"
                  />{" "}
                  {t("navigation.german")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("id")}>
                  <img
                    src={IndonesianFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="Indonesian Flag"
                  />{" "}
                  {t("navigation.indonesian")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("hi")}>
                  <img
                    src={HindiFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="Hindi Flag"
                  />{" "}
                  {t("navigation.hindu")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("nl")}>
                  <img
                    src={NederlandFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="Nederland Flag"
                  />{" "}
                  {t("navigation.nederland")}
                </li>
              </MainMenuItem>
              <MainMenuItem>
                <li onClick={() => changeLanguage("zh")}>
                  <img
                    src={ChinaFlag}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 12 }}
                    alt="China Flag"
                  />{" "}
                  {t("navigation.china")}
                </li>
              </MainMenuItem>
            </div>
            <div>
              <Button large onClick={() => dispatch(setModalLangOpen(false))}>
                {t("navigation.save")}
              </Button>
            </div>
          </LangSelectorMobileWrapper>
        </Modal>
      ) : (
        <SelectorWrapper ref={ref} onClick={handleClick}>
          <img
            src={getFlag()}
            width="50px"
            height="auto"
            style={{ marginRight: 5 }}
            alt="Country Flag"
          />{" "}
          <ArrowDownIcon
            stroke={color === "dark" ? theme.colors.white : theme.colors.primary800}
            style={ArrowStyles}
          />
          {isOpen ? (
            <DesktopMenuStyles>
              <Header level={6} align="center">
                {t("navigation.chooseLang")}
              </Header>
              <li onClick={() => changeLanguage("en")}>
                <img
                  src={GreatBritainFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="Great Britain Flag"
                />{" "}
                {t("navigation.eng")}
              </li>
              <li onClick={() => changeLanguage("tr")}>
                <img
                  src={TurkishFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="Turkish Flag"
                />{" "}
                {t("navigation.turk")}
              </li>
              <li onClick={() => changeLanguage("fr")}>
                <img
                  src={FrenchFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="French Flag"
                />{" "}
                {t("navigation.french")}
              </li>
              <li onClick={() => changeLanguage("pl")}>
                <img
                  src={PolishFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="Polish Flag"
                />{" "}
                {t("navigation.polish")}
              </li>
              <li onClick={() => changeLanguage("es")}>
                <img
                  src={SpainFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="Polish Flag"
                />{" "}
                {t("navigation.spain")}
              </li>
              <li onClick={() => changeLanguage("de")}>
                <img
                  src={GermanFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="German Flag"
                />{" "}
                {t("navigation.german")}
              </li>
              <li onClick={() => changeLanguage("id")}>
                <img
                  src={IndonesianFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="Indonesian Flag"
                />{" "}
                {t("navigation.indonesian")}
              </li>
              <li onClick={() => changeLanguage("hi")}>
                <img
                  src={HindiFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="Hindi Flag"
                />{" "}
                {t("navigation.hindu")}
              </li>
              <li onClick={() => changeLanguage("nl")}>
                <img
                  src={NederlandFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="Nederland Flag"
                />{" "}
                {t("navigation.nederland")}
              </li>
              <li onClick={() => changeLanguage("zh")}>
                <img
                  src={ChinaFlag}
                  width="40px"
                  height="auto"
                  style={{ marginRight: 5 }}
                  alt="China Flag"
                />{" "}
                {t("navigation.china")}
              </li>
            </DesktopMenuStyles>
          ) : null}
        </SelectorWrapper>
      )}
    </>
  );
};
