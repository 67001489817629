import * as React from "react";
import ReactModal from "react-modal";
import theme from "theme";

export interface ModalProps {
  isOpen: boolean;
  children?: React.ReactNode;
}

const customStyles = {
  content: {
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    transform: "none",
    border: "0",
    borderRadius: "0",
    background: theme.colors.white,
  },
  overlay: {
    zIndex: 100,
  },
};

const Modal: React.FunctionComponent<ModalProps> = ({ isOpen = false, children, ...props }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      preventScroll
      {...props}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
